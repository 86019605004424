import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Avatar, Menu, MenuItem, ListItemIcon, Divider, IconButton, Tooltip, Button, Link } from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { logOut } from '../../../../api/access';
import { apiClient } from '../../../../api/axios';

const AccountMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton onClick={handleClick} size="small" sx={{ ml: 1 }}>
            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
          </IconButton>
        </Tooltip>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            'overflow': 'visible',
            'filter': 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            'mt': 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          onClick={() => apiClient(`/access/check-auth`).then((res) => navigate(`/users/card/${res.data.user.id}`))}
        >
          <Avatar /> {t('general.viewProfile')}
        </MenuItem>

        <Divider />
        <MenuItem>
          <>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <Button
              children={t('general.logOut')}
              color="inherit"
              onClick={() => {
                navigate('/access/login');
                logOut();
              }}
            />
          </>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
