import { useState } from 'react';
import { addTextLine, getAnalysisModules, getSyntAnalysisById } from '../../../../../../../../api/syntax';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { queryRule, validationField } from '../../../../../../../../helpers';
import { IAnalysis } from '../../../..';
import Notification from '../../../../../../../../components/Notification';

const BtnNewLine = ({ selectItem, setStructure }: { selectItem: IAnalysis; setStructure: any }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [moduleType, setMpduleType] = useState<Array<string>>([]);
  const [moduleItem, setModuleItem] = useState<string>('');
  const [indexTitle, setIndexTitle] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(false);

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const handleChange = (event: any) => setText(event.target.value);
  const handleChangeModule = (event: any) => setModuleItem(event.target.value);
  const handleChangeIndexTitle = (event: any) => setIndexTitle(event.target.value);
  const clearForm = () => {
    setText('');
    setModuleItem('');
    setIndexTitle('');
  };

  return (
    <>
      <Button
        children={t('syntaxcompiler.newLine')}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => {
          setOpen(true);
          getAnalysisModules()
            .then((res) => setMpduleType(res.data.data))
            .catch((error) => {
              setNotification({
                ...notification,
                loading: true,
                message: error.response?.data?.messages || error.message,
                status: 'error',
              });
            });
        }}
      />

      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle children={'Text'} />
        <DialogContent dividers>
          <TextField
            autoFocus
            value={text}
            onChange={handleChange}
            variant="standard"
            label={t('syntaxcompiler.addTextLine')}
            sx={{ width: '100%' }}
            error={validationField(text)}
          />
          <FormControl variant="standard" sx={{ width: '100%', marginTop: '10px' }}>
            <InputLabel children={t('syntaxcompiler.selectModuleType')} />

            <Select value={moduleItem} onChange={handleChangeModule} label={t('syntaxcompiler.selectModuleType')}>
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {moduleType
                .filter((el) => el !== 'GENERIC')
                .map((el: any, index: number) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            value={indexTitle}
            onChange={handleChangeIndexTitle}
            variant="standard"
            label="Index"
            sx={{ width: '100%' }}
            error={validationField(indexTitle)}
          />
          {moduleItem !== 'GROUP' ? (
            <FormControlLabel
              sx={{ mt: 1 }}
              control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />}
              label={t('syntaxcompiler.hideModule')}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.undo')}
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
              clearForm();
            }}
          />

          <Button
            children={t('syntaxcompiler.addTextLine')}
            variant="contained"
            color="success"
            disabled={!text || !moduleItem || validationField(indexTitle) || validationField(text)}
            onClick={() => {
              addTextLine(selectItem.id, text, moduleItem, indexTitle, queryRule(moduleItem, checked))
                .then(() => {
                  setNotification({
                    ...notification,
                    loading: true,
                    message: t('syntaxcompiler.addTextLine'),
                    status: 'success',
                  });
                  getSyntAnalysisById(selectItem.id).then((res: any) => setStructure(res.data.structure));
                })
                .catch((error) => {
                  setNotification({
                    ...notification,
                    loading: true,
                    message: error.response?.data?.messages || error.message,
                    status: 'error',
                  });
                });
              setOpen(false);
              clearForm();
            }}
          />
        </DialogActions>
      </Dialog>

      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </>
  );
};

export default BtnNewLine;
