import { Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Notification({ data }: any) {
  const { notification, setNotification } = data;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={notification.loading}
      autoHideDuration={5000}
      onClose={() => {
        setNotification({ ...notification, loading: false });
      }}
    >
      <Alert
        variant="filled"
        onClose={() => {
          setNotification({ ...notification, loading: false });
        }}
        severity={notification.status}
      >
        {typeof notification.message === 'string'
          ? notification.message
          : Object.values(notification.message).map((el: any) => ` ${el}`)}
      </Alert>
    </Snackbar>
  );
}

export default Notification;
