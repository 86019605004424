import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useState } from 'react';
import { getDataFileAccept, getDataFileReview, postDataFileUpload } from '../../../../../../api/languages';

interface IRow {
  id: number;
  status: number;
  iso6391: string;
}

const BtnUpload = ({ row }: { row: IRow }) => {
  const { t } = useTranslation();

  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [file, setFile] = useState<any>();
  const [errorsReview, setErrorsReview] = useState([]);
  const onFileChange = (event: any) => setFile(event.target.files[0]);

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <>
      <Button
        children={t('settings.uploadLangFile')}
        variant="contained"
        onClick={() => setOpenUpload(true)}
        disabled={row.status === 0}
      />
      <Dialog fullWidth open={openUpload} onClose={() => setOpenUpload(false)}>
        <DialogTitle children={`${t('settings.uploadLangFileFor')} ${t(`language.${row.iso6391}`)}`} />
        <DialogContent dividers>
          <DialogContentText sx={{ mb: '8px' }}>{t('settings.noteBreaksInCsv')}</DialogContentText>
          <label htmlFor="contained-button-file">
            <Input accept=".csv" id="contained-button-file" multiple type="file" onChange={onFileChange} />
            <Button
              children={t('settings.uploadLangFile')}
              variant="contained"
              component="span"
              startIcon={<FileUploadIcon />}
            />
          </label>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('settings.cancel')}
            variant="contained"
            color="error"
            onClick={() => {
              setOpenUpload(false);
              setFile(null);
            }}
          />
          <Button
            children={t('settings.upload')}
            variant="contained"
            color="success"
            disabled={!file}
            onClick={() => {
              postDataFileUpload(file).then((res) =>
                getDataFileReview(row.id, res.data.item.id).then((res): any =>
                  res.data.parseResult ? getDataFileAccept(row.id, res.data.item.id) : setErrorsReview(res.data.errors)
                )
              );
              setOpenUpload(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BtnUpload;
