import { Box, TextField, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getQuestionsPaginationLimit, searchQuestions } from '../../../../api/syntax';
import Layout from '../../../../layouts/Header';
import Spinner from '../../../../components/Spinner';

import BtnAddQuestion from './components/BtnAddQuestion';
import TableQuestion from './components/TableQuestion';
import { hintSearchField } from '../../../../helpers';

interface IQuestion {
  id: number;
  status: number;
  default_answer: number;
  draft_count: number;
  published_count: number;
  text_sv: string;
  text_sv_id: number;
}

interface IPagination {
  page: number;
  pages: number;
  limit: number;
  countTotal: number;
  countFiltered: number;
}

const Questions = () => {
  const { t } = useTranslation();

  const [question, setQuestion] = useState<IQuestion[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<IPagination>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [searchableFields, setSearchableFields] = useState<Array<string>>([]);

  useEffect(() => {
    getQuestionsPaginationLimit(5).then((res: any) => {
      setQuestion(res.data.data);
      setPagination(res.data.pagination);
      setSearchableFields(res.data.searchableFields);
    });
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        searchQuestions(search, 0, rowsPerPage).then((res) => {
          setQuestion(res.data.data);
          const pagination = {
            ...res.data.pagination,
            countTotal: res.data.pagination.countFiltered,
          };
          setPagination(pagination);
          setPage(0);
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '24px' }}>
        <Typography children={t('syntaxcompiler.questions')} variant="h5" />
        <BtnAddQuestion />
      </Box>
      <Tooltip title={hintSearchField(searchableFields)}>
        <TextField
          label={t('productlist.search')}
          type="search"
          size="small"
          onChange={(event) => setSearch(event.target.value)}
          sx={{ ml: '24px' }}
        />
      </Tooltip>

      {question.length < 1 ? (
        <Spinner />
      ) : (
        <TableQuestion
          data={{ question, setQuestion, pagination, page, setPage, rowsPerPage, setRowsPerPage, search }}
        />
      )}
    </Layout>
  );
};

export default Questions;
