import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Box,
  Snackbar,
  Alert,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import GoBackButton from '../../../../../../components/GoBackButton';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';

import Layout from '../../../../../../layouts/Header';
import { useEffect, useState } from 'react';
import { getIntegrationById, getNewIntegrationDataSet, putIntegrationById } from '../../../../../../api/apiIntegration';
import Spinner from '../../../../../../components/Spinner';
import { showError } from '../../../../../../helpers';
import { useForm } from 'react-hook-form';

interface IFormData {
  id: number;
  status: number;
  product_register_id: number;
  public_key: string;
  request_key: string;
  request_key_iv: string;
  lang_codes_id: number;
  licenses_id: number;
  regionId?: number;
  syntax_analyse_id: number;
  kidney_function: number;
}

interface IDataSet {
  registers: [
    {
      id: number;
      last_sync: {
        date: string;
        timezone_type: number;
        timezone: string;
      };
      register_name: string;
    }
  ];
  regions: [
    {
      regionId: number;
      regionName: string;
    }
  ];
  languages: [
    {
      id: number;
      status: number;
      iso6391: string;
      name_text: string;
      name_text_en: string;
    }
  ];
  analysis: [
    {
      id: number;
      number: number;
      status: number;
      register: number;
      language: number;
      title: string;
      created: {
        date: string;
        timezone_type: number;
        timezone: string;
      };
      updated: {
        date: string;
        timezone_type: number;
        timezone: string;
      };
      created_by: number;
      updated_by?: number;
    }
  ];
}

const EditIntegration = () => {
  const { integrationId } = useParams();
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [kidneyFunctions] = useState([
    { id: 1, name: 'Cockcroft-Gault' },
    { id: 2, name: 'Lund-Malmö, reviderad' },
  ]);
  const [formData, setFormData] = useState<IFormData | any>();
  const [dataSet, setDataSet] = useState<IDataSet>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    Promise.all([getNewIntegrationDataSet(), getIntegrationById(Number(integrationId))])
      .then((res) => {
        setDataSet(res[0].data);
        setFormData(res[1].data.data[0]);
      })
      .catch((error) => setErrorMessage(error.messages || error.response.data.messages));
  }, []);

  const onSubmit = (data: any) => {
    const { licenses_id, product_register_id } = formData;
    const updateData = { ...data, licenses_id, product_register_id };

    putIntegrationById(Number(integrationId), updateData)
      .then(() => setOpen(true))
      .catch((error) => setErrorMessage(error.messages || error.response.data.messages));
  };

  return (
    <Layout>
      <GoBackButton />
      <Box sx={{ m: '0px 24px' }}>
        <Typography children={t('apiIntegration.editIntegration')} variant="h5" />
        {dataSet || formData ? (
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
            sx={{ display: 'flex', flexDirection: 'column', width: '60%', mt: '24px' }}
          >
            <FormControl variant="standard">
              <InputLabel children={t('apiIntegration.regionsSilLabel')} />
              <Select
                {...register('regionId')}
                defaultValue={formData?.regionId}
                sx={{ display: 'block', mb: '20px' }}
                variant="standard"
              >
                <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                {dataSet?.regions?.map((region: any) => (
                  <MenuItem children={region.regionName} key={region.regionId} value={region.regionId} />
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <InputLabel children={t('apiIntegration.selectLangTitle')} />
              <Select
                {...register('lang_codes_id')}
                defaultValue={formData?.lang_codes_id}
                sx={{ display: 'block', mb: '20px' }}
                variant="standard"
              >
                <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                {dataSet?.languages
                  ?.filter((el: any) => el.status === 1)
                  ?.map((el: any) => (
                    <MenuItem key={el.id} value={el.id}>
                      {t(`language.${el.iso6391}`)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <InputLabel children={t('apiIntegration.syntaxAnalysisLabel')} />
              <Select
                {...register('syntax_analyse_id')}
                defaultValue={formData?.syntax_analyse_id}
                sx={{ display: 'block', mb: '20px' }}
                variant="standard"
              >
                <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                {dataSet?.analysis.map((syntax: any) => (
                  <MenuItem children={`${syntax.number} ${syntax.title}`} key={syntax.id} value={syntax.id} />
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <InputLabel children={t('apiIntegration.standartKidneyFuncCalcLabel')} />
              <Select
                {...register('kidney_function')}
                defaultValue={formData?.kidney_function}
                sx={{ display: 'block', mb: '20px' }}
                variant="standard"
              >
                <MenuItem children={<em>{t('apiIntegration.none')}</em>} value={0} />
                {kidneyFunctions.map((kidneyFunction: any) => (
                  <MenuItem children={kidneyFunction.name} key={kidneyFunction.id} value={kidneyFunction.id} />
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard">
              <TextField
                variant="standard"
                sx={{ mb: '20px' }}
                label="callback url"
                {...register('callback_url')}
                defaultValue={formData?.callback_url}
              ></TextField>
            </FormControl>
            <Button
              type="submit"
              children={t('apiIntegration.saveIntegration')}
              variant="contained"
              color="success"
              startIcon={<LibraryAddIcon />}
              sx={{ alignSelf: 'flex-end' }}
              disabled={!isDirty || !isValid}
            />
            {errorMessage ? showError(errorMessage) : ''}
            <Snackbar
              open={open}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              autoHideDuration={5000}
              onClose={() => {
                setOpen(false), navigation('/integration');
              }}
            >
              <Alert
                onClose={() => {
                  setOpen(false), navigation('/integration');
                }}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
              >
                {t('apiIntegration.integrationChanged')}
              </Alert>
            </Snackbar>
          </Box>
        ) : (
          <Spinner />
        )}
      </Box>
    </Layout>
  );
};

export default EditIntegration;
