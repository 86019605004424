import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../../../../components/Spinner';
import { searchQuestions } from '../../../../../../api/syntax';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const TableQuestion = ({ data }: any) => {
  const { setQuestion, question, pagination, page, setPage, rowsPerPage, setRowsPerPage, search } = data;
  const { t } = useTranslation();
  const navigation = useNavigate();

  const handleChangePage = (event: any, newPage: number) => {
    searchQuestions(search, newPage + 1, rowsPerPage).then((res: any) => setQuestion(res.data.data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    searchQuestions(search, 1, event.target.value).then((res: any) => setQuestion(res.data.data));
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: 1, label: `${t('syntaxcompiler.question')} ID` },
    { id: 2, label: `${t('syntaxcompiler.question')}` },
    { id: 3, label: `${t('syntaxcompiler.defaultAnswer')}` },
    { id: 4, label: '' },
  ];

  return (
    <>
      {!question || !pagination ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table sx={{ margin: '0px 24px', width: '-webkit-fill-available' }}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} sx={{ fontWeight: '600' }} children={headCell.label} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {question.map((row: any) => (
                <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.text_sv}</TableCell>
                  <TableCell align="left">
                    {row.default_answer === -1 ? t('syntaxcompiler.no') : t('syntaxcompiler.yes')}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      children={t('syntaxcompiler.showQuestion')}
                      variant="contained"
                      startIcon={<VisibilityIcon />}
                      onClick={() => navigation(`/syntaxcompiler/questions/${row.id}`)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', margin: '0px 24px' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={pagination.countTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      )}
    </>
  );
};

export default TableQuestion;
