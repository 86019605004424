import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function AdminButton() {
  const { t } = useTranslation();

  const location = useLocation();
  const routes = ['/settings', '/users', '/side_effects_management', '/diagregister'];
  const activeRoute = routes.some((el) => el === location.pathname);

  const isActive = (path: string) => location.pathname.includes(path);

  const [admin, setAdmin] = useState(null);
  const open = Boolean(admin);
  const handleClickadmin = (event: any) => setAdmin(event.currentTarget);
  const handleCloseadmin = () => setAdmin(null);

  return (
    <>
      <Button
        children={t('menu.administration')}
        variant="text"
        component="div"
        sx={{
          flexGrow: 1,
          textTransform: 'none',
          color: activeRoute ? 'black' : 'white',
          display: { xs: 'none', md: 'flex' },
        }}
        onClick={handleClickadmin}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<KeyboardArrowDownIcon />}
      />

      <Menu
        anchorEl={admin}
        open={open}
        onClose={handleCloseadmin}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <NavLink to="/settings" style={{ textDecoration: 'none', color: isActive('settings') ? '#1976d2' : 'black' }}>
          <MenuItem children={t('menu.settings')} onClick={handleCloseadmin} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
        <NavLink to="/users" style={{ textDecoration: 'none', color: isActive('users') ? '#1976d2' : 'black' }}>
          <MenuItem children={t('menu.users')} onClick={handleCloseadmin} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
        <NavLink
          to="/side_effects_management"
          style={{ textDecoration: 'none', color: isActive('side_effects_management') ? '#1976d2' : 'black' }}
        >
          <MenuItem children={t('menu.sideEffectDatabase')} onClick={handleCloseadmin} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
        <NavLink
          to="/diagregister"
          style={{ textDecoration: 'none', color: isActive('diagregister') ? '#1976d2' : 'black' }}
        >
          <MenuItem children={t('menu.diagnosticRegister')} onClick={handleCloseadmin} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
        <NavLink to="/history" style={{ textDecoration: 'none', color: isActive('history') ? '#1976d2' : 'black' }}>
          <MenuItem children={t('menu.history')} onClick={handleCloseadmin} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
      </Menu>
    </>
  );
}
