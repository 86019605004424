import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const GoBackButton = () => {
  const navigation = useNavigate();
  const handleClick = () => navigation(-1);

  return (
    <Button onClick={handleClick} variant="text" size="small" startIcon={<ArrowBackIcon />} sx={{ margin: '10px' }}>
      Go back
    </Button>
  );
};

export default GoBackButton;
