import { useEffect, useState, MouseEvent } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import { enableMaintenanceMode, getMaintenanceModeStatus, disableMaintenanceMode } from '../../../../api/service';

enum Mode {
  on = 'on',
  off = 'off',
}

export default function ApiMaintenanceMode() {
  const { t } = useTranslation();
  const [mode, setMode] = useState<Mode>(Mode.off);

  const getMode = async () => {
    getMaintenanceModeStatus().then((res) => setMode(res.data.maintenance));
  };

  useEffect(() => {
    getMode();
  }, []);

  const handleChange = (event: MouseEvent<HTMLElement>, newMode: string) => {
    if (newMode === Mode.on) {
      enableMaintenanceMode().then((res) => {
        if (res.status == 200 && res.data.maintenance === Mode.on) {
          setMode(Mode.on);
        }
      });
    }

    if (newMode === Mode.off) {
      disableMaintenanceMode().then((res) => {
        if (res.status == 200 && res.data.maintenance === Mode.off) {
          setMode(Mode.off);
        }
      });
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={mode}
      exclusive
      onChange={handleChange}
      sx={{
        backgroundColor: mode === Mode.off ? 'LightGreen' : 'LightPink',
        color: 'black',
        fontWeight: 'bold',
      }}
      size="small"
    >
      <ToggleButton
        value={Mode.off}
        sx={{
          color: 'black',
          backgroundColor: 'Gainsboro',
          '&:hover': { backgroundColor: 'LightGreen' },
        }}
        title={t('menu.disableMaintenanceMode')}
      >
        Off
      </ToggleButton>
      <ToggleButton
        value={Mode.on}
        sx={{
          color: 'black',
          backgroundColor: 'Gainsboro',
          '&:hover': { backgroundColor: 'LightPink' },
        }}
        title={t('menu.enableMaintenanceMode')}
      >
        On
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
