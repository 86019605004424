import { useEffect, useState } from 'react';
import { apiClient } from '../../../../api/axios';
import parse from 'html-react-parser';

import DocumentationLayout from '../..';
import { Box } from '@mui/material';
import '../../styles.css';
import Spinner from '../../../../components/Spinner';

const Overview = () => {
  const [overview, setOverview] = useState<string>('');

  useEffect(() => {
    const getRequestError = async () => {
      try {
        const { data } = await apiClient.get('/documentation/api/overview');
        setOverview(data);
      } catch (error) {
        throw error;
      }
    };

    getRequestError();
  }, []);

  return (
    <DocumentationLayout>
      <Box sx={{ m: '24px' }}>
        <Box sx={{ width: 'inherit' }} className="container">
          {overview ? parse(overview) : <Spinner />}
        </Box>
      </Box>
    </DocumentationLayout>
  );
};

export default Overview;
