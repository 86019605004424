import { ButtonGroup, IconButton, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IData } from '../..';

function BtnDrugIdList({ data }: { data: IData }) {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ButtonGroup variant="contained" size="small" color="primary">
        <Button size="small" disabled={!data.parsedRequestData?.drugids?.length} onClick={() => setOpen(true)} >
          {t('administration.showDrugIdList')}
        </Button>
        <IconButton
          size="small"
          onClick={() => navigator.clipboard.writeText(data.parsedRequestData?.drugids?.join(', ') || '')}
        >
          <ContentCopy fontSize="small" />
        </IconButton>
      </ButtonGroup>
      <Dialog maxWidth={'sm'} open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ textAlign: 'center' }}>
          {t('administration.drugIdList')}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
            { data.parsedRequestData?.drugids?.join(', ') || '' }
        </DialogContent>
      </Dialog>
    </>
  );
}

export default BtnDrugIdList;
