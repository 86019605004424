import { apiClient } from '../api/axios';

export const getUsers = () => apiClient('/users');

export const searchDataUsers = (word: any, page: number, limit: number, showAll: boolean, sort?: string[]) => {
  const params = {
    search: word,
    page,
    limit,
    status: [] as number[],
    sort: [] as string[],
  };

  if (!showAll) {
    params.status = [1];
  }

  if (sort && sort.length) {
    params.sort = sort;
  }

  return apiClient.get(`/users`, {
    params,
  });
};

export const addNewUser = (data: any) => apiClient.post(`/users`, data);

export const getUsersById = (id: number | any) => apiClient(`/users/${id}`);

export const updateDataUser = (id: any, data: any) => apiClient.put(`/users/${id}`, data);

export const updateUserPassword = (userId: any, data: any) =>
  apiClient.post(`/users/${userId}/password`, data, { params: { id: userId } });

export const getRecordsUser = (id: number) =>
  apiClient(`/records`, {
    params: { rel_type: 1, rel_id: id },
  });

export const deleteUser = (id: any) => apiClient.delete(`/users/${id}`);

export const generateTwoStepCode = (userId: number) => apiClient(`/users/${userId}/twostep/add`);
export const postGenerateTwoStepCode = (userId: number, secret: string, code: string) =>
  apiClient.post(`/users/${userId}/twostep/add`, { secret, code });

export const disableTwoStepAuth = (userId: number) => apiClient(`/users/${userId}/twostep/disable`);
