import { Button, TextField, Typography, Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import IntegrationTable from './components/TableIntegration';
import Layout from '../../layouts/Header';
import { useEffect, useState } from 'react';
import { searchDataApiIntegration } from '../../api/apiIntegration';
import Spinner from '../../components/Spinner';
import { hintSearchField } from '../../helpers';

interface IApiData {
  id: number;
  status: number;
  product_register_id: number;
  public_key: string;
  request_key: string;
  request_key_iv: string;
  lang_codes_id: number;
  licenses_id: number;
  regionId: number;
  syntax_analyse_id: number;
  kidney_function: number;
  kidney_function_name: string;
  syntax_analysis_name: string;
  license_number: number;
  license_type_name: string;
  customer_id: number;
  customer_number: number;
  customer_company: string;
  request_key_decoded: string;
}

interface IPagination {
  page: number;
  pages: number;
  limit: number;
  countTotal: number;
  countFiltered: number;
}

const ApiIntegration = () => {
  const { t } = useTranslation();

  const [apiData, setApiData] = useState<IApiData[]>([]);
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<IPagination>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [checked, setChecked] = useState<boolean>(false);
  const [sort, setSort] = useState<Array<string>>([]);
  const [searchableFields, setSearchableFields] = useState<Array<string>>([]);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        searchDataApiIntegration(search, 0, rowsPerPage, [1], checked, sort).then((res) => {
          setApiData(res.data.data);
          setSearchableFields(res.data.searchableFields);
          const pagination = {
            ...res.data.pagination,
            countTotal: res.data.pagination.countFiltered,
          };
          setPagination(pagination);
          setPage(0);
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '24px' }}>
        <Typography children={t('apiIntegration.apiIntegration')} variant="h5" />
        <Link to="/integration/add/" style={{ textDecoration: 'none', color: 'white' }}>
          <Button children={t('apiIntegration.addNewIntegration')} variant="contained" startIcon={<AddIcon />} />
        </Link>
      </Box>
      <Tooltip title={hintSearchField(searchableFields)}>
        <TextField
          label={t('apiIntegration.search')}
          type="search"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          sx={{ ml: '24px' }}
        />
      </Tooltip>

      {!pagination ? (
        <Spinner />
      ) : (
        <IntegrationTable
          data={{
            apiData,
            setApiData,
            pagination,
            setPagination,
            page,
            setPage,
            rowsPerPage,
            setRowsPerPage,
            search,
            checked,
            setChecked,
            sort,
            setSort,
          }}
        />
      )}
    </Layout>
  );
};

export default ApiIntegration;
