import { useState, useEffect, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDrugsById } from '../../../../api/productRegister';
import { formatDateYMD } from '../../../../helpers';

import Layout from '../../../../layouts/Header';
import Spinner from '../../../../components/Spinner';
import GoBackButton from '../../../../components/GoBackButton';
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableBody, Paper, styled } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Axios, AxiosResponse } from 'axios';

interface IDrugs {
  id: number;
  product_register: number;
  name: string;
  note: string;
  strength?: number;
  strength_unit: string;
  atc_code: string;
  unit_amount_numerical: number;
  unit_amount_multiple1: number;
  unit_amount_multiple2: number;
  store_price: number;
  daily_dose_per_unit: number;
  product_id: number;
  drug_id: number;
  nplid: number;
  nplpackid: number;
  genericid: string;
  drug_form: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  old_register: boolean;
  ingredients: [
    {
      id: number;
      drug_id: number;
      substanceId: string;
      name: string;
    },
    {
      id: number;
      drug_id: number;
      substanceId: string;
      name: string;
    }
  ];
}

const CardDrugs: FC = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [drugDescription, setDrugDescription] = useState<IDrugs>();

  useEffect(() => {
    getDrugsById(Number(id)).then((res: AxiosResponse) => setDrugDescription(res.data.data));
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'aliceblue',
      color: 'black',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const info = [
    { id: 1, name: t('productlist.strengthNumerical'), value: drugDescription?.strength },
    { id: 2, name: t('productlist.strengthUnit'), value: drugDescription?.strength_unit },
    { id: 3, name: t('productlist.atcCode'), value: drugDescription?.atc_code },
    { id: 4, name: t('productlist.packNumberNumeric'), value: drugDescription?.unit_amount_numerical },
    { id: 5, name: t('productlist.packMultiple1'), value: drugDescription?.unit_amount_multiple1 },
    { id: 6, name: t('productlist.packMultiple2'), value: drugDescription?.unit_amount_multiple2 },
    { id: 7, name: t('productlist.pharmSalePrice'), value: drugDescription?.store_price },
    { id: 8, name: t('productlist.numOfDailyDoses'), value: drugDescription?.daily_dose_per_unit },
    { id: 9, name: t('productlist.itemNum'), value: drugDescription?.product_id },
    { id: 10, name: t('productlist.drugId'), value: drugDescription?.drug_id },
    { id: 11, name: t('productlist.nplId'), value: drugDescription?.nplid },
    { id: 12, name: t('productlist.nplPackId'), value: drugDescription?.nplpackid },
    { id: 13, name: t('productlist.pharmForm'), value: drugDescription?.drug_form },
    { id: 14, name: t('productlist.retrieved'), value: formatDateYMD(drugDescription?.created?.date || null) },
  ];

  return (
    <Layout>
      <GoBackButton />
      {!drugDescription ? (
        <Spinner />
      ) : (
        <>
          <Typography children={drugDescription.name} variant="h3" sx={{ m: '0 24px 24px' }} />
          <Box sx={{ display: 'flex', m: '0 24px 48px', justifyContent: 'space-between' }}>
            <Box sx={{ width: '40%' }}>
              <Typography variant={'h5'}>{t('productlist.info')}</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {info.map((row) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell children={row.name} component="th" scope="row" />
                        <StyledTableCell children={row.value} align="right" />
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box sx={{ width: '40%' }}>
              <Typography variant={'h5'}>{t('productlist.containsSubstances')}</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow></TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }} children={t('productlist.name')} />
                      <TableCell sx={{ fontWeight: 'bold' }} children={t('productlist.substanceId')} align="right" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {drugDescription.ingredients.map((row: any) => (
                      <StyledTableRow key={row.id}>
                        <StyledTableCell children={row.name} component="th" scope="row" />
                        <StyledTableCell children={row.substanceId} align="right" />
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      )}
    </Layout>
  );
};

export default CardDrugs;
