import { apiClient } from '../api/axios';

export const getDataSideEffects = () => apiClient(`/side-effects`);

export const searchSideEffects = (word: string, page: number, limit: number, sort?: string[]) => {
  const params = {
    search: word,
    page,
    limit,
    sort: [] as string[],
  };
  if (sort && sort.length) {
    params.sort = sort;
  }

  return apiClient(`/side-effects`, {
    params,
  });
};

export const getRecordsSideEffects = () =>
  apiClient(`/records`, {
    params: { rel_type: 3 },
  });

export const getRecordsSideEffectsById = (analysisId: any) =>
  apiClient(`/records`, {
    params: { rel_type: 3, rel_id: analysisId },
  });

export const getVersionSideEffects = () => apiClient('/side-effects/versions');
export const getVersionSideEffectsById = (id: number) => apiClient(`/side-effects/versions/${id}`);

export const postVersionFileUpload = (textfile: any) =>
  apiClient.post(
    `/side-effects/datafiles/upload`,
    { textfile },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );

export const acceptVersionSideEffects = (id: number) => apiClient(`/side-effects/versions/${id}/accept`);
