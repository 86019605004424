import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LangButton from '../../layouts/Header/components/LangButton';
import { AppBar, Box, Button, MenuItem, Toolbar } from '@mui/material';
import imgLogo from '../../assets/images/logo.png';
interface ILayout {
  children: React.ReactNode;
}
const DocumentationLayout: FC<ILayout> = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isActive = (path: string) => location.pathname.includes(path);

  return (
    <>
      <Box sx={{ flexGrow: 1, textAlign: 'center' }}>
        <AppBar position="static" color="primary">
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex' }}>
              <Button variant="outlined" sx={{ backgroundColor: '#FFF' }}>
                <img src={imgLogo} alt="img logo" height={24} />
              </Button>
              <Link
                to="/documentation/overview"
                style={{ textDecoration: 'none', color: isActive('overview') ? 'black' : 'white' }}
              >
                <MenuItem children={t('general.docOverview')} sx={{ fontSize: '0.875rem' }} />
              </Link>
              <Link
                to="/documentation/api_calls_doc"
                style={{ textDecoration: 'none', color: isActive('api_calls_doc') ? 'black' : 'white' }}
              >
                <MenuItem children={t('general.docApiCalls')} sx={{ fontSize: '0.875rem' }} />
              </Link>
              <Link
                to="/documentation/request_error_messages"
                style={{ textDecoration: 'none', color: isActive('request_error_messages') ? 'black' : 'white' }}
              >
                <MenuItem children={t('general.docReqErrMsg')} sx={{ fontSize: '0.875rem' }} />
              </Link>
            </Box>
            <LangButton />
          </Toolbar>
        </AppBar>
      </Box>
      {children}
    </>
  );
};

export default DocumentationLayout;
