import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addAlgorithmLineRow,
  getAnalysisModules,
  getSyntAnalysisById,
  getSyntaxAlgorithms,
} from '../../../../../../../../api/syntax';
import { queryRule, validationField } from '../../../../../../../../helpers';
import { IStructure } from '../..';

interface IAlgoItem {
  id: number;
  name: string;
}

const BtnRowAddAlgo = ({
  el,
  setStructure,
}: {
  el: IStructure;
  setStructure: React.Dispatch<React.SetStateAction<IStructure>>;
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [dataList, setDataList] = useState({
    algorithmList: [],
    moduleTypeList: [],
  });

  const [value, setValue] = useState({
    algorithm: '',
    moduleType: '',
    index: '',
    hidden: false,
    loading: true,
  });

  return (
    <>
      <Tooltip title={t('syntaxcompiler.addAlgorithm')}>
        <IconButton
          color={'primary'}
          onClick={() => {
            setOpen(true);

            Promise.all([getSyntaxAlgorithms(), getAnalysisModules()])
              .then((res) => {
                setValue({ ...value, loading: false });
                setDataList({ ...dataList, algorithmList: res[0].data.data, moduleTypeList: res[1].data.data });
              })
              .catch((error) => {
                setValue({ ...value, loading: false });
                setErrorMessage(error.response?.data?.messages || error.message);
              });
          }}
          sx={{ p: '5px' }}
        >
          <CalculateIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('syntaxcompiler.selectAlgorithm')}</DialogTitle>

        <DialogContent dividers={true}>
          {value.loading ? (
            t('syntaxcompiler.loading')
          ) : errorMessage ? (
            <Alert variant="filled" severity="error" sx={{ width: 'fit-content' }}>
              {errorMessage}
            </Alert>
          ) : (
            <>
              <Box>
                <FormControl variant="standard" sx={{ width: '100%', marginTop: '10px' }}>
                  <InputLabel children={t('syntaxcompiler.selectAlgorithm')} />

                  <Select
                    value={value.algorithm}
                    onChange={(e) => setValue({ ...value, algorithm: e.target.value })}
                    label={t('syntaxcompiler.addAlgorithm')}
                  >
                    <MenuItem value={''}>
                      <em>None</em>
                    </MenuItem>
                    {dataList.algorithmList.map((el: IAlgoItem) => (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <FormControl variant="standard" sx={{ width: '100%', marginTop: '10px' }}>
                <InputLabel children={t('syntaxcompiler.selectModuleType')} />

                <Select
                  value={value.moduleType}
                  onChange={(e) => setValue({ ...value, moduleType: e.target.value })}
                  label={t('syntaxcompiler.selectModuleType  ')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dataList.moduleTypeList
                    .filter((el) => el !== 'GROUP')
                    .map((el: string, index: number) => (
                      <MenuItem key={index} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                value={value.index}
                onChange={(e) => setValue({ ...value, index: e.target.value })}
                variant="standard"
                label={t('syntaxcompiler.index')}
                sx={{ width: '100%' }}
                error={validationField(value.index)}
              />
              {value.moduleType !== 'GROUP' ? (
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Checkbox checked={value.hidden} onChange={() => setValue({ ...value, hidden: !value.hidden })} />
                  }
                  label={t('syntaxcompiler.hideModule')}
                />
              ) : null}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              setValue({ ...value, algorithm: '', moduleType: '', index: '', hidden: false });
            }}
          />
          <Button
            children={t('syntaxcompiler.addAlgorithm')}
            color="success"
            variant="contained"
            disabled={!value.moduleType || !value.algorithm || validationField(value.index)}
            onClick={() => {
              addAlgorithmLineRow(
                el.analysis,
                el.id,
                +value.algorithm,
                value.moduleType,
                value.index,
                queryRule(value.moduleType, value.hidden)
              )
                .then(() => {
                  getSyntAnalysisById(el.analysis).then((res) => setStructure(res.data.structure));
                })
                .catch((error) => {
                  setErrorMessage(error.response.data.messages);
                });
              setOpen(false);
              setValue({ ...value, algorithm: '', moduleType: '', index: '', hidden: false });
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BtnRowAddAlgo;
