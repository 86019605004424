import {
  Button,
  FormControl,
  Box,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import GoBackButton from '../../../../../../components/GoBackButton';
import RecentEvent from '../../../../../../components/RecentEvent';
import Layout from '../../../../../../layouts/Header';
import { useTranslation } from 'react-i18next';
import {
  acceptVersionSideEffects,
  getRecordsSideEffectsById,
  getVersionSideEffects,
  getVersionSideEffectsById,
  postVersionFileUpload,
} from '../../../../../../api/sideEffects';
import { formatDate, showError } from '../../../../../../helpers';
import Spinner from '../../../../../../components/Spinner';

interface IDataVersion {
  id: number;
  status: number;
  run_time: number;
  latest_activated: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  version_number: number;
  file_id: number;
  created_by: number;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  rows: number;
  related_datafile?: {
    id: number;
    rel_type: number;
    rel_id: number;
    name: string;
    extension: string;
    type: string;
    folder: string;
    path: string;
    size: number;
    created: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
    created_by: number;
    created_by_type: number;
  };
  fileName?: string;
}

interface IVersionDataInfo {
  parseResult: boolean;
  errors: Array<string>;
  rows?: number;
  runtime?: number;
  item: {
    id: number;
    status: number;
    run_time: number;
    latest_activated: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
    version_number: number;
    file_id: number;
    created_by: number;
    created: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
    rows: number;
    related_datafile: {
      id: number;
      rel_type: number;
      rel_id: number;
      name: string;
      extension: string;
      type: string;
      folder: string;
      path: string;
      size: number;
      created: {
        date: string;
        timezone_type: number;
        timezone: string;
      };
      created_by: number;
      created_by_type: number;
    };
    fileName: string;
  };
}

const Version = () => {
  const { t } = useTranslation();

  const [dataVersion, setDataVersion] = useState<IDataVersion[]>([]);
  const [versionRecords, setVersionRecords] = useState([]);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [file, setFile] = useState();
  const [versionDataInfo, setVersionDataInfo] = useState<IVersionDataInfo>();
  const [version, setVersion] = useState<IDataVersion | any>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [backDrop, setBackDrop] = useState(false);
  const [parseResult, setParseResult] = useState(false);

  const onFileChange = (event: any) => setFile(event.target.files[0]);
  const handleChangeVersion = (event: any) => setVersion(event.target.value);

  useEffect(() => {
    getVersionSideEffects().then((res) => setDataVersion(res.data.data));
  }, []);

  useEffect(() => {}, [versionDataInfo]);

  useEffect(() => {
    if (version.id) {
      getRecordsSideEffectsById(version?.id)
        .then((res) => setVersionRecords(res.data.data))
        .catch((error) => setErrorMessage(error.response.data.messages));
      getVersionSideEffectsById(version?.id)
        .then((res) => {
          setVersionDataInfo(undefined);
          setVersionDataInfo(res.data);
        })
        .catch((error) => setErrorMessage(error.response.data.messages));
    }
  }, [version.id]);

  const Input = styled('input')({
    display: 'none',
  });

  return (
    <Layout>
      <GoBackButton />
      <Box sx={{ m: '0 24px', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography children={t('administration.version')} variant="h5" />
          <Typography children={t('administration.sideEffectVersionsLabel')} variant="subtitle1" />
          {dataVersion.length === 0 ? (
            <Spinner />
          ) : (
            <FormControl fullWidth variant="standard" sx={{ mt: '24px' }}>
              <InputLabel children={t('administration.selectVersion')} />
              <Select value={version} onChange={handleChangeVersion}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {dataVersion.map((el: any) => (
                  <MenuItem key={el.id} value={el}>
                    {`${el.version_number} - ${t('administration.thCreated')} - ${
                      el?.created === null ? '' : formatDate(el?.created.date)
                    } - ${t('administration.lines')} ${el.rows}`}
                  </MenuItem>
                ))}
              </Select>
              {!versionDataInfo || !version ? null : (
                <>
                  {' '}
                  <Typography children={t('administration.dbFilePreview')} variant="h5" sx={{ mt: '24px' }} />
                  <Typography variant="h6" children={t('administration.totalRows')} sx={{ display: 'contents' }} />
                  <Typography
                    variant="h6"
                    children={versionDataInfo?.rows}
                    sx={{ display: 'contents', color: '#1976d2;', fontWeight: 600 }}
                  />
                  <Typography variant="h6" children={` ${t('administration.runTakes')}`} sx={{ display: 'contents' }} />
                  <Typography
                    variant="h6"
                    children={versionDataInfo?.runtime}
                    sx={{ display: 'contents', color: '#1976d2;', fontWeight: 600 }}
                  />
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell children={t('administration.thFileName')} />
                          <TableCell children={t('administration.thSysDataVersionInternal')} />
                          <TableCell children={t('administration.thLastActivated')} />
                          <TableCell children={t('administration.thCreated')} />
                          <TableCell children={t('administration.thCreatedBy')} />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                          <TableCell component="th" scope="row">
                            {versionDataInfo?.item?.fileName.split(/_[0-9]/gm)[0]}
                          </TableCell>
                          <TableCell>{versionDataInfo?.item?.version_number}</TableCell>
                          <TableCell>{formatDate(versionDataInfo?.item?.latest_activated.date)}</TableCell>
                          <TableCell>{formatDate(versionDataInfo?.item?.created?.date || null)}</TableCell>
                          <TableCell>{t('administration.noUsersFound')}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Typography children={t('administration.warning')} sx={{ color: '#ffc107', fontWeight: 600 }} />
                  {versionDataInfo.errors.map((el: string, index: number) => (
                    <Typography key={index} children={el} sx={{ color: 'red' }} />
                  )) || ''}
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography children={t('administration.toContinueClickSideEffectDb')} variant="h6" />
                    <Button
                      children={t('administration.activateSideEffectDb')}
                      variant="contained"
                      color="success"
                      disabled={versionDataInfo.errors.length > 0}
                      sx={{ width: 'fit-content' }}
                      onClick={() => {
                        versionDataInfo.parseResult
                          ? acceptVersionSideEffects(version.id).then((res: any) => {
                              setParseResult(res.data.parseResult);
                              setBackDrop(false);
                              setOpen(true);
                            })
                          : null;
                        setBackDrop(true);
                      }}
                    />
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDrop}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                </>
              )}
            </FormControl>
          )}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            children={t('administration.uplNewDbFile')}
            variant="contained"
            component="span"
            startIcon={<FileUploadIcon />}
            onClick={() => setOpenUpload(true)}
          />
          <Dialog fullWidth open={openUpload} onClose={() => setOpenUpload(false)}>
            <DialogTitle children={t('administration.uplDbFile')} />
            <DialogContent dividers>
              <label htmlFor="contained-button-file">
                <Input accept=".txt" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                <Button
                  children={t('administration.uplDbFile')}
                  variant="contained"
                  component="span"
                  startIcon={<FileUploadIcon />}
                />
              </label>
            </DialogContent>
            <DialogActions>
              <Button
                children={t('settings.cancel')}
                variant="contained"
                color="error"
                onClick={() => {
                  setOpenUpload(false);
                  setFile(undefined);
                }}
              />
              <Button
                children={t('settings.upload')}
                variant="contained"
                color="success"
                disabled={!file}
                onClick={() => {
                  postVersionFileUpload(file)
                    .then((response: any) => {
                      getVersionSideEffects().then((res) => {
                        setDataVersion(res.data.data);
                        const currentEl = res.data.data.find((el: any) => el.id === response.data.newVersionId);
                        setVersion(currentEl);
                      });
                      setOpen(true);
                    })
                    .catch((error) => {
                      setOpenError(true);
                      setErrorMessage(error.response.data.messages);
                    });
                  setOpenUpload(false);
                  setFile(undefined);
                }}
              />
            </DialogActions>
          </Dialog>
          <RecentEvent records={versionRecords} />
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {!parseResult ? t('settings.fileUploadSuccess') : t('administration.dbUpdated')}
        </Alert>
      </Snackbar>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => {
            setOpenError(false);
          }}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default Version;
