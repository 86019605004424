import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { hintSearchField } from '../../../../../../helpers';
import RemoveIntegrationBtn from '../RemoveIntegrationBtn';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../../../../../store';
import { observer } from 'mobx-react-lite';

const IntegrationTable = observer(() => {
  const { id } = useParams();
  const navigation = useNavigate();
  const {
    getCustomerApiIntegration,
    customerApiIntegration,
    deleted,
    customerData,
    customerLicenses,
    isLoadingApiIntegration,
  } = store.customerRegister;
  const { t } = useTranslation();

  const headCells = [
    { id: '1', label: t('apiIntegration.thPublicKey'), sortField: 'public_key' },
    { id: '2', label: t('apiIntegration.thKidneyFuncCalc'), sortField: 'kidney_function' },
    { id: '3', label: t('apiIntegration.thSyntaxAnalysis'), sortField: 'syntax_analyse_id' },
    { id: '4', label: t('apiIntegration.thRegionId'), sortField: 'regionId' },
    { id: '5', label: t('apiIntegration.thLicenseNum'), sortField: '' },
    { id: '6', label: t('apiIntegration.thLicenseType'), sortField: '' },
    { id: '7', label: t('apiIntegration.thRequestKey'), sortField: '' },
    { id: '8', label: `${t('apiIntegration.thActivated')} (handshake)`, sortField: 'status' },
    { id: '9', label: '', sortField: '' },
  ];

  const [sortFlag, setSortflag] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string>();
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  useEffect(() => {
    getCustomerApiIntegration(Number(id), search, page, rowsPerPage);
  }, [deleted, search]);

  const handleChangePage = (event: any, newPage: number) => {
    getCustomerApiIntegration(Number(id), search, newPage + 1, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    getCustomerApiIntegration(Number(id), search, event.target.value, rowsPerPage);
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSort = (sortField?: string) => {
    let sorting;
    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setOrderBy(sortField);
    }

    getCustomerApiIntegration(Number(id), search, page, rowsPerPage, sorting);
    setSortflag(!sortFlag);
    setPage(0);
  };

  const status = (request_key: string) => (request_key !== '0' ? t('apiIntegration.yes') : t('apiIntegration.no'));

  return (
    <Box sx={{ m: 3 }}>
      <Typography children={t('apiIntegration.apiIntegrForCustLicenses')} variant="h5" sx={{ p: '8px 0' }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Tooltip title={hintSearchField(customerApiIntegration?.searchableFields || [])}>
          <TextField
            size="small"
            label={t('licenses.search')}
            type="search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Tooltip>
        <Button
          children={t('apiIntegration.addNewIntegration')}
          variant="contained"
          disabled={customerData.status === 2 || customerLicenses?.data === null}
          startIcon={<AddIcon />}
          onClick={() => navigation(`/integration/add/${id}`)}
        />
      </Box>
      <Box>
        {isLoadingApiIntegration ? (
          t('customers.loading')
        ) : customerApiIntegration?.data === null ? (
          ''
        ) : (
          <Box sx={{ width: '100%' }}>
            <Table sx={{ tableLayout: 'fixed', width: '100%', overflowX: 0 }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                      {!headCell.sortField ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          IconComponent={ArrowDropUpIcon}
                          active={headCell.sortField === orderBy}
                          direction={sortFlag ? 'asc' : 'desc'}
                          onClick={() => {
                            handleSort(headCell.sortField);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {customerApiIntegration?.data?.map((row: any) => (
                  <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell sx={{ wordWrap: 'break-word' }} align="left">
                      {row.public_key}
                    </TableCell>
                    <TableCell align="left">{row.kidney_function_name}</TableCell>
                    <TableCell align="left">{row.syntax_analysis_name}</TableCell>
                    <TableCell align="left">{row.regionId}</TableCell>
                    <TableCell align="left">{row.license_number}</TableCell>
                    <TableCell align="left">{row.license_type_name}</TableCell>
                    <TableCell sx={{ wordWrap: 'break-word' }} align="left">
                      {row.request_key_decoded || t('apiIntegration.notActivated')}
                    </TableCell>
                    <TableCell align="left">{status(row.request_key)}</TableCell>
                    <TableCell align="left">
                      <RemoveIntegrationBtn data={{ row, setPage }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', m: '0px 24px' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={customerApiIntegration?.pagination?.countFiltered || 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default IntegrationTable;
