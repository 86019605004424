import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, DialogTitle, DialogContentText, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import RecentEvent from '../../../../../../components/RecentEvent';
import store from '../../../../../../store';
import { observer } from 'mobx-react-lite';

const AlertDialog = observer(() => {
  const { t } = useTranslation();
  const { setCustomerStatus, deleteCustomer, customerRecord, customerData, customerLicenses, isLoadingLicenses } =
    store.customerRegister;

  const [open, setOpen] = useState<boolean>(false);
  const [openRemove, setOpenRemove] = useState<boolean>(false);

  const status = {
    active: 1,
    inActive: 2,
  };

  return (
    <Box sx={{ m: 3, display: 'flex', flexDirection: 'column' }}>
      {isLoadingLicenses ? null : (
        <>
          <Button
            children={customerData?.status === 2 ? t('customers.activateCustomer') : t('customers.deactivateCustomer')}
            color={customerData?.status === 2 ? 'success' : 'warning'}
            variant="contained"
            onClick={() => setOpen(true)}
          />
          <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
            <DialogTitle children={customerData?.status === 2 ? t('customers.active') : t('customers.remInactivate')} />
            <DialogContent dividers>
              <DialogContentText children={customerData?.company} />
            </DialogContent>
            <DialogActions>
              <Button
                children={t('customers.cancel')}
                variant="contained"
                color="error"
                onClick={() => setOpen(false)}
              />
              <Button
                children={
                  customerData?.status === 2 ? t('customers.confirmActivate') : t('customers.confirmInactivate')
                }
                variant="contained"
                color="success"
                onClick={() => {
                  if (customerData.status === 2) {
                    setOpen(false);
                    setCustomerStatus(customerData.id, status.active);
                  } else {
                    setOpen(false);
                    setCustomerStatus(customerData.id, status.inActive);
                  }
                }}
              />
            </DialogActions>
          </Dialog>

          <Button
            children={t('customers.removeCustomer')}
            color="error"
            variant="contained"
            sx={{ mt: '10px' }}
            onClick={() => setOpenRemove(true)}
            disabled={customerLicenses.data !== null}
          />
          <Dialog open={openRemove} onClose={() => setOpenRemove(false)} fullWidth>
            <DialogTitle children={t('customers.remRemove')} />
            <DialogContent dividers>
              <DialogContentText children={customerData?.company} />
            </DialogContent>
            <DialogActions>
              <Button
                children={t('customers.cancel')}
                variant="contained"
                color="error"
                onClick={() => setOpenRemove(false)}
              />
              <Button
                children={t('customers.confirmRemove')}
                variant="contained"
                color="success"
                onClick={() => {
                  setOpenRemove(false);
                  deleteCustomer(customerData.id);
                }}
              />
            </DialogActions>
          </Dialog>
        </>
      )}

      <RecentEvent records={customerRecord} />
    </Box>
  );
});

export default AlertDialog;
