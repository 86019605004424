import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { getSyntaxTestData, postTestVisual } from '../../../api/testAnalysis';

import Layout from '../../../layouts/Header';
import Spinner from '../../../components/Spinner';
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { showError } from '../../../helpers';
import InteractionBtn from './components/InteractionBtn';

interface IOptions {
  kidney: number;
  side: number;
  double: number;
  interaction: number;
  recommendation: number;
  analyse: number;
  regionId: number;
  kidney_function: number;
}

interface IAnalysis {
  id: number;
  number: number;
  status: number;
  register: number;
  language: number;
  title: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  updated: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  created_by: number;
  updated_by: null;
}

interface IRegions {
  regionId: number;
  regionName: string;
}

const VisualizationComponent = () => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch } = useForm();

  const [kidneyFunctions] = useState([
    { id: 1, name: 'Cockcroft-Gault' },
    { id: 2, name: 'Lund-Malmö, reviderad' },
  ]);
  const [syntaxAnalysis, setSyntaxAnalysis] = useState<IAnalysis[]>([]);
  const [options, setOptions] = useState<IOptions>();
  const [regions, setRegions] = useState<IRegions[]>([]);
  const [openIframe, setOpenIframe] = useState<boolean>(false);
  const [html, setHtml] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [className, setClassName] = useState([
    { id: 1, title: 'A', label: 'Undertemined Severity', color: '#f2df00' },
    { id: 2, title: 'B', label: 'Moderate Interaction', color: '#edb300' },
    { id: 3, title: 'C', label: 'Severe Interaction', color: '#f99600' },
    { id: 4, title: 'D', label: 'Contraindicated Drug Interaction', color: 'crimson' },
    { id: 5, title: 'X', label: 'Drug duplicaton', color: 'DodgerBlue' },
  ]);

  useEffect(() => {
    getSyntaxTestData()
      .then((res) => {
        setOptions(res.data.options);
        setSyntaxAnalysis(res.data.analyses);
        setRegions(res.data.regions);
      })
      .catch((error) => {
        setErrorMessage(error.response?.data?.messages || error.message);
        setOpenError(true);
      });
  }, []);

  const rules = /[\n ,]/gm;
  const watchIds = watch('drugids');
  const countIds = watchIds?.split(rules).filter((el: string) => el !== '').length;

  const onSubmit = (data: any) => {
    const { drugids } = data;
    const updateData = {
      ...data,
      drugids: drugids.split(rules).filter((el: string) => el !== ''),
      classNames: className.map((el) => el.title.toLocaleUpperCase()),
      colors: className.map((el) => el.color),
    };

    postTestVisual(updateData)
      .then((res) => {
        setHtml(res.data);
        setOpenIframe(false);
        setOpen(true);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.messages || error.response.message);
        setOpenError(true);
        setOpenIframe(false);
      });
  };

  return (
    <Layout>
      <Typography children={t('tests.visualizationComponent')} variant="h5" sx={{ m: '24px' }} />
      {!options ? (
        <Spinner />
      ) : (
        <>
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} sx={{ m: '24px' }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
              <Controller
                render={({ field }) => (
                  <>
                    <InputLabel children={t('tests.kidneyFuncCalc')} />
                    <Select {...field} sx={{ display: 'block', mb: '20px' }} variant="standard">
                      <MenuItem children={<em>{t('tests.none')}</em>} value={0} />
                      {kidneyFunctions.map((kidneyFunction: any) => (
                        <MenuItem children={kidneyFunction.name} key={kidneyFunction.id} value={kidneyFunction.id} />
                      ))}
                    </Select>
                  </>
                )}
                control={control}
                name="kidney_function"
                defaultValue={options.kidney_function}
              />
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.kidney}
                name="kidney"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      return field.onChange(e.target.checked ? 1 : 0);
                    }}
                    defaultChecked={!!options.kidney}
                  />
                )}
              />
              <InputLabel children={t('tests.kidneyFuncCalc')} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.double}
                name="double"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      return field.onChange(e.target.checked ? 1 : 0);
                    }}
                    defaultChecked={!!options.double}
                  />
                )}
              />
              <InputLabel children={t('tests.dualUseOfDrugs')} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.interaction}
                name="interaction"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                    defaultChecked={!!options.interaction}
                  />
                )}
              />
              <InputLabel children={t('tests.interactAnalysis')} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.recommendation}
                name="recommendation"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                    defaultChecked={!!options.recommendation}
                  />
                )}
              />
              <InputLabel children={t('tests.recomendAnalysis')} />
            </Box>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
              <Controller
                render={({ field }) => (
                  <>
                    <InputLabel children={t('tests.silRegion')} />
                    <Select {...field} sx={{ display: 'block', mb: '20px' }} variant="standard">
                      <MenuItem children={<em>{t('tests.none')}</em>} value="" />
                      {regions.map((region: IRegions) => (
                        <MenuItem children={region.regionName} key={region.regionId} value={region.regionId} />
                      ))}
                    </Select>
                  </>
                )}
                control={control}
                name="regionId"
                defaultValue={options.regionId}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
              <Controller
                render={({ field }) => (
                  <>
                    <InputLabel children={t('tests.syntaxAnalysis')} />
                    <Select {...field} sx={{ display: 'block', mb: '20px' }} variant="standard">
                      <MenuItem children={<em>{t('tests.none')}</em>} value="" />
                      {syntaxAnalysis.map((syntax: IAnalysis) => (
                        <MenuItem children={`${syntax.number} ${syntax.title}`} key={syntax.id} value={syntax.id} />
                      ))}
                    </Select>
                  </>
                )}
                control={control}
                name="analyse"
                defaultValue={options.analyse}
              />
            </FormControl>

            <Box
              sx={{
                '& .MuiTextField-root': { m: '0 0', minWidth: '60%' },
              }}
            >
              <InputLabel children={t('tests.drugIds') + ' (input format: 123456, 7891011, 12131415)'} />
              <Controller
                name="drugids"
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    multiline
                    rows={5}
                    value={field.value}
                    onChange={field.onChange}
                    helperText={t('tests.twoDrugs')}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                '& .MuiTextField-root': { m: '0 0', minWidth: '60%' },
                'mt': 1,
              }}
            ></Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '60%', alignItems: 'center' }}>
              <Box>
                {className.map((el) => (
                  <Box key={el.id} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <InteractionBtn className={className} setClassName={setClassName} key={el.id} el={el} />
                    <Typography sx={{ ml: 1 }}>{el.label}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                '& .MuiTextField-root': { m: '0 0', minWidth: '60%' },
              }}
            >
              <InputLabel children={t('tests.copyright')} sx={{ mt: 1 }} />
              <Controller
                defaultValue={t('tests.copyrightDefaultValue')}
                name="copyright"
                control={control}
                render={({ field }) => <TextField value={field.value} onChange={field.onChange} />}
              />
            </Box>

            <Button
              type="submit"
              children={t('tests.send')}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ m: '24px 0' }}
              onClick={() => setOpenIframe(true)}
              disabled={countIds < 2 ? true : false}
            />
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openIframe}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog fullScreen={true} maxWidth={'xl'} open={open} onClose={() => setOpen(false)}>
              <DialogContent>
                <Box sx={{ height: '100%' }}>
                  <iframe srcDoc={html} width="100%" height="90%"></iframe>
                  <DialogActions>
                    <Button
                      children={t('tests.cancel')}
                      variant="contained"
                      color="error"
                      onClick={() => setOpen(false)}
                    />
                  </DialogActions>
                </Box>
              </DialogContent>
            </Dialog>

            {errorMessage ? (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openError}
                autoHideDuration={5000}
                onClose={() => setOpenError(false)}
              >
                {showError(errorMessage)}
              </Snackbar>
            ) : (
              ''
            )}
          </Box>
        </>
      )}
    </Layout>
  );
};

export default VisualizationComponent;
