import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IData } from '../..';
import { exportLogFile } from '../../../../../../api/logs';

function BtnDownload({ data }: { data: IData }) {
  const { t } = useTranslation();

  const handleDownloadFile = (data: IData) =>
    exportLogFile(data.id).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${data.id}_${data.request_key}_${data.status}_${data.endpoint}.txt`);
      document.body.appendChild(link);
      link.click();
    });

  return (
    <Button variant="contained" size="small" onClick={() => handleDownloadFile(data)}>
      {t('administration.downloadFile')}
    </Button>
  );
}

export default BtnDownload;
