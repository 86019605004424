import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getSyntAnalysisById, getTranslation, postTranslation } from '../../../../../../../../api/syntax';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
  IconButton,
  Tooltip,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { IStructure } from '../..';
import { useParams } from 'react-router-dom';

interface IData {
  lang_code_id: number;
  iso6391: string;
  text?: string;
  id_translation_item: number;
}

interface ILanquageData {
  1?: string;
  2?: string;
  3?: string;
  4?: string;
  5?: string;
}

const BtnLanguage = ({
  el,
  setStructure,
}: {
  el: IStructure;
  setStructure: React.Dispatch<React.SetStateAction<IStructure>>;
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState<IData[]>([]);

  const onSubmit: SubmitHandler<ILanquageData> = (data) => {
    setOpen(false);
    postTranslation(el.analysis, el.id, Object.keys(data), Object.values(data)).then(() => {
      getSyntAnalysisById(id).then((res) => setStructure(res.data.structure));
    });
  };

  return (
    <>
      <Tooltip title={t('syntaxcompiler.textLineTranslations')}>
        <IconButton
          color={'primary'}
          size="small"
          onClick={() => {
            setOpen(true);
            getTranslation(el.analysis, el.id).then((res) => {
              setData(res.data.translations);
            });
          }}
        >
          <LanguageIcon />
        </IconButton>
      </Tooltip>

      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle children={t('syntaxcompiler.textLineTranslations')} />
          {data.length === 0 ? (
            <DialogContent dividers={true} children={t('syntaxcompiler.loading')} />
          ) : (
            <DialogContent dividers={true}>
              <FormControl fullWidth>
                {data.map((el: IData, index: number) => (
                  <Box key={index} sx={{ display: 'contents' }}>
                    <FormLabel children={el.iso6391} sx={{ mt: '20px' }} />
                    <TextField {...register(`${el.lang_code_id}`)} defaultValue={el.text} variant="standard" />
                  </Box>
                ))}
              </FormControl>
            </DialogContent>
          )}
          <DialogActions>
            <Button
              children={t('syntaxcompiler.close')}
              variant="contained"
              color="error"
              onClick={() => setOpen(false)}
            />

            <Button type="submit" children={t('syntaxcompiler.save')} variant="contained" color="success" />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default BtnLanguage;
