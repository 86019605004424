import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate, showError } from '../../helpers';
import { getProductRegister, searchDrugs, syncProductRegister } from '../../api/productRegister';

import Layout from '../../layouts/Header';
import Spinner from '../../components/Spinner';
import ProductTable from './components/ProductTable';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosResponse } from 'axios';

interface ISync {
  id: number;
  last_sync: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  register_name: string;
}

interface IPagination {
  page: number;
  pages: number;
  limit: number;
  countTotal: number;
  countFiltered: number;
}

interface IDrugs {
  id: number;
  product_register: number;
  name: string;
  note: string;
  strength: number;
  strength_unit: string;
  atc_code: string;
  unit_amount_numerical: number;
  unit_amount_multiple1: number;
  unit_amount_multiple2: number;
  store_price: number;
  daily_dose_per_unit: number;
  product_id: number;
  drug_id: number;
  nplid: number;
  nplpackid: number;
  genericid: string;
  drug_form: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  old_register: boolean;
}

const ProductRegister: FC = () => {
  const { t } = useTranslation();

  const [openInactive, setOpenInactive] = useState<boolean>(false);
  const [search, setSeatch] = useState<string>('');
  const [drugs, setDrugs] = useState<IDrugs[]>();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [sync, setSync] = useState<ISync>();
  const [pagination, setPagination] = useState<IPagination>();
  const [sort, setSort] = useState();

  useEffect(() => {
    getProductRegister().then((res: AxiosResponse) => setSync(res.data.data[0]));
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        searchDrugs(search, 0, rowsPerPage, sort).then((res: AxiosResponse) => {
          setDrugs(res.data.data);
          const pagination = {
            ...res.data.pagination,
            countTotal: res.data.pagination.countFiltered,
          };
          setPagination(pagination);
          setPage(0);
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: '24px', flexWrap: 'wrap' }}>
        <Box>
          <Typography children={t('productlist.prodRegister')} variant="h5" />

          <Tooltip
            title={[
              t('productlist.preparationName'),
              ' * ',
              t('productlist.atcCode'),
              ' * ',
              t('productlist.drugId'),
              ' * ',
              t('productlist.genericid'),
              ' * ',
              t('productlist.nplId'),
              ' * ',
              t('productlist.nplPackId'),
            ]}
          >
            <TextField
              label={t('productlist.search')}
              type="search"
              size="small"
              sx={{ mt: '24px' }}
              onChange={(event) => setSeatch(event.target.value)}
            />
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link to="/product_list/missing_drugs/" style={{ textDecoration: 'none', color: 'white' }}>
            <Button
              children={t('productlist.unsyncProducts')}
              color="warning"
              variant="contained"
              sx={{ width: '100%' }}
            />
          </Link>
          <Button
            children={t('productlist.syncProductRegister')}
            variant="contained"
            onClick={() => setOpenInactive(true)}
            sx={{ mt: '10px' }}
          />

          <Dialog open={openInactive} onClose={() => setOpenInactive(false)}>
            <DialogTitle children={t('productlist.syncronization')} sx={{ background: 'aliceblue' }} />
            <DialogContent>
              <Typography children={`${t('productlist.syncronizationOf')} product register`} variant="subtitle1" />
              <Typography children={t('productlist.syncDesc')} variant="body2" />
              <Typography children={t('productlist.doYouWantToCont')} variant="h6" />
            </DialogContent>
            <DialogActions sx={{ background: 'aliceblue' }}>
              <Button
                children={t('productlist.cancel')}
                color="error"
                variant="contained"
                onClick={() => setOpenInactive(false)}
              />
              <Button
                children={t('productlist.startSync')}
                color="success"
                variant="contained"
                onClick={() => {
                  setOpenInactive(false);
                  syncProductRegister(sync?.id ?? 1)
                    .then(() => {
                      setOpen(true);
                      localStorage.setItem('sync', 'true');
                    })
                    .catch((error) => {
                      setErrorMessage(error.response.data.messages);
                      setOpenError(true);
                    });
                }}
              />
            </DialogActions>
          </Dialog>
        </Box>
      </Box>

      {!sync ? (
        <Spinner />
      ) : (
        <>
          <Typography sx={{ color: '#28a745', m: '10px 24px' }} variant="subtitle1">{`${sync?.register_name} - ${t(
            'productlist.lastSync'
          )} ${formatDate(sync.last_sync?.date)}`}</Typography>
          {!pagination ? (
            <Spinner />
          ) : (
            <ProductTable
              data={{ drugs, setDrugs, pagination, page, setPage, rowsPerPage, setRowsPerPage, search, sort, setSort }}
            />
          )}
        </>
      )}

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => {
            setOpenError(false);
          }}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('productlist.pleaseWaitSync')}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default ProductRegister;
