import { Typography } from '@mui/material';
import Layout from '../../../layouts/Header';

const TestAlgorithms = () => (
  <Layout>
    <Typography variant="h5" sx={{ m: '24px' }}>
      In development...
    </Typography>
  </Layout>
);
export default TestAlgorithms;
