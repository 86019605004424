import { FC, useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  AppBar,
  Box,
  Toolbar,
  Button,
  MenuItem,
  Typography,
  Menu,
  IconButton,
  LinearProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import imgLogo from '../../assets/images/logo.png';
import AccountMenu from './components/AccountMenu';
import SyntaxButton from './components/SyntaxButton';
import AdminButton from './components/AdminButton';
import LangButton from './components/LangButton';
import ApiMaintenanceMode from './components/ApiMaintenanceMode';
import { Container } from '@mui/system';
import { getSyncStatusProductRegister } from '../../api/productRegister';
import { showError } from '../../helpers';
import TestsButton from './components/TestsButton';

interface ILayout {
  children: React.ReactNode;
}

const Layout: FC<ILayout> = ({ children }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const pages = [
    { id: 1, label: t('menu.dashboard'), path: '/' },
    { id: 2, label: t('menu.customerRegister'), path: '/customers' },
    { id: 3, label: t('menu.apiIntegration'), path: '/integration' },
    { id: 4, label: t('menu.productRegister'), path: '/product_list' },
    { id: 5, label: t('menu.testAnalysis'), path: '/test-analysis' },
    { id: 6, label: t('menu.analysis'), path: '/syntaxcompiler' },
    { id: 7, label: t('menu.calculationAlgorithm'), path: '/syntaxcompiler/algorithms' },
    { id: 8, label: t('menu.questions'), path: '/syntaxcompiler/questions' },
    { id: 9, label: t('menu.settings'), path: '/settings' },
    { id: 10, label: t('menu.users'), path: '/users' },
    { id: 11, label: t('menu.sideEffectDatabase'), path: '/side_effects_management' },
    { id: 12, label: t('menu.diagnosticRegister'), path: '/diagregister' },
    { id: 13, label: t('menu.apiDocumentation'), path: '/documentation' },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);

  useEffect(() => {
    if (localStorage.getItem('sync') === 'true') {
      const timerId = setInterval(() => {
        getSyncStatusProductRegister('1')
          .then((res) => {
            if (res.data.isLastSyncSuccess) {
              localStorage.setItem('sync', 'false');
              setOpen(true);
            }
          })
          .catch((error) => {
            setErrorMessage(error.response.data.messages || error.message);
            setOpenError(true);
            localStorage.setItem('sync', 'false');
            navigation('/product_list');
          });
      }, 10000);
      return () => clearInterval(timerId);
    }
  }, [localStorage.getItem('sync')]);

  return (
    <>
      <AppBar position="static">
        <Container maxWidth={false}>
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.id} onClick={handleCloseNavMenu}>
                    <Link
                      to={`${page.path}`}
                      target={page.path === '/documentation' ? '_blank' : '_self'}
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <Typography textAlign="center">{page.label}</Typography>
                    </Link>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Button
              variant="outlined"
              disabled={true}
              sx={{ backgroundColor: '#FFF', display: { xs: 'none', md: 'flex' } }}
            >
              <img src={imgLogo} alt="img logo" height={24} />
            </Button>

            <Button
              variant="text"
              component="div"
              sx={{ flexGrow: 1, color: 'inherit', textTransform: 'none', display: { xs: 'none', md: 'flex' } }}
            >
              <NavLink
                to={`/`}
                style={({ isActive }) => {
                  return {
                    textDecoration: 'none',
                    display: 'block',
                    margin: '1rem 0',
                    color: isActive ? '#000' : '#FFF',
                  };
                }}
              >
                {t('menu.dashboard')}
              </NavLink>
            </Button>

            <Button
              variant="text"
              component="div"
              sx={{ flexGrow: 1, color: 'inherit', textTransform: 'none', display: { xs: 'none', md: 'flex' } }}
            >
              <NavLink
                to={`/customers`}
                style={({ isActive }) => {
                  return {
                    textDecoration: 'none',
                    display: 'block',
                    margin: '1rem 0',
                    color: isActive ? '#000' : '#FFF',
                  };
                }}
              >
                {t('menu.customerRegister')}
              </NavLink>
            </Button>

            <Button
              variant="text"
              component="div"
              sx={{ flexGrow: 1, color: 'inherit', textTransform: 'none', display: { xs: 'none', md: 'flex' } }}
            >
              <NavLink
                to={`/integration`}
                style={({ isActive }) => {
                  return {
                    textDecoration: 'none',
                    display: 'block',
                    margin: '1rem 0',
                    color: isActive ? '#000' : '#FFF',
                  };
                }}
              >
                {t('menu.apiIntegration')}
              </NavLink>
            </Button>

            <Button
              variant="text"
              component="div"
              sx={{ flexGrow: 1, color: 'inherit', textTransform: 'none', display: { xs: 'none', md: 'flex' } }}
            >
              <NavLink
                to={`/product_list`}
                style={({ isActive }) => {
                  return {
                    textDecoration: 'none',
                    display: 'block',
                    margin: '1rem 0',
                    color: isActive ? '#000' : '#FFF',
                  };
                }}
              >
                {t('menu.productRegister')}
              </NavLink>
            </Button>
            <TestsButton />
            <SyntaxButton />
            <AdminButton />
            <Button
              variant="text"
              component="div"
              sx={{ flexGrow: 1, textTransform: 'none', color: 'white', display: { xs: 'none', md: 'flex' } }}
            >
              <Link target={'_blank'} to="/documentation/overview" style={{ textDecoration: 'none', color: 'white' }}>
                {t('menu.apiDocumentation')}
              </Link>
            </Button>
            <ApiMaintenanceMode />
            <AccountMenu />
            <LangButton />
          </Toolbar>
        </Container>
      </AppBar>
      {localStorage.getItem('sync') === 'true' ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress color="secondary" />
        </Box>
      ) : null}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
          location.pathname === '/product_list' ? location.reload() : null;
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpen(false);
            location.pathname === '/product_list' ? location.reload() : null;
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('productlist.syncCompleted')}
        </Alert>
      </Snackbar>
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => {
            setOpenError(false);
          }}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
      {children}
    </>
  );
};

export default Layout;
