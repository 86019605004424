import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IRow {
  id: number;
  product_register: number;
  api_call: string;
  atc_code: string;
  product_id: number;
  drug_id: number;
  nplid: number;
  nplpackid: number;
  is_sync: boolean;
  is_processed: boolean;
  status_after_sync: boolean;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  integration: string;
}

const CallBtn = ({ row }: { row: IRow }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button children={t('productlist.call')} variant="contained" onClick={() => setOpen(true)} />
      <Dialog open={open} fullWidth onClose={() => setOpen(false)}>
        <DialogTitle children={t('productlist.callData')} />
        <DialogContent>{<pre>{row.api_call}</pre>}</DialogContent>
        <DialogActions>
          <Button children={t('productlist.close')} variant="contained" color="error" onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CallBtn;
