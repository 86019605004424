import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { fetchFile, getDataFileDownload } from '../../../../../../api/languages';
import { showError } from '../../../../../../helpers';

interface IRow {
  id: number;
  name_text_en: string;
  status: number;
  iso6391: string;
}

const BtnDownLoad = ({ row }: { row: IRow }) => {
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      rel_type: [],
    },
  });

  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = (data: any) => {
    getDataFileDownload(row.id, data)
      .then((res) => {
        fetchFile(res.config.url, row.name_text_en);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.messages);
        setOpenError(true);
      });
    setOpen(false);
  };

  return (
    <>
      <Button
        children={t('settings.downloadLangFile')}
        variant="contained"
        onClick={() => setOpen(true)}
        disabled={row.id === 1 || row.status === 0}
      />
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle children={`${t('settings.downloadLangFileFor')} ${t(`language.${row.iso6391}`)}`} />
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent dividers sx={{ display: 'grid' }}>
            <FormControlLabel
              label={t('settings.syntaxQuestions')}
              control={<Checkbox {...register('rel_type')} value={16} />}
            />
            <FormControlLabel
              label={t('settings.syntaxTextLines')}
              control={<Checkbox {...register('rel_type')} value={15} />}
            />
            <FormControlLabel
              label={t('settings.syntaxReturnResponse')}
              control={<Checkbox {...register('rel_type')} value={12} />}
            />
            <FormControlLabel
              label={t('settings.phase20text')}
              control={<Checkbox {...register('rel_type')} value={5} />}
            />
            <FormControlLabel
              label={t('settings.fassText')}
              control={<Checkbox {...register('rel_type')} value={4} />}
            />
          </DialogContent>
          <DialogActions>
            <Button children={t('settings.cancel')} variant="contained" color="error" onClick={() => setOpen(false)} />
            <Button type="submit" children={t('settings.download')} variant="contained" color="success" />
          </DialogActions>
        </Box>
      </Dialog>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => setOpenError(false)}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </>
  );
};

export default BtnDownLoad;
