import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from '../../../../../../store';

function RemoveIntegrationBtn({ data }: any) {
  const { row, setPage } = data;
  const { t } = useTranslation();
  const { deleteApiIntegration } = store.customerRegister;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        color="error"
        variant="contained"
        onClick={() => setOpen(true)}
        children={t('apiIntegration.removeIntegration')}
      />
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle children={t('apiIntegration.removeTitle')} />
        <DialogContent dividers>{t('apiIntegration.doYouWantRemoveIntegration')}</DialogContent>
        <DialogActions>
          <Button
            children={t('apiIntegration.cancel')}
            variant="contained"
            color="error"
            onClick={() => setOpen(false)}
          />
          <Button
            children={t('apiIntegration.yesRemoveIntegration')}
            variant="contained"
            color="success"
            onClick={() => {
              setOpen(false);
              deleteApiIntegration(row.id);
              setPage(0);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RemoveIntegrationBtn;
