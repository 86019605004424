import { useTranslation } from 'react-i18next';

import Layout from '../../../../layouts/Header';
import GoBackButton from '../../../../components/GoBackButton';
import DragTable from './componets/DragTable';
import { Typography, TextField, Box, Tooltip } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { getMissingDrugs, searchMissingDrugs } from '../../../../api/productRegister';
import { getData } from '../../../../api/dashboard';
import Spinner from '../../../../components/Spinner';
import { hintSearchField } from '../../../../helpers';
import { AxiosResponse } from 'axios';

export interface IMissingDrugs {
  id: number;
  product_register: number;
  api_call: string;
  atc_code: string;
  product_id: number;
  drug_id: number;
  nplid: number;
  nplpackid: number;
  is_sync: boolean;
  is_processed: boolean;
  status_after_sync: boolean;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  integration: string;
}

const MissingDrugs: FC = () => {
  const { t } = useTranslation();
  const [missingDrugs, setMissingDrugs] = useState<IMissingDrugs[]>();

  const [sync, setSync] = useState<any>();
  const [search, setSeatch] = useState<string>('');
  const [searchableFields, setSearchableFields] = useState([]);

  useEffect(() => {
    getMissingDrugs().then((res: AxiosResponse) => {
      setMissingDrugs(res.data.data);
      setSearchableFields(res.data.searchableFields);
    });
    getData().then((res: AxiosResponse) => setSync(res.data.data.statistic));
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () => searchMissingDrugs(search).then((res: any) => setMissingDrugs(res.data.data)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <Layout>
      <GoBackButton />
      <Typography children={t('productlist.unsyncProducts')} variant="h5" sx={{ m: '24px' }} />
      <Box sx={{ ml: '24px' }}>
        <Tooltip title={hintSearchField(searchableFields)}>
          <TextField
            label={t('productlist.search')}
            type="search"
            size="small"
            onChange={(event) => setSeatch(event.target.value)}
          />
        </Tooltip>
        {!sync || !missingDrugs ? (
          <Spinner />
        ) : (
          <>
            <Typography
              children={`${t('productlist.lastSync')} ${sync.last_sync}`}
              sx={{ color: '#28a745' }}
              variant="subtitle1"
            />
            <Typography
              children={`${t('productlist.nextAutoSync')} ${sync.next_sync}`}
              sx={{ color: '#ed6c02' }}
              variant="subtitle1"
            />
            <DragTable missingDrugs={missingDrugs} />
          </>
        )}
      </Box>
    </Layout>
  );
};

export default MissingDrugs;
