import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { IModulesList, INotification } from '../..';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { deleteModulesById, getModules } from '../../../../../../api/modules';
import Notification from '../../../../../../components/Notification';

function ModulesListItem({
  el,
  setModuleList,
}: {
  el: IModulesList;
  setModuleList: React.Dispatch<React.SetStateAction<IModulesList[]>>;
}) {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const [removeBtn, setRemoveBtn] = useState(false);
  const [notification, setNotification] = useState<INotification>({
    loading: false,
    message: '',
    status: 'error',
  });

  return (
    <>
      <ListItem sx={{ pl: 0, pt: 0 }} divider>
        <ListItemText children={el.name} />
        {!el.static ? (
          <>
            <IconButton onClick={() => navigation(`/syntaxcompiler/modules/${el.id}`)}>
              <EditIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => setRemoveBtn(true)}>
              <DeleteIcon color="error" />
            </IconButton>
            <Dialog open={removeBtn} fullWidth>
              <DialogTitle>{t('syntaxcompiler.remove')}</DialogTitle>
              <DialogContent dividers>
                <DialogContentText>{el.name}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  children={t('syntaxcompiler.cancel')}
                  color="error"
                  variant="contained"
                  onClick={() => setRemoveBtn(false)}
                />
                <Button
                  children={t('syntaxcompiler.remove')}
                  color="success"
                  variant="contained"
                  onClick={() => {
                    deleteModulesById(el.id)
                      .then(() => {
                        setNotification({
                          ...notification,
                          loading: true,
                          message: t('syntaxcompiler.removed'),
                          status: 'success',
                        });
                        getModules().then((res) => setModuleList(res.data.data));
                      })
                      .catch((error) => {
                        setNotification({
                          ...notification,
                          loading: true,
                          message: error.response.data.messages,
                          status: 'error',
                        });
                      });
                    setRemoveBtn(false);
                  }}
                />
              </DialogActions>
            </Dialog>
          </>
        ) : (
          ''
        )}
      </ListItem>
      {notification.loading ? <Notification data={{ notification, setNotification }} /> : null}
    </>
  );
}

export default ModulesListItem;
