import { Alert } from '@mui/material';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

interface INotifications {
  [x: string]: any;
  id: number;
  status: number;
  license_type_id: number;
  customer_id: number;
  callsforperiod: number;
  created: string;
  expires: string;
  customer: string;
  warning: string;
}
[];

const Notifications = ({ notifications }: { notifications: INotifications }) =>
  notifications.map((el: any) => {
    const { customer_id } = el;
    return (
      <Alert key={el.id} severity="error" sx={{ margin: '15px 24px' }}>
        <Link to={`/customers/card/${customer_id}`} style={{ textDecoration: 'none', color: '#721c24' }}>
          {`${el.customer} - `} {parse(el.warning)}
        </Link>
      </Alert>
    );
  });

export default Notifications;
