import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDateYMD } from '../../../../helpers';

import Spinner from '../../../../components/Spinner';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Box,
  TableSortLabel,
} from '@mui/material';
import { ascSortDrugs, descSortDrugs, searchDrugs } from '../../../../api/productRegister';

const ProductTable = ({ data }: any) => {
  const { drugs, setDrugs, pagination, page, setPage, rowsPerPage, setRowsPerPage, search, sort, setSort } = data;
  const { t } = useTranslation();

  const headCells = [
    { id: '1', label: t('productlist.preparationName'), sortField: 'name' },
    { id: '2', label: t('productlist.atcCode'), sortField: 'atc_code' },
    { id: '3', label: t('productlist.drugId'), sortField: 'drug_id' },
    { id: '4', label: t('productlist.genericid'), sortField: 'genericid' },
    { id: '5', label: t('productlist.nplId'), sortField: 'nplid' },
    { id: '6', label: t('productlist.nplPackId'), sortField: 'nplpackid' },
    { id: '7', label: t('productlist.added'), sortField: 'created' },
    { id: '8', label: t('productlist.historyPrep'), sortField: 'old_register' },
    { id: '9', label: '', sortField: '' },
  ];

  const [sortFlag, setSortflag] = useState(false);
  const [orderBy, setOrderBy] = useState<any>();

  const handleChangePage = (event: any, newPage: number) => {
    searchDrugs(search, newPage + 1, rowsPerPage, sort).then((res) => setDrugs(res.data.data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    searchDrugs(search, 1, event.target.value, sort).then((res) => setDrugs(res.data.data));
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSort = (sortField?: string) => {
    let sorting;
    setOrderBy(sortField);
    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setSort(sorting);
    }

    searchDrugs(search, 1, rowsPerPage, sorting).then((res) => setDrugs(res.data.data));
    setPage(0);
  };

  return (
    <>
      {!drugs ? (
        <Spinner />
      ) : (
        <>
          <TableContainer sx={{ mt: '24px' }}>
            <Table sx={{ minWidth: 650, m: '0px 24px', width: '-webkit-fill-available' }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                      {!headCell.sortField ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          IconComponent={ArrowDropUpIcon}
                          active={headCell.sortField === orderBy}
                          direction={sortFlag ? 'asc' : 'desc'}
                          onClick={() => {
                            handleSort(headCell.sortField);
                            setSortflag(!sortFlag);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {drugs.map((row: any) => (
                  <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.atc_code}</TableCell>
                    <TableCell align="left">{row.drug_id}</TableCell>
                    <TableCell align="left">{row.genericid}</TableCell>
                    <TableCell align="left">{row.nplid}</TableCell>
                    <TableCell align="left">{row.nplpackid}</TableCell>
                    <TableCell align="left">{formatDateYMD(row.created.date)}</TableCell>
                    <TableCell align="left">{row.old_register ? t('productlist.yes') : t('productlist.no')}</TableCell>
                    <TableCell align="left">
                      <Link to={`/product_list/card/${row.id}`} style={{ textDecoration: 'none', color: 'white' }}>
                        <Button children={t('productlist.showProduct')} variant="contained" />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', m: '0px 24px' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={pagination.countTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default ProductTable;
