import { apiClient } from './axios';

// export const login = () => axios('/access/login');
export const logOut = () => apiClient('/access/logout');
// export const checkAuth = () => apiClient(`/access/check-auth`);

export const sendResetLink = (email: any) => apiClient.post(`/access/reset/send`, { ...email });
export const verifyResetLink = (pPsM9kGuVD: any, dNdvTcCCqR: any) =>
  apiClient.get(`/access/reset/verify`, { params: { pPsM9kGuVD, dNdvTcCCqR } });
export const setNewPassword = (pPsM9kGuVD: any, dNdvTcCCqR: any, data: any) =>
  apiClient.post(`/access/reset/save`, { pPsM9kGuVD, dNdvTcCCqR, ...data });
