import { makeAutoObservable } from 'mobx';
import CustomerRegister from './CustomerRegister';
class RootStore {
  customerRegister = new CustomerRegister();
  constructor() {
    makeAutoObservable(this);
  }
}

export default new RootStore();
