import RecentEvent from '../../../../components/RecentEvent';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguagesExtended, getUsersRecords } from '../../../../api/languages';
import Spinner from '../../../../components/Spinner';
import Layout from '../../../../layouts/Header';
import { getStatus } from '../../../../helpers';
import BtnChangeStatus from './components/BtnChangeStatus';
import BtnDownLoad from './components/BtnDownLoad';
import BtnUpload from './components/BtnUpload';
import Notification from '../../../../components/Notification';

interface IData {
  id: number;
  status: number;
  counts?: [
    {
      rel_type?: string;
      cnt_items: string;
      rel_type_description: string;
    }
  ];
  integrations: [
    {
      id: number;
      status: number;
      product_register_id: number;
      public_key: string;
      request_key: string;
      request_key_iv: string;
      lang_codes_id: number;
      licenses_id: number;
      regionId: number;
      syntax_analyse_id: number;
      kidney_function: number;
    }
  ];
  count_integrations: number;
  iso6391: string;
  name_text: string;
  name_text_en: string;
}

interface IRecord {
  id: number;
  user: number;
  api: number;
  rel_type: number;
  rel_id: number;
  type: string;
  data: string;
  location: string;
  user_agent: {
    agent: string;
    is_browser: boolean;
    is_mobile: boolean;
    platform: string;
    browser: string;
    version: string;
    mobile: string;
  };
  timestamp: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  description: string;
  test: string;
  locale: string;
}

const Settings = () => {
  const { t } = useTranslation();

  const [data, setData] = useState<IData[]>([]);
  const [records, setRecodrs] = useState<IRecord[]>([]);
  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  useEffect(() => {
    getLanguagesExtended()
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        });
      });
    getUsersRecords()
      .then((res) => setRecodrs(res.data.data))
      .catch((error) => {
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        });
      });
  }, []);

  const headCells = [
    { id: '1', label: t('settings.status') },
    { id: '2', label: t('settings.language') },
    { id: '3', label: t('settings.translationsCount') },
    { id: '4', label: t('settings.integrationsCount') },
    { id: '5', label: '' },
    { id: '6', label: '' },
    { id: '7', label: '' },
  ];

  return (
    <Layout>
      {data.length > 0 ? (
        <>
          <Box sx={{ m: '24px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography children={t('settings.settings')} variant="h5" />
              <RecentEvent records={records} />
            </Box>
            <Typography children={t('settings.languageSettings')} sx={{ mt: '24px' }} variant="h6" />
          </Box>
          <Box sx={{ m: '24px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row: any) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">{row.status === 1 ? getStatus(row.status) : getStatus(2)}</TableCell>
                    <TableCell align="left">{t(`language.${row.iso6391}`)}</TableCell>
                    <TableCell align="left">
                      {row.counts.map((item: any) => (
                        <Typography key={item.rel_type}>{`${item.rel_type_description}: ${item.cnt_items}`}</Typography>
                      ))}
                    </TableCell>
                    <TableCell align="left">
                      <Typography>{row.count_integrations ? row.count_integrations : t('settings.no')}</Typography>
                    </TableCell>
                    <TableCell align="center">
                      <BtnDownLoad row={row} />
                    </TableCell>
                    <TableCell align="center">
                      <BtnUpload row={row} />
                    </TableCell>
                    <TableCell align="left">
                      <BtnChangeStatus row={row} setData={setData} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </>
      ) : (
        <Spinner />
      )}

      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </Layout>
  );
};

export default Settings;
