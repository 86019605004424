import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postQuestion } from '../../../../../../api/syntax';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Switch,
  Snackbar,
  Alert,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Stack } from '@mui/system';
import { showError } from '../../../../../../helpers';

const BtnAddQuestion = () => {
  const { t } = useTranslation();

  const [openQuestion, setOpenQuestion] = useState<boolean>(false);
  const [questionSelect, setQuestionSelect] = useState<string>('');
  const [defaultAnswer, setDefaultAnswer] = useState(-1);
  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);

  const handleChangeQuestionSelect = (event: any) => setQuestionSelect(event.target.value);
  const handleChangeDefaultAnswer = (event: any) => setDefaultAnswer(event.target.checked ? 1 : -1);

  return (
    <>
      <Button
        children={t('syntaxcompiler.addNewQuestion')}
        onClick={() => setOpenQuestion(true)}
        variant="contained"
        startIcon={<AddIcon />}
      />

      <Dialog fullWidth open={openQuestion} onClose={() => setOpenQuestion(false)}>
        <DialogTitle children={t('syntaxcompiler.addNewQuestion')} />
        <DialogContent dividers>
          <TextField
            sx={{ marginTop: 1, width: '100%' }}
            multiline
            label={t('syntaxcompiler.swedishLabel')}
            helperText={t('syntaxcompiler.possibilityToEnterQuestInOtherLang')}
            value={questionSelect}
            onChange={handleChangeQuestionSelect}
            autoFocus
          />
          <Typography variant="subtitle1" sx={{ mt: '24px' }}>
            {t('syntaxcompiler.defaultAnswer')}
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography>{t('syntaxcompiler.no')}</Typography>
            <Switch onChange={handleChangeDefaultAnswer} />
            <Typography>{t('syntaxcompiler.yes')}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            variant="contained"
            color="error"
            onClick={() => {
              setOpenQuestion(false);
              setQuestionSelect('');
            }}
          />
          <Button
            children={t('syntaxcompiler.save')}
            variant="contained"
            color="success"
            disabled={questionSelect.length === 0}
            onClick={() => {
              postQuestion(questionSelect, defaultAnswer)
                .then(() => {
                  setDefaultAnswer(-1);
                  setQuestionSelect('');
                  setOpen(true);
                })
                .catch((error) => {
                  setErrorMessage(error.response.data.messages);
                  setQuestionSelect('');
                  setOpenError(true);
                });
              setOpenQuestion(false);
            }}
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
          location.reload();
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpen(false);
            location.reload();
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('syntaxcompiler.questionSaved')}
        </Alert>
      </Snackbar>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => {
            setOpenError(false);
          }}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </>
  );
};

export default BtnAddQuestion;
