import { apiClient } from './axios';

export const getLanguagesExtended = () => apiClient('/languages/extended');
export const postStatusLanguage = (id: number, statusCode: number) =>
  apiClient.post(`/languages/${id}/status`, { status: statusCode });

export const getUsersRecords = () =>
  apiClient(`/records`, {
    params: { rel_type: 17 },
  });

export const removeLanguage = (id: number) => apiClient.delete(`/languages/${id}`);

export const getDataFileDownload = (id: number, data: any) =>
  apiClient(`/languages/${id}/datafiles/download`, { params: { data } });

export const fetchFile = (url: string | any, title: string) => {
  apiClient({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `translation_Swedish_${title}.csv`);
    document.body.appendChild(link);
    link.click();
  });
};

export const postDataFileUpload = (csvfile: any) =>
  apiClient.post(
    `/languages/datafiles/upload`,
    { csvfile },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );

export const getDataFileReview = (id: number, dataFielId: number) =>
  apiClient(`/languages/${id}/datafiles/${dataFielId}`);
export const getDataFileAccept = (id: number, dataFielId: number) =>
  apiClient(`/languages/${id}/datafiles/${dataFielId}/accept`);
