import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Layout from '../../layouts/Header';
import Spinner from '../../components/Spinner';
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Snackbar,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { formatDate, getStatus, hintSearchField } from '../../helpers';
import store from '../../store';
import { observer } from 'mobx-react-lite';

interface ICustomerData {
  id: number;
  status: number;
  number: number;
  company: string;
  corporate: string;
  address: string;
  zip: string;
  city: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}

const CustomerRegister: FC = observer(() => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { getCustomersList, customersList, customersListIsLoading, notification, closeNotification } =
    store.customerRegister;

  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [checked, setChecked] = useState<boolean>(false);
  const [sort, setSort] = useState<Array<string>>([]);
  const [sortFlag, setSortflag] = useState(false);
  const [orderBy, setOrderBy] = useState<string>('');

  useEffect(() => {
    getCustomersList(search, page, rowsPerPage, checked, sort);
  }, [search]);

  const headCells = [
    { id: '1', label: 'Status', sortField: '' },
    { id: '2', label: t('customers.thCustomerNumber'), sortField: 'number' },
    { id: '3', label: t('customers.thBusiness'), sortField: 'company' },
    { id: '4', label: t('customers.thCity'), sortField: 'city' },
    { id: '5', label: t('customers.thCreated'), sortField: 'created' },
    { id: '6', label: '', sortField: '' },
  ];

  const handleChangePage = (event: any, newPage: number) => {
    getCustomersList(search, newPage + 1, rowsPerPage, checked, sort);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    getCustomersList(search, 1, event.target.value, checked, sort);
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    getCustomersList(search, 1, rowsPerPage, event.target.checked, sort);

    setPage(0);
  };

  const handleSort = (sortField?: string) => {
    let sorting;

    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setSort(sorting);
      setOrderBy(sortField);
    }

    getCustomersList(search, 1, rowsPerPage, checked, sorting);
    setSortflag(!sortFlag);

    setPage(0);
  };

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: ' 24px', flexWrap: 'wrap' }}>
        <Typography children={t('customers.registerCustomer')} variant="h5" />

        <Button
          children={t('customers.addNewCustomer')}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigation(`/customers/add`)}
        />
      </Box>

      <Tooltip title={hintSearchField(customersList?.searchableFields || [])}>
        <TextField
          label={t('customers.search')}
          type="search"
          size="small"
          onChange={(e) => setSearch(e.target.value)}
          sx={{ ml: '24px' }}
        />
      </Tooltip>

      {customersListIsLoading ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650, m: '0px 24px', width: '-webkit-fill-available' }}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                    {!headCell.sortField ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        IconComponent={ArrowDropUpIcon}
                        active={headCell.sortField === orderBy}
                        direction={sortFlag ? 'asc' : 'desc'}
                        onClick={() => {
                          handleSort(headCell.sortField);
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {customersList?.data?.map((row: ICustomerData) => (
                <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell children={getStatus(row.status)} align="left" />
                  <TableCell children={row.number} align="left" />
                  <TableCell children={row.company} align="left" />
                  <TableCell children={row.city} align="left" />
                  <TableCell children={formatDate(row.created.date)} align="left" />
                  <TableCell align="left">
                    {!row.status ? (
                      ''
                    ) : (
                      <Button
                        children={t('customers.showCustomer')}
                        variant="contained"
                        onClick={() => navigation(`/customers/card/${row.id}`)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', m: '0px 24px' }}>
            <FormControlLabel
              control={<Switch size="small" checked={checked} onChange={handleChange} />}
              label={t('customers.showDeleted')}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={customersList?.pagination?.countFiltered}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      )}
      {!notification.isLoading ? (
        ''
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={notification.isLoading}
          autoHideDuration={5000}
          onClose={() => closeNotification()}
        >
          <Alert variant="filled" onClose={() => closeNotification()} severity={notification.severity}>
            {typeof notification.message === 'string'
              ? t(notification.message)
              : Object.values(notification.message).map((el: any) => ` ${el}`)}
          </Alert>
        </Snackbar>
      )}
    </Layout>
  );
});

export default CustomerRegister;
