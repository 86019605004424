import { apiClient } from '../api/axios';

export const getCustomers = () => apiClient('/customers');
export const postData = (data: any) => apiClient.post(`/customers`, data);
export const putData = (id: number, data: any) => apiClient.put(`/customers/${id}`, data);
export const getLicensesCustomer = (customer_id: string | undefined) =>
  apiClient(`/licenses`, {
    params: {
      customer_id: `${customer_id}`,
      status: [1],
    },
  });
