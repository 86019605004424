import { makeAutoObservable, flow } from 'mobx';
import { apiClient } from '../api/axios';

export default class Store {
  customerData: any = null;
  isLoading: boolean = true;

  customersList: any = null;
  customersListIsLoading: boolean = true;

  customerRecord: any = null;

  customerLicenses: any = null;
  isLoadingLicenses: boolean = true;
  licensesList: any = null;
  license: any = null;

  customerApiIntegration: any = null;
  isLoadingApiIntegration: boolean = true;

  deleted: boolean = false;

  notification: any = {
    isLoading: false,
    message: '',
    severity: 'error',
  };

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  getCustomerData = flow(function* (this: Store, id) {
    this.isLoading = true;
    try {
      const {
        data: { data },
      } = yield apiClient(`/customers/${id}`);
      this.customerData = data[0];
      this.isLoading = false;
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
      this.isLoading = false;
    } finally {
      this.isLoading = false;
    }
  });

  setCustomerStatus = flow(function* (this: Store, id, status) {
    try {
      const {
        data: { item },
      } = yield apiClient.post(`/customers/${id}/status`, { status });
      this.customerData = item;
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: status === 2 ? 'customers.customerDectivated' : 'customers.customerActivated',
        severity: 'success',
      };
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  deleteCustomer = flow(function* (this: Store, id) {
    try {
      yield apiClient.delete(`/customers/${id}`);
      window.location.replace('/customers');
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: 'customers.customerRemoved',
        severity: 'success',
      };
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  getCustomerRecord = flow(function* (this: Store, id) {
    try {
      const {
        data: { data },
      } = yield apiClient('/records', {
        params: { rel_type: '2', rel_id: id },
      });
      this.customerRecord = data;
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  getCustomerLicenses = flow(function* (this: Store, customer_id: number, page: number, limit: number, sort?: any) {
    const params = {
      customer_id: `${customer_id}`,
      page,
      limit,
      status: [1],
      sort: [] as string[],
    };

    if (sort && sort.length) {
      params.sort = sort;
    }

    this.isLoadingLicenses = true;

    try {
      const { data } = yield apiClient(`/licenses`, {
        params,
      });
      this.customerLicenses = data;
      this.isLoadingLicenses = false;
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
      this.isLoadingLicenses = false;
    }
  });

  deleteLicense = flow(function* (this: Store, licenseId) {
    try {
      yield apiClient.delete(`/licenses/${licenseId}`);
      this.deleted = !this.deleted;
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: 'customers.licenseRemoved',
        severity: 'success',
      };
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  getCustomerApiIntegration = flow(function* (
    this: Store,
    customerId: number,
    search: string,
    page: number,
    limit: number,
    sort?: string[]
  ) {
    const params = {
      customer_id: customerId,
      search,
      page,
      limit,
      status: [1] as number[],
      sort: [] as string[],
    };
    if (sort && sort.length) {
      params.sort = sort;
    }

    this.isLoadingApiIntegration = true;
    try {
      const { data } = yield apiClient(`/integrations`, {
        params,
      });
      this.customerApiIntegration = data;
      this.isLoadingApiIntegration = false;
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
      this.isLoadingApiIntegration = false;
    }
  });

  deleteApiIntegration = flow(function* (this: Store, integrationId) {
    try {
      yield apiClient.delete(`/integrations/${integrationId}`);
      this.deleted = !this.deleted;
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: 'customers.integrationRemoved',
        severity: 'success',
      };
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  getLicensesList = flow(function* (this: Store) {
    try {
      const {
        data: { data },
      } = yield apiClient('/licenses/types');
      this.licensesList = data;
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  getCustomersList = flow(function* (
    this: Store,
    search?: string,
    page?: number,
    limit?: number,
    showAll?: boolean,
    sort?: string[]
  ) {
    const params = {
      search,
      page,
      limit,
      status: [] as number[],
      sort: [] as string[],
    };

    if (showAll) {
      params.status = [1, 2, 0];
    }

    if (sort && sort.length) {
      params.sort = sort;
    }

    this.customersListIsLoading = true;
    try {
      const { data } = yield apiClient('/customers', { params });
      this.customersList = data;
      this.customersListIsLoading = false;
    } catch (error: any) {
      this.customersListIsLoading = false;
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  createLicense = flow(function* (this: Store, data: any) {
    try {
      const {
        data: {
          item: { customer_id },
        },
      } = yield apiClient.post('/licenses', data);
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: 'customers.licenseAdded',
        severity: 'success',
      };
      window.location.replace(`/customers/card/${customer_id}`);
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  getLicense = flow(function* (this: Store, license_id: string | undefined) {
    try {
      const {
        data: { data },
      } = yield apiClient(`/licenses/${license_id}`);
      this.license = data[0];
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  putLicense = flow(function* (this: Store, license_id: string | undefined, data: any) {
    try {
      yield apiClient.put(`/licenses/${license_id}`, data);

      this.notification = {
        ...this.notification,
        isLoading: true,
        message: 'customers.customerInfoSaved',
        severity: 'success',
      };
    } catch (error: any) {
      this.notification = {
        ...this.notification,
        isLoading: true,
        message: error.response?.data?.messages || error.message,
        severity: 'error',
      };
    }
  });

  refresh = () => (this.license = null);

  closeNotification = () => (this.notification = { ...this.notification, isLoading: false, message: '', severity: '' });
}
