import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import TableQuestion from './components/TableQuestion';
import { useTranslation } from 'react-i18next';
import { getQuestions, searchQuestions } from '../../../../../../api/syntax';

export interface IQuestion {
  id: number;
  status: number;
  default_answer: number;
  draft_count: number;
  published_count: number;
  text_sv: string;
  text_sv_id: number;
}

const BtnLoadQuestion = ({ xml, setXml }: { xml: string; setXml: any }) => {
  const { t } = useTranslation();

  const [openQuestion, setOpenQuestion] = useState<boolean>(false);
  const [search, setSeatch] = useState<string>('');
  const [question, setQuestion] = useState<IQuestion[]>([]);

  useEffect(() => {
    getQuestions().then((res) => setQuestion(res.data.data));
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => searchQuestions(search, 0, 100).then((res) => setQuestion(res.data.data)), 500);
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <>
      <Button
        children={t('syntaxcompiler.loadQuestion')}
        onClick={() => setOpenQuestion(true)}
        variant="contained"
        color="warning"
        size="small"
        startIcon={<DownloadIcon />}
        sx={{ width: 'max-content' }}
      />
      <Dialog fullWidth open={openQuestion} onClose={() => setOpenQuestion(false)}>
        <DialogTitle children={t('syntaxcompiler.loadQuestion')} />
        <DialogContent dividers>
          <TextField
            label={t('productlist.search')}
            type="search"
            size="small"
            onChange={(event) => setSeatch(event.target.value)}
          />
          <TableQuestion question={question} xml={xml} setXml={setXml} />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.close')}
            variant="contained"
            color="error"
            onClick={() => setOpenQuestion(false)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BtnLoadQuestion;
