import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateUserPassword } from '../../../../../../../../api/users';

import { Typography, Box, TextField, Button } from '@mui/material';
import Notification from '../../../../../../../../components/Notification';

const Password = ({ userId }: { userId: number }) => {
  const { t } = useTranslation();

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm();

  const onSubmit = (data: any) => {
    updateUserPassword(userId, data)
      .then(() => {
        setNotification({
          ...notification,
          loading: true,
          message: t('administration.passwordChanged'),
          status: 'error',
        });
      })
      .catch((error) => {
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        });
      });
  };

  return (
    <>
      <Typography children={t('administration.changePassword')} variant="h6" sx={{ mt: 3 }} />
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ '& .MuiTextField-root': { width: '100%' } }}>
        <TextField
          {...register('password', {
            pattern: {
              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
              message: 'no valid',
            },
            setValueAs: (v) => v.trim(),
          })}
          variant="standard"
          sx={{ marginTop: 1 }}
          label={t('administration.enterNewPassword')}
          type="password"
        />
        {errors?.password?.type && (
          <Typography
            variant="caption"
            color="error"
            children={t('administration.passwordMustBe')}
            sx={{ display: 'block', ml: '10px' }}
          />
        )}

        <TextField
          {...register('password_repeat', {
            required: true,
            pattern: {
              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
              message: 'no valid',
            },
            setValueAs: (v) => v.trim(),
          })}
          variant="standard"
          sx={{ marginTop: 2 }}
          type="password"
          label={t('administration.repeatNewPassword')}
        />
        {errors?.password_repeat?.type && (
          <Typography
            variant="caption"
            color="error"
            children={t('administration.passwordMustBe')}
            sx={{ display: 'block', ml: '10px' }}
          />
        )}
        <Button
          type="submit"
          children={t('administration.save')}
          variant="contained"
          color="success"
          sx={{ mt: 3 }}
          disabled={!isDirty}
        />
        {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
      </Box>
    </>
  );
};

export default Password;
