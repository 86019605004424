import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import Layout from '../../../../../../layouts/Header';
import GoBackButton from '../../../../../../components/GoBackButton';
import Spinner from '../../../../../../components/Spinner';
import { Typography, Box, Button, TextField } from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { formatDateYMD } from '../../../../../../helpers';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import store from '../../../../../../store';
import { observer } from 'mobx-react-lite';
interface IData {
  expires: string;
  callsforperiod: string;
}

const NewLicense = observer(() => {
  const { t } = useTranslation();
  const { licenseId } = useParams();
  const navigation = useNavigate();
  const { putLicense, getLicense, license } = store.customerRegister;
  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      expires: license?.expires,
      callsforperiod: license?.callsforperiod,
    },
  });

  const onSubmit: SubmitHandler<IData> = (data) => {
    putLicense(licenseId, data);
    navigation(`/customers/card/${license?.customer_id}`);
  };

  useEffect(() => {
    getLicense(licenseId);
  }, []);

  const currentData = new Date().toISOString().slice(0, 10);

  return (
    <Layout>
      {license === null ? (
        <Spinner />
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GoBackButton />
              <Typography children={license?.license_name} variant="h5" sx={{ m: '0px 24px', p: '8px' }} />
              <Typography variant="h6" sx={{ m: '0px 24px', padding: '8px' }}>
                {t('licenses.renew')}
              </Typography>

              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ display: 'flex', m: '0 24px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      control={control}
                      name="expires"
                      defaultValue={license?.expires}
                      render={({ field }) => (
                        <>
                          <DatePicker
                            {...field}
                            label={t('licenses.newDate')}
                            value={field.value}
                            disablePast={true}
                            onChange={(date) => field.onChange(formatDateYMD(date))}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </>
                      )}
                    />
                  </LocalizationProvider>
                </Box>

                {license.license_type_id === '2' ? (
                  <Box sx={{ m: '24px' }}>
                    <Typography children={t('licenses.extraNumberOfCalls')} />
                    <TextField
                      {...register('callsforperiod', { value: license?.callsforperiod })}
                      type="number"
                      variant="standard"
                    />
                  </Box>
                ) : null}
                <Button
                  children={t('licenses.save')}
                  variant="contained"
                  color="success"
                  startIcon={<LibraryAddIcon />}
                  sx={{ m: '24px' }}
                  type="submit"
                  disabled={currentData === watch('expires')}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Layout>
  );
});

export default NewLicense;
