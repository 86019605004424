import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function SyntaxButton() {
  const { t } = useTranslation();
  const location = useLocation();

  const activeRoute = location.pathname.includes('syntaxcompiler');
  const isActive = (path: string) => location.pathname.includes(path);

  const [syntax, setSyntax] = useState(null);
  const open = Boolean(syntax);

  const handleClick = (event: any) => setSyntax(event.currentTarget);
  const handleClose = () => setSyntax(null);

  return (
    <>
      <Button
        children={t('menu.syntax')}
        variant="text"
        component="div"
        sx={{
          flexGrow: 1,
          textTransform: 'none',
          color: activeRoute ? 'black' : 'white',
          display: { xs: 'none', md: 'flex' },
        }}
        onClick={handleClick}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        endIcon={<KeyboardArrowDownIcon />}
      />

      <Menu
        anchorEl={syntax}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <NavLink
          to="/syntaxcompiler/analysis"
          style={{ textDecoration: 'none', color: isActive('/syntaxcompiler/analysis') ? '#1976d2' : 'black' }}
        >
          <MenuItem children={t('menu.analysis')} onClick={handleClose} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
        <NavLink
          to="/syntaxcompiler/algorithms"
          style={{ textDecoration: 'none', color: isActive('algorithms') ? '#1976d2' : 'black' }}
        >
          <MenuItem children={t('menu.calculationAlgorithm')} onClick={handleClose} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
        <NavLink
          to="/syntaxcompiler/questions"
          style={{ textDecoration: 'none', color: isActive('questions') ? '#1976d2' : 'black' }}
        >
          <MenuItem children={t('menu.questions')} onClick={handleClose} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
        <NavLink
          to="/syntaxcompiler/modules"
          style={{ textDecoration: 'none', color: isActive('modules') ? '#1976d2' : 'black' }}
        >
          <MenuItem children={t('menu.module')} onClick={handleClose} sx={{ fontSize: '0.875rem' }} />
        </NavLink>
      </Menu>
    </>
  );
}
