import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getVersionsAlgorithm } from '../../../../../../api/syntax';
import { formatDate } from '../../../../../../helpers';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { IAlgorithms } from '../..';

interface IVersion {
  id: number;
  algoritm_id: number;
  syntax: string;
  version: number;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  created_by: number;
}

const BtnVersion = ({ algo }: { algo: IAlgorithms }) => {
  const { t } = useTranslation();

  const [openVersion, setOpenVersion] = useState<boolean>(false);
  const [versionSelect, setVersionSelect] = useState<IVersion | any>('');
  const [data, setData] = useState<IVersion[]>([]);

  useEffect(() => {
    if (algo.id) {
      getVersionsAlgorithm(algo.id).then((res) => setData(res.data.data));
    }
  }, [openVersion]);

  const handleChangeVersionSelect = (event: any) => setVersionSelect(event.target.value);

  return (
    <>
      <Button
        children={t('syntaxcompiler.versions')}
        variant="contained"
        startIcon={<AccountTreeIcon />}
        onClick={() => setOpenVersion(true)}
      />

      <Dialog fullWidth open={openVersion} onClose={() => setOpenVersion(false)}>
        <DialogTitle children={t('syntaxcompiler.earlierVersionsOfAlgorithm')} />
        <DialogContent>
          <FormControl variant="standard" sx={{ width: ' 100%' }}>
            <InputLabel children={t('syntaxcompiler.selectVersion')} />
            <Select
              label={t('syntaxcompiler.selectVersion')}
              value={versionSelect}
              onChange={handleChangeVersionSelect}
            >
              <MenuItem value="">None</MenuItem>
              {data.map((el: any) => (
                <MenuItem key={el.id} value={el}>
                  {`#${el.version} ${formatDate(el.created.date)}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {!versionSelect ? (
            ''
          ) : (
            <Typography children={`${versionSelect.syntax}`} sx={{ mt: 2, background: '#e6f7ff', p: 1 }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            variant="contained"
            color="error"
            onClick={() => {
              setOpenVersion(false);
              setVersionSelect('');
              setData([]);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BtnVersion;
