import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Divider } from '@mui/material';

interface IStatistic {
  api_calls: number;
  api_calls_since_sync: number;
  missing_articles: number;
  last_sync: string;
  next_sync: string;
  no_articles: number;
}

const DashboardStatisticsCard = ({ statistic }: { statistic: IStatistic }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
        <Card sx={{ width: '30%', mt: '20px' }}>
          <CardContent>
            <Typography children={t('dashboard.hStatistics')} variant="h5" />
            <Divider sx={{ m: '10px 0px' }} />
            <Typography
              children={`${t('dashboard.apiCallsTotal')} ${statistic.api_calls}`}
              variant="body1"
              sx={{ m: '16px 0px' }}
            />
            <Typography
              children={`${t('dashboard.apiCallsAfterLastSync')} ${statistic.api_calls_since_sync}`}
              variant="body1"
            />
          </CardContent>
        </Card>

        <Card sx={{ width: '30%', mt: '20px' }}>
          <CardContent>
            <Typography children={t('dashboard.hProductRegister')} variant="h5" />
            <Divider sx={{ m: '10px 0px' }} />
            <Typography variant="inherit" sx={{ m: '16px 0px' }}>
              {`${t('dashboard.numItemsOutOfSync')} ${statistic.missing_articles} `}
              <Link to="/product_list/missing_drugs" style={{ textDecoration: 'none', color: '#ffc107' }}>
                {t('dashboard.showList')}
              </Link>
            </Typography>
            <Typography
              children={`${t('dashboard.lastSync')} ${statistic.last_sync} `}
              variant="inherit"
              sx={{ m: '16px 0px' }}
            />

            <Typography
              children={`${t('dashboard.nextSync')} ${statistic.next_sync} `}
              variant="inherit"
              sx={{ m: '16px 0px' }}
            />

            <Typography
              children={`${t('dashboard.numItemsInSystem')} ${statistic.no_articles} `}
              variant="inherit"
              sx={{ m: '16px 0px' }}
            />
          </CardContent>
        </Card>

        <Card sx={{ width: '30%', mt: '20px' }}>
          <CardContent>
            <Typography children={t('dashboard.hReports')} variant="h5" component="div" />
            <Divider sx={{ m: '10px 0px' }} />
            <Typography variant="inherit" sx={{ m: '16px 0px' }}>
              ...
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default DashboardStatisticsCard;
