import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/system';
import RecentEvent from '../../../../components/RecentEvent';
import RDTable from './componets/DRTable';
import Layout from '../../../../layouts/Header';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getDiagnosesRecords, getDiagnosesSearch, postDiagnosesUpload } from '../../../../api/diagnoses';
import Spinner from '../../../../components/Spinner';
import { hintSearchField, showError } from '../../../../helpers';

interface IDiagnoese {
  id: number;
  icd_10_code: string;
  text: string;
  row: number;
  file_id: number;
}

interface IPagination {
  page: number;
  pages: number;
  limit: number;
  countTotal: number;
  countFiltered: number;
}

const Diagregister = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const [diagnoses, setDiagnoses] = useState<IDiagnoese[]>([]);

  const [pagination, setPagination] = useState<IPagination>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const [records, setRecords] = useState([]);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [file, setFile] = useState();
  const [searchableFields, setSearchableFields] = useState<Array<string>>([]);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const onFileChange = (event: any) => setFile(event.target.files[0]);

  useEffect(() => {
    getDiagnosesRecords().then((res) => setRecords(res.data.data));
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        getDiagnosesSearch(search, 0, rowsPerPage).then((res) => {
          setDiagnoses(res.data.data);
          setSearchableFields(res.data.searchableFields);
          const pagination = {
            ...res.data.pagination,
            countTotal: res.data.pagination.countFiltered,
          };
          setPagination(pagination);
          setPage(0);
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const Input = styled('input')({
    display: 'none',
  });
  return (
    <Layout>
      <Box sx={{ m: '24px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <Box>
          <Typography children={t('administration.diagRegister')} variant="h5" />
          <Tooltip title={hintSearchField(searchableFields)}>
            <TextField
              label={t('administration.search')}
              type="search"
              size="small"
              sx={{ m: '24px 0' }}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            children={t('administration.uploadNewDiagRegFile')}
            variant="contained"
            component="span"
            startIcon={<FileUploadIcon />}
            onClick={() => setOpenUpload(true)}
          />
          <Dialog fullWidth open={openUpload} onClose={() => setOpenUpload(false)}>
            <DialogTitle children={t('administration.uplDbFile')} />
            <DialogContent dividers>
              <label htmlFor="contained-button-file">
                <Input accept=".txt" id="contained-button-file" multiple type="file" onChange={onFileChange} />
                <Button
                  children={t('administration.uplDbFile')}
                  variant="contained"
                  component="span"
                  startIcon={<FileUploadIcon />}
                />
              </label>
            </DialogContent>
            <DialogActions>
              <Button
                children={t('settings.cancel')}
                variant="contained"
                color="error"
                onClick={() => setOpenUpload(false)}
              />
              <Button
                children={t('settings.upload')}
                variant="contained"
                color="success"
                disabled={!file}
                onClick={() => {
                  postDiagnosesUpload(file)
                    .then(() => {
                      setOpen(true);
                    })
                    .catch((error) => {
                      setOpenError(true);
                      setErrorMessage(error.response.data.messages);
                    });
                  setOpenUpload(false);
                }}
              />
            </DialogActions>
          </Dialog>
          <RecentEvent records={records} />{' '}
        </Box>
      </Box>

      {!pagination ? (
        <Spinner />
      ) : (
        <Box sx={{ m: '0 24px' }}>
          <RDTable
            data={{
              diagnoses,
              setDiagnoses,
              pagination,
              setPagination,
              page,
              setPage,
              rowsPerPage,
              setRowsPerPage,
              search,
            }}
          />
        </Box>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('settings.fileUploadSuccess')}
        </Alert>
      </Snackbar>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => {
            setOpenError(false);
          }}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default Diagregister;
