import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getRecordsAlgorithm,
  getRecordsAlgorithmById,
  getSyntaxAlgorithms,
  getSyntaxAlgorithmsById,
} from '../../../../api/syntax';
import RecentEvent from '../../../../components/RecentEvent';

import Layout from '../../../../layouts/Header';
import Content from './components/Content';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../../components/Spinner';

export interface IAlgorithms {
  id: number;
  status: number;
  syntax: string;
  function: string;
  description: string;
  name: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  created_by: number;
  updated: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  updated_by: number;
  php_code: string;
  function_name: string;
  is_usable: number;
  module: string;
}

interface IRecords {
  id: number;
  user: number;
  api: number;
  rel_type: number;
  rel_id: number;
  type: string;
  data: string;
  location: string;
  user_agent: string;
  timestamp: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  description: string;
  test: string;
  locale: string;
}

const Algorithm = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [algorithms, setAlgorithms] = useState<IAlgorithms[]>([]);
  const [algo, setAlgo] = useState<IAlgorithms | any>('');
  const [newAlgo, setNewAlgo] = useState<boolean>(false);
  const [records, setRecords] = useState<IRecords[]>([]);

  useEffect(() => {
    if (id) {
      getRecordsAlgorithmById(algo?.id).then((res: any) => setRecords(res.data.data));
    } else {
      getRecordsAlgorithm().then((res: any) => setRecords(res.data.data));
    }

    return () => {
      setAlgo('');
      setNewAlgo(false);
    };
  }, [id]);

  const handleChangeAlgo = (event: any) => {
    setAlgo(event.target.value);
    navigate(`/syntaxcompiler/algorithms/${event.target.value.id || ''}`);
  };

  useEffect(() => {
    getSyntaxAlgorithms().then((res: any) => {
      setAlgorithms(res.data.data);
      if (id) {
        getSyntaxAlgorithmsById(Number(id)).then((result) => {
          const currentAlgoById = res.data.data.find((el: any) => el.id === result.data.algorithm.id);
          if (currentAlgoById) {
            setAlgo(currentAlgoById);
          }
        });
      }
    });
    return () => {
      setAlgo('');
      setNewAlgo(false);
    };
  }, [id]);

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: ' 24px', flexWrap: 'wrap' }}>
        <Box sx={{ width: '80%' }}>
          <Typography children={t('syntaxcompiler.calculationAlgorithm')} variant="h5" />
          <Box sx={{ mt: '24px' }}>
            <Box>
              <Box>
                {algorithms.length < 1 ? (
                  <Spinner />
                ) : (
                  <FormControl variant="standard" sx={{ width: '100%' }}>
                    <InputLabel children={t('syntaxcompiler.selectAlgOrCreateNew')} />
                    <Tooltip title={algo.name || t('syntaxcompiler.selectAlgOrCreateNew')} leaveDelay={10}>
                      <Select value={algo} onChange={handleChangeAlgo}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {algorithms.map((el: any) => (
                          <MenuItem key={el.id} value={el}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Tooltip>
                  </FormControl>
                )}
              </Box>
              {algo || newAlgo ? (
                <Content algo={algo} setAlgo={setAlgo} setAlgorithms={setAlgorithms} setNewAlgo={setNewAlgo} />
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button
            variant="contained"
            children={t('syntaxcompiler.createNew')}
            onClick={() => {
              setAlgo('');
              setNewAlgo(true);
              navigate('/syntaxcompiler/algorithms');
            }}
          />
          <RecentEvent records={records} />
        </Box>
      </Box>
    </Layout>
  );
};

export default Algorithm;
