import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Snackbar,
  Alert,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { sendResetLink } from '../../../../api/access';
import { showError } from '../../../../helpers';

const ResetPassBtn = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState<boolean>(false);
  const [openMessage, setOpenMessage] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = (data: any) =>
    sendResetLink(data)
      .then(() => setOpenMessage(true))
      .catch((error) => {
        setErrorMessage(error.response.data.messages || error.message);
        setOpenError(true);
      });

  return (
    <div>
      <Button variant="text" startIcon={<LockResetIcon />} onClick={() => setOpen(true)} sx={{ mt: '10px' }}>
        {t('access.forgot')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('access.resetPassword')}</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>{t('access.enterEmailToGetRestoreLink')}</DialogContentText>
            <TextField
              {...register('username', { required: true })}
              autoFocus
              margin="dense"
              id="name"
              label={t('access.emailAddress')}
              type="email"
              fullWidth
              variant="standard"
            />
            {(errors.firstName?.type || errors.password?.type) && (
              <Typography variant="caption" color="error" children={t('access.fillEmail')} />
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={() => setOpen(false)}>
              {t('access.cancel')}
            </Button>
            <Button type="submit" variant="contained" color="success">
              {t('access.sendFeedbackLink')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openMessage}
        autoHideDuration={2000}
        onClose={() => setOpenMessage(false)}
      >
        <Alert variant="filled" onClose={() => setOpenMessage(false)} severity="success" sx={{ width: '100%' }}>
          {t('access.linkSent')}
        </Alert>
      </Snackbar>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={2000}
          onClose={() => setOpenError(false)}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </div>
  );
};

export default ResetPassBtn;
