import Alert from '@mui/material/Alert';
import { t } from 'i18next';
import { format } from 'date-fns';

export const getStatus = (status) => {
  const dataStatus = [
    {
      statusValue: 1,
      label: <Alert children={t('customers.customerActive')} severity="success" />,
    },
    {
      statusValue: 2,
      label: <Alert children={t('customers.customerInactive')} severity="warning" />,
    },
    {
      statusValue: 0,
      label: <Alert children={t('customers.customerRemoved')} severity="error" />,
    },
  ];

  if (status === undefined) {
    return;
  }

  return dataStatus.find((el) => status === el.statusValue).label;
};

export const getStatusAnalysis = (status) => (status === 1 ? t('syntaxcompiler.published') : t('syntaxcompiler.draft'));
export const formatDate = (date) => format(new Date(date), "yyyy-MM-dd' 'HH:mm:ss") || null;
export const formatDateYMD = (date) => format(new Date(date), 'yyyy-MM-dd');
export const showError = (errorMessage) => {
  if (typeof errorMessage !== 'string') {
    for (const error in errorMessage) {
      return <Alert variant="filled" sx={{ width: 'fit-content' }} severity="error">{`${errorMessage[error]}`}</Alert>;
    }
  } else {
    return <Alert variant="filled" sx={{ width: 'fit-content' }} severity="error">{`${errorMessage}`}</Alert>;
  }
};

export const hintSearchField = (data) => {
  const fields = [
    {
      searchableFields: 'firstname',
      label: t('customers.contactPersonFirsName'),
    },
    {
      searchableFields: 'lastname',
      label: t('customers.contactPersonLastName'),
    },
    {
      searchableFields: 'company',
      label: t('customers.companyName'),
    },
    {
      searchableFields: 'number',
      label: t('customers.thCustomerNumber'),
    },
    {
      searchableFields: 'email',
      label: t('customers.contactPersonEmail'),
    },
    {
      searchableFields: 'icd_10_code',
      label: 'ICD-codes',
    },
    {
      searchableFields: 'text',
      label: 'Text',
    },
    {
      searchableFields: 'public_key',
      label: t('apiIntegration.thPublicKey'),
    },
    {
      searchableFields: 'name',
      label: t('productlist.preparationName'),
    },
    {
      searchableFields: 'atc_code',
      label: 'ATC code',
    },
    {
      searchableFields: 'internalID',
      label: 'InternalID',
    },
    {
      searchableFields: 'nplid',
      label: 'NPL id',
    },
    {
      searchableFields: 'nplpackid',
      label: 'NPL pack id',
    },
    {
      searchableFields: 'note',
      label: t('productlist.historyPrep'),
    },
    {
      searchableFields: 'integration',
      label: t('productlist.apiIntegration'),
    },
    {
      searchableFields: 'created',
      label: t('productlist.dateOfCall'),
    },
    {
      searchableFields: 'username',
      label: 'E-mail',
    },
    {
      searchableFields: 'id',
      label: 'Id',
    },
    {
      searchableFields: 'text_sv',
      label: 'Text',
    },
    {
      searchableFields: 'side_effect_frequency',
      label: t('administration.thFrequency'),
    },
    {
      searchableFields: 'phase_20_text',
      label: t('administration.thPh20Text'),
    },
    {
      searchableFields: 'fass_text',
      label: t('administration.thFass'),
    },
  ];

  return fields.filter((field) => data.includes(field.searchableFields)).map((el) => `${el.label} * `);
};

export const validationField = (str) => {
  const specialChars = `@$%^&*()_+-={};':",<>?~`;
  return specialChars.split('').some((char) => str.includes(char));
};

export const queryRule = (moduleItem, elHidden) => (moduleItem === 'GROUP' ? 0 : elHidden ? 1 : 0);
