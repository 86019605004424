import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { putTranslationsQuestion } from '../../../../../../../../api/syntax';

import { Box, Button, TextField, FormControl, Typography, Stack, Switch } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IData } from '../..';
import Notification from '../../../../../../../../components/Notification';
import {searchDataApiIntegration} from "../../../../../../../../api/apiIntegration";

const LangForm = ({ data }: { data: IData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = data.question;
  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm({ mode: 'onChange' });

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });



  const onSubmit = (data: any) => {
    const lang_id = Object.keys(data).filter((el) => el !== 'default_answer');
    const lang_text = Object.values(data).filter((el) => el !== true && el !== false);
    const { default_answer } = data;

    putTranslationsQuestion(Number(id), lang_id, lang_text, default_answer ? 1 : -1)
      .then(() => {
        setNotification({
          ...notification,
          loading: true,
          message: t('syntaxcompiler.questionSaved'),
          status: 'success',
        });
        setTimeout(() => {
          navigate('/syntaxcompiler/questions');
        }, 2000);
      })
      .catch((error) => {
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        });
      });
  };

  return (
    <>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ m: '16px 24px 0 0', width: '100%' }}>
        <FormControl fullWidth>
          {data?.translations?.map((el: any, index: number) => (
            <Box key={index} sx={{ display: 'contents' }}>
              <TextField
                multiline
                {...register(`${el.lang_code_id}`)}
                defaultValue={el.text}
                variant="standard"
                label={el.iso6391}
              />
            </Box>
          ))}
        </FormControl>

        <Typography variant="subtitle1" sx={{ mt: '24px' }}>
          {t('syntaxcompiler.defaultAnswer')}
        </Typography>
        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Typography>{t('syntaxcompiler.no')}</Typography>
          <Switch {...register('default_answer')} defaultChecked={data.question.default_answer == 1 ? true : false} />
          <Typography>{t('syntaxcompiler.yes')}</Typography>
        </Stack>

        <Button
          type="submit"
          children={t('syntaxcompiler.save')}
          variant="contained"
          color="success"
          sx={{ mt: 1 }}
          disabled={!isDirty || !isValid}
        />
      </Box>
      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </>
  );
};

export default LangForm;
