import { Alert, Box, Button, Snackbar, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setNewPassword, verifyResetLink } from '../../../../api/access';
import { showError } from '../../../../helpers';

const ResetForm = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();
  const pPsM9kGuVD = searchParams.get('pPsM9kGuVD');
  const dNdvTcCCqR = searchParams.get('dNdvTcCCqR');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [open, setOpen] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const onSubmit = (data: any) =>
    verifyResetLink(pPsM9kGuVD, dNdvTcCCqR)
      .then(() =>
        setNewPassword(pPsM9kGuVD, dNdvTcCCqR, data)
          .then(() => {
            setOpen(true);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.messages || error.message);
            setOpenError(true);
          })
      )
      .catch((error) => {
        setErrorMessage(error.response.data.messages || error.message);
        setOpenError(true);
      });

  return (
    <Box
      sx={{ display: 'flex', height: '100vh', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
    >
      <>
        <Typography children={t('administration.changePassword')} variant="h4" sx={{ mt: 3 }} />
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ '& .MuiTextField-root': { width: '100%' } }}>
          <TextField
            {...register('password', {
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                message: 'no valid',
              },
              setValueAs: (v) => v.trim(),
            })}
            variant="standard"
            sx={{ marginTop: 1 }}
            label={t('administration.enterNewPassword')}
            type="password"
          />
          {errors?.password?.type && (
            <Typography
              variant="caption"
              color="error"
              children={t('administration.passwordMustBe')}
              sx={{ display: 'block', ml: '10px' }}
            />
          )}

          <TextField
            {...register('password_repeat', {
              required: true,
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                message: 'no valid',
              },
              setValueAs: (v) => v.trim(),
            })}
            variant="standard"
            sx={{ marginTop: 2 }}
            type="password"
            label={t('administration.repeatNewPassword')}
          />
          {errors?.password_repeat?.type && (
            <Typography
              variant="caption"
              color="error"
              children={t('administration.passwordMustBe')}
              sx={{ display: 'block', ml: '10px' }}
            />
          )}

          <Snackbar
            open={open}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            onClose={() => {
              setOpen(false), navigation('/access/login');
            }}
          >
            <Alert
              variant="filled"
              onClose={() => {
                setOpen(false);
                navigation('/access/login');
              }}
              severity="success"
              sx={{ width: '100%' }}
            >
              {t('administration.passwordChanged')}
            </Alert>
          </Snackbar>

          {errorMessage ? (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={openError}
              autoHideDuration={2000}
              onClose={() => setOpenError(false)}
            >
              {showError(errorMessage)}
            </Snackbar>
          ) : (
            ''
          )}
          <Button type="submit" children={t('administration.save')} variant="contained" sx={{ mt: 3 }} />
        </Box>
      </>
    </Box>
  );
};

export default ResetForm;
