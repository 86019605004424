import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAnalysisRow, getSyntAnalysisById } from '../../../../../../../../api/syntax';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { IStructure } from '../..';

const RemoveBtn = ({ el, setStructure }: { el: IStructure; setStructure: any }) => {
  const { t } = useTranslation();

  const [openRemove, setOpenRemove] = useState<boolean>(false);

  return (
    <>
      <IconButton color="error" size="small" onClick={() => setOpenRemove(true)}>
        <ClearIcon />
      </IconButton>
      <Dialog open={openRemove} onClose={() => setOpenRemove(false)}>
        <DialogTitle children={t('syntaxcompiler.remove')} />
        <DialogContent>
          <DialogContentText children={t('syntaxcompiler.deleteRow')} sx={{ fontWeight: 600 }} />
          <DialogContentText children={t('syntaxcompiler.youWillAlsoDeleteLines')} />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            variant="contained"
            color="error"
            onClick={() => setOpenRemove(false)}
          />
          <Button
            children={t('syntaxcompiler.deleteSyntaxRow')}
            variant="contained"
            color="success"
            onClick={() => {
              deleteAnalysisRow(el.analysis, el.id).then(() => {
                getSyntAnalysisById(el.analysis).then((res: any) => setStructure(res.data.structure));
              });
              setOpenRemove(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveBtn;
