import { Box, Button, List, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../../../layouts/Header';
import AddIcon from '@mui/icons-material/Add';
import { getModules } from '../../../../api/modules';
import { useNavigate } from 'react-router-dom';
import ModulesListItem from './components/ModulesListItem/index';
import Spinner from '../../../../components/Spinner';
import Notification from '../../../../components/Notification';

export interface IModulesList {
  id: number;
  name: string;
  label: string;
  label_color: string;
  legend: string;
  static: number;
}
export interface INotification {
  loading: boolean;
  message: string;
  status: string;
}

const Module = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [moduleList, setModuleList] = useState<IModulesList[]>([]);
  const [notification, setNotification] = useState<INotification>({
    loading: false,
    message: '',
    status: 'error',
  });

  useEffect(() => {
    getModules()
      .then((res) => {
        setModuleList(res.data.data);
        setNotification({
          ...notification,
          loading: true,
        });
      })
      .catch((error) => {
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        });
      });
  }, []);

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: ' 24px', flexWrap: 'wrap' }}>
        <Typography children={t('syntaxcompiler.module')} variant="h5" />
        <Button
          children={t('syntaxcompiler.addNewModule')}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigation('/syntaxcompiler/modules/add')}
        />
      </Box>
      {moduleList.length === 0 ? (
        <Spinner />
      ) : (
        <List sx={{ overflowY: 'auto', width: '40%', ml: 3, height: '600px' }}>
          {moduleList.map((el: IModulesList) => (
            <ModulesListItem key={el.id} el={el} setModuleList={setModuleList} />
          ))}
        </List>
      )}
      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </Layout>
  );
};

export default Module;
