import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import LangButton from '../../layouts/Header/components/LangButton';
import ResetPassBtn from './componets/ResetPassBtn';
import imgLogo from '../../assets/images/logo.png';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../api/axios';
import Notification from '../../components/Notification';

interface DataForm {
  username?: string;
  password?: string;
  code?: string;
}

const Access: FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { register, handleSubmit } = useForm({ shouldFocusError: true, shouldUseNativeValidation: true });

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const onSubmit: SubmitHandler<DataForm> = async (data) => {
    try {
      await apiClient.post('access/login', data);
      navigation('/');
    } catch (error: any) {
      return setNotification({
        ...notification,
        loading: true,
        message: error.response?.data?.messages || error.message,
        status: 'error',
      });
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <Paper elevation={12} sx={{ maxWidth: '500px', height: 'fit-content', p: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <img src={imgLogo} alt="img logo" height={24} />

            <LangButton />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register('username', { required: true })}
              size="small"
              fullWidth
              placeholder={t('access.username')}
              type="email"
            />
            <TextField
              {...register('password', { required: true })}
              size="small"
              fullWidth
              type="password"
              placeholder={t('access.password')}
              sx={{ m: '10px 0 ' }}
            />

            <TextField
              {...register('code')}
              size="small"
              fullWidth
              placeholder={t('access.twoFA')}
              sx={{ m: '10px 0 ' }}
            />

            <Button type="submit" children={t('access.signin')} fullWidth variant="contained" />
          </form>
          <ResetPassBtn />
          {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
          <Typography>{process.env.REACT_APP_TAG}</Typography>
          <Typography>{process.env.REACT_APP_GIT_COMMIT}</Typography>
        </Paper>
      </Box>
    </>
  );
};

export default Access;
