import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { getAlgoTranslation, getSyntaxAlgorithms, postAlgorithm, putAlgorithm } from '../../../../../../api/syntax';

import { Box, Typography, TextField, Button, Alert, Snackbar } from '@mui/material';
import BtnDelete from '../BtnDelete';
import BtnLanguage from '../BtnLanguage';
import BtnLoadQuestion from '../BtnLoadQuestion';
import BtnNewQuestion from '../BtnNewQuestion';
import BtnVersion from '../BtnVersion';
import Spinner from '../../../../../../components/Spinner';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { showError } from '../../../../../../helpers';
import { IAlgorithms } from '../..';

export interface ITranslation {
  lang_code_id: number;
  iso6391: string;
  text?: string | any;
}

const Content = ({
  algo,
  setAlgorithms,
  setAlgo,
  setNewAlgo,
}: {
  algo: IAlgorithms | any;
  setAlgorithms: any;
  setAlgo: any;
  setNewAlgo: any;
}) => {
  const { t } = useTranslation();
  const { handleSubmit } = useForm();

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [translations, setTranslation] = useState<ITranslation[]>([]);
  const [xml, setXml] = useState<string>('');
  const [textSv, setTextSv] = useState<string>('');
  const [algorithmName, setAlgorithmName] = useState<string>('');
  const [algorithmDescription, setAlgorithmDescription] = useState<string>('');

  useEffect(() => {
    setAlgorithmName(algo.name);
    setAlgorithmDescription(algo.description);
    setTextSv(translations[0]?.text);
  }, [translations]);

  useEffect(() => {
    if (algo) {
      setXml(algo.syntax);
      getAlgoTranslation(algo.id).then((res: any) => setTranslation(res.data.data));
    } else {
      setTranslation([]);
      setAlgorithmName('');
      setAlgorithmDescription('');
      setTextSv('');
      setXml('<return> </return>');
    }
  }, [algo]);

  const handleChangeName = (e: any) => setAlgorithmName(e.target.value);
  const handleChangeDescription = (e: any) => setAlgorithmDescription(e.target.value);
  const handleChangeResponseText = (e: any) => setTextSv(e.target.value);
  const handleChangeXml = (e: any) => setXml(e.target.value);

  const onSubmit = () => {
    if (algo === '') {
      postAlgorithm(algorithmName, algorithmDescription, xml, textSv)
        .then((result: any) => {
          getSyntaxAlgorithms().then((res: any) => {
            setAlgorithms(res.data.data);
            const currentEl = res.data.data.find((el: any) => el?.id === result.data.algorithm?.id);
            if (currentEl) {
              setAlgo(currentEl);
            }

            setOpen(true);
          });
        })
        .catch((e: any) => {
          setErrorMessage(e.response.data.messages);
          setOpenError(true);
        });
    } else {
      putAlgorithm(algo.id, algorithmName, algorithmDescription, xml, textSv)
        .then((result: any) => {
          getSyntaxAlgorithms().then((res: any) => {
            setAlgorithms(res.data.data);
            const currentEl = res.data.data.find((el: any) => el?.id === result.data.algorithm?.id);
            if (currentEl) {
              setAlgo(currentEl);
            }

            setOpen(true);
          });
        })
        .catch((e: any) => {
          setErrorMessage(e.response.data.messages);
          setOpenError(true);
        });
    }
  };

  const handleCopyAlgorithm = () => {
    postAlgorithm(algorithmName, algorithmDescription, xml, textSv)
      .then((result: any) => {
        getSyntaxAlgorithms().then((res: any) => {
          setAlgorithms(res.data.data);
          const currentEl = res.data.data.find((el: any) => el?.id === result.data.algorithm?.id);
          if (currentEl) {
            setAlgo(currentEl);
          }

          setOpen(true);
        });
      })
      .catch((e: any) => {
        setErrorMessage(e.response.data.messages);
        setOpenError(true);
      });
  };

  return (
    <>
      {!translations ? (
        '' // <Spinner />
      ) : (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ '& .MuiTextField-root': { mt: 1, width: 'initial', display: 'flex', flexDirection: 'column' } }}
        >
          <TextField
            multiline
            label={t('syntaxcompiler.name')}
            variant="standard"
            value={algorithmName || ''}
            onChange={handleChangeName}
            name="name"
            required
          />
          <TextField
            multiline
            label={t('syntaxcompiler.description')}
            variant="standard"
            onChange={handleChangeDescription}
            value={algorithmDescription || ''}
            name="description"
          />
          <Box
            sx={{
              '& .MuiTextField-root': { width: '90%' },
              'display': 'flex',
              'justifyContent': 'space-between',
              'alignItems': 'end',
            }}
          >
            <TextField
              multiline
              label={t('syntaxcompiler.returnResponse')}
              variant="standard"
              onChange={handleChangeResponseText}
              value={textSv || ''}
              name="response_text_sv"
            />
            <BtnLanguage algo={algo} translations={translations} setTranslation={setTranslation} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
            <Typography children={t('syntaxcompiler.algXmlLogic')} variant="h6" />
            <Box>
              <BtnNewQuestion xml={xml} setXml={setXml} />
              <BtnLoadQuestion xml={xml} setXml={setXml} />
            </Box>
          </Box>
          <TextField multiline rows={15} sx={{ width: '500px' }} value={xml} onChange={handleChangeXml} name="syntax" />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Button
                children={t('syntaxcompiler.cancel')}
                variant="contained"
                color="error"
                onClick={() => {
                  setAlgo(''), setNewAlgo(false);
                }}
                sx={{ mr: '10px' }}
              />
              <BtnDelete algo={algo} setAlgo={setAlgo} setAlgorithms={setAlgorithms} setNewAlgo={setNewAlgo} />
              <BtnVersion algo={algo} />
            </Box>

            {errorMessage ? (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openError}
                autoHideDuration={2000}
                onClose={() => setOpenError(false)}
              >
                {showError(errorMessage)}
              </Snackbar>
            ) : (
              ''
            )}

            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={open}
              autoHideDuration={2000}
              onClose={() => setOpen(false)}
            >
              <Alert variant="filled" onClose={() => setOpen(false)} severity="success" sx={{ width: '100%' }}>
                {t('syntaxcompiler.algorithmSaved')}
              </Alert>
            </Snackbar>
            <Box>
              <Button
                children={t('syntaxcompiler.copy')}
                variant="contained"
                color="error"
                sx={{ mr: '10px' }}
                startIcon={<ContentCopyIcon />}
                onClick={handleCopyAlgorithm}
              />
              <Button children={t('syntaxcompiler.save')} type="submit" variant="contained" color="success" />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Content;
