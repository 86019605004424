import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import { getDiagnosesSearch } from '../../../../../../api/diagnoses';

const RDTable = ({ data }: any) => {
  const { diagnoses, setDiagnoses, pagination, page, setPage, rowsPerPage, setRowsPerPage, search } = data;

  const handleChangePage = (event: any, newPage: number) => {
    getDiagnosesSearch(search, newPage + 1, rowsPerPage).then((res) => setDiagnoses(res.data.data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    getDiagnosesSearch(search, 1, event.target.value).then((res) => setDiagnoses(res.data.data));
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: 1, label: 'icd_10_code' },
    { id: 2, label: 'text' },
  ];

  return (
    <>
      <TableContainer sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }} children={headCell.label} />
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {diagnoses.map((row: any) => (
              <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{row.icd_10_code}</TableCell>
                <TableCell align="left">{row.text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', margin: '0px 24px' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={pagination.countTotal}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </TableContainer>
    </>
  );
};

export default RDTable;
