import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  FormLabel,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { getAlgoTranslation, postAlgoTranslation } from '../../../../../../api/syntax';
import { ITranslation } from '../Content';
import { IAlgorithms } from '../..';

const BtnLanguage = ({
  algo,
  translations,
  setTranslation,
}: {
  algo: IAlgorithms;
  translations: ITranslation[];
  setTranslation: any;
}) => {
  const { t } = useTranslation();
  const { register, handleSubmit } = useForm();

  const [open, setOpen] = useState<boolean>(false);

  const onSubmit = (data: any) =>
    postAlgoTranslation(algo.id, Object.keys(data), Object.values(data)).then(() =>
      getAlgoTranslation(algo.id).then((res: any) => {
        setTranslation(res.data.data), setOpen(false);
      })
    );

  return (
    <>
      <Box>
        <Button disabled={!algo} variant="contained" onClick={() => setOpen(true)}>
          <LanguageIcon />
        </Button>
        {!algo ? (
          ''
        ) : (
          <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle children={t('syntaxcompiler.textLineTranslations')} />
              <DialogContent>
                <FormControl fullWidth>
                  {translations.map((el: any, index: number) => (
                    <Box key={index} sx={{ display: 'contents' }}>
                      <FormLabel children={el.iso6391 || ''} sx={{ mt: '20px' }} />
                      <TextField
                        {...register(`${el.lang_code_id}`)}
                        multiline
                        defaultValue={el.text || ''}
                        variant="standard"
                      />
                    </Box>
                  ))}
                </FormControl>
              </DialogContent>
              <DialogActions>
                <Button
                  children={t('syntaxcompiler.close')}
                  variant="contained"
                  color="error"
                  onClick={() => setOpen(false)}
                />

                <Button type="submit" children={t('syntaxcompiler.save')} variant="contained" color="success" />
              </DialogActions>
            </Box>
          </Dialog>
        )}
      </Box>
    </>
  );
};

export default BtnLanguage;
