import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { deleteAlgorithm, getSyntaxAlgorithms } from '../../../../../../api/syntax';
import { IAlgorithms } from '../..';

const BtnDelete = ({
  algo,
  setAlgo,
  setAlgorithms,
  setNewAlgo,
}: {
  algo: IAlgorithms;
  setAlgo: any;
  setAlgorithms: any;
  setNewAlgo: any;
}) => {
  const { t } = useTranslation();

  const [openRemove, setOpenRemove] = useState<boolean>(false);

  return (
    <>
      <Button
        children={t('syntaxcompiler.deleteAlg')}
        variant="outlined"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={() => setOpenRemove(true)}
        sx={{ width: 'max-content', mr: '10px' }}
      />

      <Dialog fullWidth open={openRemove} onClose={() => setOpenRemove(false)}>
        <DialogTitle children={t('syntaxcompiler.doYouWantToDeleteAlg')} />
        <DialogContent dividers>
          <DialogContentText>{algo.name}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            variant="contained"
            color="error"
            onClick={() => setOpenRemove(false)}
          />
          <Button
            children={t('syntaxcompiler.delete')}
            variant="contained"
            color="success"
            onClick={() => {
              deleteAlgorithm(algo.id).then(() => {
                getSyntaxAlgorithms().then((res: any) => setAlgorithms(res.data.data));
              });
              setOpenRemove(false);
              setAlgo('');
              setNewAlgo(false);
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BtnDelete;
