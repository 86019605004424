import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { Table, Box, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Link } from '@mui/material';

interface ILicenses {
  map: any;
  id: number;
  status: number;
  license_type_id: number;
  customer_id: number;
  callsforperiod: number;
  created: number;
  expires: number;
  customer: string;
  warning: string;
}
[];

const LicensesTable = ({ licenses }: { licenses: ILicenses }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ m: '24px' }}>
      <TableContainer sx={{ width: '100%' }}>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell children={'ID'} sx={{ fontWeight: 600 }} align="left" />
              <TableCell children={t('dashboard.thCustomer')} sx={{ fontWeight: 600 }} align="left" />
              <TableCell children={t('dashboard.thProblem')} sx={{ fontWeight: 600 }} align="left" />
              <TableCell children={t('dashboard.thEndDate')} sx={{ fontWeight: 600 }} align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            {licenses.map((row: any) => {
              const { customer_id } = row;
              return (
                <TableRow key={row.id} sx={{ 'td, &:last-child th,  ': { borderRight: 0, borderLeft: 0 } }}>
                  <TableCell align="left">
                    <Link
                      href={`/customers/card/${customer_id}`}
                      sx={{ flexGrow: 1, textDecoration: 'none', color: '#000' }}
                    >
                      {row.license_type_id}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    <Link
                      href={`/customers/card/${customer_id}`}
                      sx={{ flexGrow: 1, textDecoration: 'none', color: '#000' }}
                    >
                      {row.customer}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    <Link
                      children={parse(row.warning)}
                      href={`/customers/card/${customer_id}`}
                      sx={{ flexGrow: 1, textDecoration: 'none', color: '#000' }}
                    />
                  </TableCell>
                  <TableCell align="left">
                    <Link
                      children={row.expires}
                      href={`/customers/card/${customer_id}`}
                      sx={{ flexGrow: 1, textDecoration: 'none', color: '#000' }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default LicensesTable;
