import { apiClient } from '../api/axios';

export const getLogs = (
  page?: number,
  limit?: number,
  sort?: string[],
  status?: string,
  search?: string,
  filter?: any
) => {
  const params = {
    page,
    limit,
    sort: [] as string[],
    status,
    search,
    ...filter,
  };

  if (sort && sort.length) {
    params.sort = sort;
  }

  return apiClient('logs', { params });
};

export const exportLogFile = (id: number) => apiClient(`logs/export/${id}`);
export const logParse = (id: number) => apiClient(`logs/parse/${id}`);

export const filterLogs = (date: any) => {
  const { date_from, date_to } = date;
  const params = { date_from, date_to };
  return apiClient('logs', { params });
};
