import { useSpring, animated } from 'react-spring';

import SvgIcon from '@mui/material/SvgIcon';
import { alpha, styled } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import { Button, Box, Collapse, Typography, Divider } from '@mui/material';
import BtnRowAddAlgo from './components/BtnRowAddAlgo';
import BtnRowAddNewLine from './components/BtnRowAddNewLine';
// web.cjs is required for IE11 support

import BtnLanguage from './components/BtnLanguage';
import RemoveBtn from './components/RemoveBtn';
import BtnNewLine from './components/BtnNewLine';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import BtnEdit from './components/BtnEdit';

export interface IStructure {
  id: number;
  analysis: number;
  function_name: string;
  index: string;
  module: string;
  order: number;
  parent: number;
  php_code: string;
  prefixedOrder: string;
  rel_id: number;
  rel_type: number;
  response_text: string;
  row_type: string;
  status: number;
  syntax: string;
  text: string;
  child?: [];
  hidden: number;
}

function CloseSquare(props: any) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14, padding: '5px 0' }} {...props}>
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props: any) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: 'translate3d(20px,0,0)',
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: any) => <TreeItem {...props} TransitionComponent={TransitionComponent} />)(
  ({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
      '& .close': {
        opacity: 0.3,
      },
    },
    [`& .${treeItemClasses.group}`]: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
  })
);
('');

const renderTree = (nodes: IStructure[], setStructure: any) => {
  return (
    <>
      {nodes.map((el: any) => {
        const label = (textType: any) => {
          const isShow = textType.row_type === 'text_row';

          return (
            <Box
              key={el.id}
              sx={{ display: 'flex', justifyContent: 'space-between', opacity: el.hidden === 0 ? 1 : 0.6 }}
            >
              <Box sx={{ width: '85%' }}>
                {!isShow ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }}>{`${textType.prefixedOrder}`}</Typography>
                    <Typography sx={{ fontWeight: 600, ml: '5px' }}>{textType.module}</Typography>
                    <Link
                      style={{ marginLeft: '10px', color: '#0288d1' }}
                      to={`/syntaxcompiler/algorithms/${textType.rel_id}`}
                    >
                      (#{textType.rel_id})
                    </Link>
                    <BtnEdit el={textType} setStructure={setStructure} />
                    <Typography>{textType.text}</Typography>
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 600 }}>{`${textType.prefixedOrder}`}</Typography>
                    <Typography sx={{ fontWeight: 600, ml: '5px' }}>{textType.module}</Typography>
                    <BtnEdit el={textType} setStructure={setStructure} />
                    <Typography>{textType.text}</Typography>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                {isShow ? (
                  <>
                    <BtnRowAddNewLine el={el} setStructure={setStructure}></BtnRowAddNewLine>
                    <BtnRowAddAlgo el={el} setStructure={setStructure}></BtnRowAddAlgo>
                    <BtnLanguage el={el} setStructure={setStructure} />
                  </>
                ) : null}

                <RemoveBtn el={el} setStructure={setStructure} />
              </Box>
            </Box>
          );
        };

        return (
          <StyledTreeItem
            sx={{ pt: '10px' }}
            key={el.id}
            nodeId={String(el.id)}
            label={label(el)}
            children={el.child.length > 0 ? renderTree(el.child, setStructure) : null}
          />
        );
      })}
    </>
  );
};

export default function CustomizedTreeView({ selectItem, setSelectItem, structure, setStructure }: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const expanded: any = [];

  const setExpanded = (arr: Array<string>) => {
    arr.map((el: any) => {
      expanded.push(el.id + '');
      if (el.child.length > 0) {
        el.child ? setExpanded(el.child) : null;
      }
    });
  };

  if (structure) {
    setExpanded(structure);
  }

  return (
    <>
      <Box sx={{ mt: '24px' }}>
        {structure.length === 0 ? '' : <Divider sx={{ mt: 2 }} />}

        <TreeView expanded={expanded} defaultCollapseIcon={<CloseSquare />}>
          {renderTree(structure, setStructure)}
        </TreeView>

        <Divider sx={{ mt: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '24px' }}>
          <BtnNewLine selectItem={selectItem} setStructure={setStructure} />
          <Button
            children={t('syntaxcompiler.cancel')}
            variant="contained"
            color="error"
            onClick={() => {
              setSelectItem('');
              navigate('/syntaxcompiler/analysis');
            }}
          />
        </Box>
      </Box>
    </>
  );
}
