import { FC, useContext, useEffect, useState } from 'react';
import { getData } from '../../api/dashboard';

import Layout from '../../layouts/Header';
import Spinner from '../../components/Spinner';
import Card from './components/Card';
import LicensesTable from './components/Table';
import Notifications from './components/Notifications';

import { Snackbar, Typography } from '@mui/material';
import { showError } from '../../helpers';
import { useTranslation } from 'react-i18next';

const Dashboard: FC = () => {
  const { t } = useTranslation();
  const [dataRes, setDataRes] = useState<any>();
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    getData()
      .then((res) => {
        setDataRes(res.data.data);
      })
      .catch((error) => {
        setErrorMessage(error.message || error.response.data.messages);
        setOpenError(true);
      });
  }, []);

  return (
    <>
      <Layout>
        <Typography children={'Dashboard'} variant="h5" sx={{ m: '24px' }} />

        {dataRes ? (
          <>
            <Notifications notifications={dataRes.notifications} />
            <Card statistic={dataRes.statistic} />
            {dataRes.licenses.length > 0 ? (
              ''
            ) : (
              <Typography children={t('dashboard.noActionNeededNow')} sx={{ m: '24px' }} color="#28a745" />
            )}
            <LicensesTable licenses={dataRes.licenses} />
          </>
        ) : (
          <Spinner />
        )}
        {errorMessage ? (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openError}
            autoHideDuration={2000}
            onClose={() => setOpenError(false)}
          >
            {showError(errorMessage)}
          </Snackbar>
        ) : (
          ''
        )}
      </Layout>
    </>
  );
};

export default Dashboard;
