import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from '../../../../../../store';

const RemoveLicenseBtn = ({ data }: any) => {
  const { row, setPage } = data;
  const { deleteLicense } = store.customerRegister;
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Button children={t('licenses.removeLicense')} color="error" variant="contained" onClick={() => setOpen(true)} />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle children={t('licenses.remove')} />
        <DialogContent dividers>{`${row.license_type_name}: ${t('licenses.licenseHaveIntegration')}`}</DialogContent>
        <DialogActions>
          <Button children={t('licenses.cancel')} variant="contained" color="error" onClick={() => setOpen(false)} />
          <Button
            children={t('licenses.removeLicense')}
            variant="contained"
            color="success"
            onClick={() => {
              setOpen(false);
              deleteLicense(row.id);
              setPage(0);
            }}
          />{' '}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RemoveLicenseBtn;
