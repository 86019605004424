import { Box, Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from '../../../../../../layouts/Header';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import { useNavigate, useParams } from 'react-router-dom';
import { createModule, getModulesById, updateModule } from '../../../../../../api/modules';
import Spinner from '../../../../../../components/Spinner';
import Notification from '../../../../../../components/Notification';
import { INotification } from '../..';
import { TwitterPicker } from 'react-color';
import { useForm, Controller } from 'react-hook-form';

export interface IData {
  id?: number;
  name: string;
  label: string;
  label_color: string;
  legend: string;
  static?: number;
}

function NewModule() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState<IData | undefined>();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      name: '',
      label: '',
      legend: '',
      label_color: '#000000',
    },
  });

  const [notification, setNotification] = useState<INotification>({
    loading: false,
    message: '',
    status: 'error',
  });

  const onSubmit = (data: any) => {
    id
      ? updateModule(+id, data)
          .then(() => {
            setTimeout(() => {
              navigate('/syntaxcompiler/modules');
            }, 2000);

            setNotification({
              ...notification,
              loading: true,
              message: t('syntaxcompiler.updated'),
              status: 'success',
            });
          })
          .catch((error) => {
            setNotification({
              ...notification,
              loading: true,
              message: error.response?.data?.messages || error.message,
              status: 'error',
            });
          })
      : createModule(data)
          .then(() => {
            setNotification({
              ...notification,
              loading: true,
              message: t('syntaxcompiler.created'),
              status: 'success',
            });
            setTimeout(() => {
              navigate('/syntaxcompiler/modules');
            }, 2000);
          })
          .catch((error) => {
            setNotification({
              ...notification,
              loading: true,
              message: error.response.data.messages,
              status: 'error',
            });
          });
  };

  useEffect(() => {
    if (id) {
      getModulesById(+id)
        .then((res) => {
          const { name, label, label_color, legend } = res.data.data[0];
          setData({ ...data, name, label, label_color, legend });
          setValue('name', name);
          setValue('label', label);
          setValue('legend', legend);
          setValue('label_color', label_color);
        })
        .catch((error) => {
          setNotification({
            ...notification,
            loading: true,
            message: error.response?.data?.messages,
            status: 'error',
          });
        });
    }
  }, []);

  const rule = /[a-z+!@#$%^&*() ,./+"'!=]/;
  const rule2 = /[0-9!@#$%^&*() ,./_+"'!-=]/;

  return (
    <Layout>
      {!data && id ? (
        <Spinner />
      ) : (
        <Box
          onSubmit={handleSubmit(onSubmit)}
          component={'form'}
          sx={{ display: 'flex', flexDirection: 'column', width: '40%', m: 3 }}
        >
          <>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  variant="standard"
                  label={t('syntaxcompiler.name')}
                  value={field.value}
                  onChange={(e) =>
                    field.onChange(
                      e.target.value
                        .toLocaleUpperCase()
                        .split('')
                        .map((el: string, i: number) => {
                          if (i === 0) {
                            return el.replace(rule2, '');
                          }

                          return el.replace(rule, '');
                        })
                        .join('')
                    )
                  }
                />
              )}
            />
          </>

          <TextField
            {...register('legend', { required: true })}
            variant="standard"
            label={t('syntaxcompiler.legend')}
          />
          <TextField {...register('label', { required: true })} variant="standard" label={t('syntaxcompiler.label')} />

          <Box sx={{ mt: 2 }}>
            <Controller
              name="label_color"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <TwitterPicker
                  color={field.value}
                  onChange={(e) => field.onChange(e.hex)}
                  width={'100%'}
                  triangle="top-left"
                  colors={[
                    '#FF6900',
                    '#7BDCB5',
                    '#00D084',
                    '#8ED1FC',
                    '#ABB8C3',
                    '#F78DA7',
                    '#9900EF',
                    '#f2df00',
                    '#edb300',
                    '#f99600',
                    '#DC143C',
                    '#1e90ff',
                  ]}
                />
              )}
            />
          </Box>
          <Box sx={{ mt: 2, alignSelf: 'self-end' }}>
            <Button
              children={t('syntaxcompiler.cancel')}
              variant="contained"
              color="error"
              onClick={() => navigate('/syntaxcompiler/modules')}
            />
            <Button
              sx={{ ml: 1 }}
              type="submit"
              children={t('syntaxcompiler.save')}
              variant="contained"
              color="success"
              startIcon={<LibraryAddIcon />}
              disabled={!isDirty}
            />
          </Box>
        </Box>
      )}
      {notification.loading ? <Notification data={{ notification, setNotification }} /> : null}
    </Layout>
  );
}

export default NewModule;
