import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  deleteAnalysisById,
  getSyntAnalysisCode,
  getSyntaxAnalysis,
  postStatusById,
} from '../../../../../../api/syntax';

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IAnalysis, ISelectItem } from '../..';
import { IStructure } from '../CustomizedTreeView';
import { useNavigate } from 'react-router-dom';

interface ICode {
  id: number;
  row_type: string;
  text: string;
  syntax?: string;
  child?: [];
}

const DialogButton = ({
  selectItem,
  setSelectItem,
  setAnalysis,
}: {
  selectItem: IAnalysis;
  setSelectItem: React.Dispatch<React.SetStateAction<ISelectItem | any>>;
  setAnalysis: React.Dispatch<React.SetStateAction<IAnalysis[]>>;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [openInactive, setOpenInactive] = useState<boolean>(false);
  const [openRemove, setOpenRemove] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [code, setCode] = useState<IStructure[]>([]);
  const [loading, setLoading] = useState(false);

  const toggleNameButton = (statusItem: number) =>
    statusItem === 2 ? t('syntaxcompiler.publish') : t('syntaxcompiler.unpublish');

  const renderTree = (code: ICode[]) => {
    return (
      <>
        {code.map((el: ICode) => {
          const label = (textType: ICode) => {
            const isShow = textType.row_type === 'text_row';
            const algo = textType.row_type === 'algorithm';

            return (
              <Box key={el.id} sx={{ display: 'flex' }}>
                <Typography sx={{ m: '10px' }}>{isShow ? `<Text> ${textType.text} </Text> ` : ''}</Typography>
                {algo ? textType.syntax : ''}
              </Box>
            );
          };

          return (
            <Box key={el.id}>
              {label(el)}
              {Array.isArray(el.child) ? renderTree(el.child) : null}
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <ButtonGroup sx={{ width: 'max-content', flexWrap: 'wrap' }}>
        <Button
          key="one"
          children={toggleNameButton(selectItem.status)}
          color="success"
          variant="contained"
          onClick={() => setOpenInactive(true)}
          sx={{ mr: '10px' }}
        />
        <Dialog open={openInactive} fullWidth onClose={() => setOpenInactive(false)}>
          <DialogTitle children={t('syntaxcompiler.doYouWant')} />
          <DialogContent dividers>
            <DialogContentText>{`${
              selectItem.status === 2 ? t('syntaxcompiler.publish') : t('syntaxcompiler.unpublish')
            }: ${selectItem.title}`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('syntaxcompiler.cancel')}
              variant="contained"
              color="error"
              onClick={() => setOpenInactive(false)}
            />
            <Button
              children={t('syntaxcompiler.yes')}
              variant="contained"
              color="success"
              onClick={() => {
                selectItem.status === 1
                  ? postStatusById(selectItem.id, 2).then((result) => {
                      getSyntaxAnalysis().then((res) => {
                        setAnalysis(res.data.data);
                        const currentEl = res.data.data.find((el: IAnalysis) => el?.id === result.data.analysis?.id);
                        if (currentEl) {
                          setSelectItem(currentEl);
                        }
                      });
                    })
                  : postStatusById(selectItem.id, 1).then((result) => {
                      getSyntaxAnalysis().then((res) => {
                        setAnalysis(res.data.data);
                        const currentEl = res.data.data.find((el: IAnalysis) => el?.id === result.data.analysis?.id);
                        if (currentEl) {
                          setSelectItem(currentEl);
                        }
                      });
                    }),
                  setOpenInactive(false);
              }}
            />
          </DialogActions>
        </Dialog>
        <Button
          key="two"
          children={t('syntaxcompiler.delete')}
          color="error"
          variant="contained"
          onClick={() => setOpenRemove(true)}
          sx={{ mr: '10px' }}
        />
        <Dialog fullWidth open={openRemove} onClose={() => setOpenRemove(false)}>
          <DialogTitle children={t('syntaxcompiler.doYouWant')} />
          <DialogContent dividers>
            <DialogContentText>{<>{`${t('syntaxcompiler.delete')}: ${selectItem.title}`}</>}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              children={t('syntaxcompiler.cancel')}
              variant="contained"
              color="error"
              onClick={() => setOpenRemove(false)}
            />
            <Button
              children={t('syntaxcompiler.yes')}
              variant="contained"
              color="success"
              onClick={() => {
                setOpenRemove(false);
                setSelectItem('');
                navigate('/syntaxcompiler/analysis');
                deleteAnalysisById(selectItem.id);
              }}
            />
          </DialogActions>
        </Dialog>
        <Button
          key="three"
          children={t('syntaxcompiler.showCode')}
          color="info"
          variant="contained"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            setOpen(true);
            getSyntAnalysisCode(selectItem.id).then((res) => {
              setCode(res.data.structure);
              setLoading(true);
            });
          }}
        />
        <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
          <DialogTitle children={t('syntaxcompiler.code')} />
          <DialogContent dividers>{!loading ? t('syntaxcompiler.loading') : <>{renderTree(code)}</>}</DialogContent>
          <DialogActions>
            <Button
              children={t('syntaxcompiler.close')}
              variant="contained"
              color="error"
              onClick={() => setOpen(false)}
            />
          </DialogActions>
        </Dialog>
      </ButtonGroup>
    </Box>
  );
};

export default DialogButton;
