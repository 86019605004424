import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAnalysisRecords,
  getAnalysisRecordsId,
  getSyntAnalysisById,
  getSyntaxAnalysis,
} from '../../../../api/syntax';
import RecentEvent from '../../../../components/RecentEvent';
import { getStatusAnalysis } from '../../../../helpers';

import Layout from '../../../../layouts/Header';
import CreateButton from './components/CreateButton';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';

import DialogButton from './components/DialogButton';
import CustomizedTreeView from './components/CustomizedTreeView';
import Spinner from '../../../../components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';

export interface IAnalysis {
  id: number;
  number: number;
  status: number;
  register: number;
  language: number;
  title: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  updated: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  created_by: number;
  updated_by?: number;
}

export interface ISelectItem {
  id: number;
  status: number;
  title: string;
}

interface IRecords {
  id: number;
  user: number;
  api: number;
  rel_type: number;
  rel_id: number;
  type: string;
  data: string;
  location: string;
  user_agent: string;
  timestamp: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  description: string;
  test: string;
  locale: string;
}

export default function AnalysisPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [analysis, setAnalysis] = useState<IAnalysis[]>([]);
  const [selectItem, setSelectItem] = useState<ISelectItem | any>();

  const [structure, setStructure] = useState([]);

  const [records, setRecords] = useState<IRecords[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (selectItem === '') {
      setLoading(false);
    }

    getSyntaxAnalysis().then((res) => {
      setLoading(false);
      setAnalysis(res.data.data);
      if (id) {
        const currentElement = res.data.data.find((el: IAnalysis) => el.id === +id);
        setSelectItem(currentElement);
        setLoading(true);

        getSyntAnalysisById(Number(id))
          .then((result) => {
            setStructure(result.data.structure);
          })
          .then(() => setLoading(false));
      }
    });
  }, [id]);

  useEffect(() => {
    if (selectItem) {
      getAnalysisRecordsId(id).then((res) => setRecords(res.data.data));
    } else {
      getAnalysisRecords().then((res) => setRecords(res.data.data));
    }

    return () => setSelectItem('');
  }, [id]);

  const handleChange = (event: any) => {
    setSelectItem(event.target.value);
    navigate(`/syntaxcompiler/analysis/${event.target.value.id || ''}`);
  };

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: ' 24px' }}>
        <Box sx={{ width: '80%' }}>
          <Typography children={t('syntaxcompiler.analysis')} variant="h5" />
          <Box>
            <Box>
              {loading && id ? (
                <Spinner />
              ) : (
                <FormControl fullWidth variant="standard">
                  <InputLabel children={t('syntaxcompiler.selectAnalysis')} />
                  <Tooltip
                    leaveDelay={10}
                    title={
                      <>
                        {selectItem
                          ? `${selectItem.title} ${getStatusAnalysis(selectItem.status)}`
                          : t('syntaxcompiler.selectAnalysis')}
                      </>
                    }
                  >
                    <Select value={selectItem || ''} onChange={handleChange}>
                      <MenuItem value="">None</MenuItem>

                      {analysis.map((el: any) => (
                        <MenuItem key={el.id} value={el}>
                          #{el.id} {el.title} {getStatusAnalysis(el.status)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Tooltip>
                  <FormHelperText children={t('syntaxcompiler.selAnalysisOrCreateNew')} />
                </FormControl>
              )}
              {!selectItem || loading ? (
                ''
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Box sx={{ mt: 2, maxWidth: '50%' }}>
                      <Typography children={`#${selectItem.id} ${selectItem.title}`} variant="h5" />
                      <Typography
                        sx={{ color: 'green' }}
                        children={getStatusAnalysis(selectItem.status)}
                        variant="h5"
                      />
                    </Box>
                    <DialogButton selectItem={selectItem} setSelectItem={setSelectItem} setAnalysis={setAnalysis} />
                  </Box>

                  <CustomizedTreeView
                    selectItem={selectItem}
                    setSelectItem={setSelectItem}
                    structure={structure}
                    setStructure={setStructure}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CreateButton />
          <RecentEvent records={records} />
        </Box>
      </Box>
    </Layout>
  );
}
