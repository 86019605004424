import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { postData, putData } from '../../api/customerRegister';

import { Box, Button, TextField, Typography } from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Notification from '../Notification';

interface IUserData {
  id: number;
  status: number;
  number: number;
  company: string;
  corporate: string;
  address: string;
  zip: string;
  city: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}

const FormData = ({ dataUser }: { dataUser: IUserData | undefined }) => {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data: any) => {
    dataUser === undefined
      ? postData(data)
          .then(() => {
            setNotification({
              ...notification,
              loading: true,
              message: t('customers.newCustomerCreated'),
              status: 'success',
            });
            setTimeout(() => {
              navigation('/customers');
            }, 2000);
          })
          .catch((error: any) => {
            setNotification({
              ...notification,
              loading: true,
              message: error.response?.data?.messages || error.message,
              status: 'error',
            });
          })
      : putData(dataUser?.id, data)
          .then(() => {
            setTimeout(() => {
              navigation('/customers');
            }, 2000);
            setNotification({
              ...notification,
              loading: true,
              message: t('customers.dataChanged'),
              status: 'success',
            });
          })
          .catch((error: any) => {
            setNotification({
              ...notification,
              loading: true,
              message: error.response?.data?.messages || error.message,
              status: 'error',
            });
          });
  };

  return (
    <Box
      component="form"
      sx={{ 'margin': '0px 24px', '& .MuiTextField-root': { m: '8px 0', width: '70%' }, 'maxWidth': '60%' }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Box>
        <TextField
          {...register('company', { required: true })}
          defaultValue={dataUser?.company}
          label={t('customers.companyName')}
          variant="standard"
          required
        />
        {errors?.company?.type && (
          <Typography
            variant="caption"
            color="error"
            children={t('customers.enterValidCompName')}
            sx={{ display: 'block', ml: '10px' }}
          />
        )}
        <TextField
          {...register('corporate')}
          defaultValue={dataUser?.corporate}
          label={t('customers.orgNumber')}
          variant="standard"
        />
        <TextField
          {...register('address')}
          defaultValue={dataUser?.address}
          label={t('customers.address')}
          variant="standard"
        />
        <TextField
          {...register('zip')}
          defaultValue={dataUser?.zip}
          label={t('customers.zipCode')}
          variant="standard"
        />
        <TextField {...register('city')} defaultValue={dataUser?.city} label={t('customers.city')} variant="standard" />
        <Typography children={t('customers.contactPerson')} variant="h6" sx={{ p: '8px 0', m: '24px 0' }} />
        <Box>
          <TextField
            {...register('firstname', { required: true })}
            defaultValue={dataUser?.firstname}
            label={t('customers.contactPersonFirsName')}
            variant="standard"
            required
          />
          {errors?.firstname?.type && (
            <Typography
              variant="caption"
              color="error"
              children={t('customers.enterContactPersonFirstName')}
              sx={{ display: 'block', ml: '10px' }}
            />
          )}
          <TextField
            {...register('lastname', { required: true })}
            defaultValue={dataUser?.lastname}
            label={t('customers.contactPersonLastName')}
            variant="standard"
            required
          />
          {errors?.lastname?.type && (
            <Typography
              variant="caption"
              color="error"
              children={t('customers.enterContactPersonLastName')}
              sx={{ display: 'block', ml: '10px' }}
            />
          )}
          <TextField
            {...register('phone')}
            defaultValue={dataUser?.phone}
            label={t('customers.contactPersonPhoneNumber')}
            variant="standard"
          />
          <TextField
            {...register('email', {
              required: true,
              pattern:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            defaultValue={dataUser?.email}
            label={t('customers.contactPersonEmail')}
            variant="standard"
            required
          />
          {errors.email?.type && (
            <Typography
              variant="caption"
              color="error"
              children={t('customers.enterContactPersonEmail')}
              sx={{ display: 'block', ml: '10px' }}
            />
          )}
        </Box>
        <Button
          type="submit"
          children={t('customers.saveCustomer')}
          variant="contained"
          color="success"
          startIcon={<LibraryAddIcon />}
          sx={{ m: '24px 0' }}
          disabled={!isDirty || !isValid}
        />
        {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
      </Box>
    </Box>
  );
};

export default FormData;
