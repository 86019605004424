import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useEffect, useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Box,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDateYMD } from '../../../../../../helpers';
import RemoveLicenseBtn from '../RemoveLicenseBtn';
import AddIcon from '@mui/icons-material/Add';
import store from '../../../../../../store';
import { observer } from 'mobx-react-lite';

const LicenseTable = observer(() => {
  const { t } = useTranslation();
  const { customerData, getCustomerLicenses, customerLicenses, deleted, isLoadingLicenses } = store.customerRegister;
  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [orderBy, setOrderBy] = useState<string>('');
  const [sortFlag, setSortflag] = useState<boolean>(false);

  const headCells = [
    { id: '1', label: t('licenses.licenseNumber'), sortField: '' },
    { id: '2', label: t('licenses.type'), sortField: 'license_type_id' },
    { id: '3', label: t('licenses.maxCall'), sortField: 'callsforperiod' },
    { id: '4', label: t('licenses.thSaved'), sortField: 'created' },
    { id: '5', label: t('licenses.validUntil'), sortField: 'expires' },
    { id: '7', label: '', sortField: '' },
    { id: '8', label: '', sortField: '' },
    { id: '6', label: '', sortField: '' },
  ];

  useEffect(() => {
    getCustomerLicenses(Number(id), page, rowsPerPage);
  }, [deleted]);

  const handleChangePage = (event: any, newPage: number) => {
    getCustomerLicenses(Number(id), newPage + 1, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    getCustomerLicenses(Number(id), 1, event.target.value);
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSort = (sortField?: string) => {
    let sorting;

    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setOrderBy(sortField);
    }

    getCustomerLicenses(Number(id), page, rowsPerPage, sorting);
    setSortflag(!sortFlag);
    setPage(0);
  };

  return (
    <>
      <Box sx={{ m: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Typography children={t('licenses.licenses')} variant="h5" sx={{ p: '8px 0', width: '50%' }} />
          <Button
            disabled={customerData?.status === 2}
            children={t('licenses.addNewLicense')}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate(`/licenses/add_new/${customerData?.id}`)}
            sx={{ height: 'fit-content' }}
          />
        </Box>
        {isLoadingLicenses ? (
          t('customers.loading')
        ) : customerLicenses?.data === null ? null : (
          <TableContainer sx={{ width: '100%' }}>
            <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                      {!headCell.sortField ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          IconComponent={ArrowDropUpIcon}
                          active={headCell.sortField === orderBy}
                          direction={sortFlag ? 'asc' : 'desc'}
                          onClick={() => {
                            handleSort(headCell.sortField);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {customerLicenses?.data?.map((row: any) => (
                  <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell children={row.license_number} align="left" />
                    <TableCell children={row.license_type_name} align="left" />
                    <TableCell children={row.callsforperiod} align="left" />
                    <TableCell children={formatDateYMD(row.created.date)} align="left" />
                    <TableCell children={formatDateYMD(row.expires.date)} align="left" />
                    <TableCell align="left">
                      <Button
                        children={t('licenses.manageLicense')}
                        variant="contained"
                        onClick={() => navigate(`/licenses/card/${row.id}`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        children={t('licenses.createApiIntegration')}
                        variant="contained"
                        onClick={() => navigate(`/integration/add/${row.customer_id}/${row.id}`)}
                      />
                    </TableCell>
                    <TableCell align="left">
                      <RemoveLicenseBtn data={{ row, setPage, rowsPerPage }} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', m: '0px 24px' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={customerLicenses?.pagination?.countFiltered}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        )}
      </Box>
    </>
  );
});

export default LicenseTable;
