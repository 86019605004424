import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { searchDataUsers } from '../../../../api/users';
import { getStatus, hintSearchField } from '../../../../helpers';

import Layout from '../../../../layouts/Header';
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import VisibilityIcon from '@mui/icons-material/Visibility';
import Spinner from '../../../../components/Spinner';

export interface IUsers {
  id: number;
  status: number;
  firstname: string;
  lastname: string;
  username: string;
  authentication_fail: number;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  two_step_activated: number;
  language: string;
  activated: boolean;
  is_password_reset: boolean;
}

interface IPagination {
  page: number;
  pages: number;
  limit: number;
  countTotal: number;
  countFiltered: number;
}

const Users: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [users, setUsers] = useState<IUsers[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [search, setSearch] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [checked, setChecked] = useState<boolean>(false);
  const [sort, setSort] = useState<Array<string>>([]);
  const [searchableFields, setSearchableFields] = useState<Array<string>>([]);

  const status = (statusField: number) => (statusField ? t('administration.yes') : t('administration.no'));

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        searchDataUsers(search, 0, rowsPerPage, checked).then((res) => {
          setUsers(res.data.data);
          setSearchableFields(res.data.searchableFields);
          const pagination = {
            ...res.data.pagination,
            countTotal: res.data.pagination.countFiltered,
          };
          setPagination(pagination);
          setPage(0);
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value);

  const handleChangePage = (event: any, newPage: number) => {
    searchDataUsers(search, newPage + 1, rowsPerPage, checked).then((res) => setUsers(res.data.data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    searchDataUsers(search, 1, event.target.value, checked).then((res) => setUsers(res.data.data));
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    searchDataUsers(search, 1, rowsPerPage, event.target.checked).then((res) => {
      setUsers(res.data.data);
      const pagination = {
        ...res.data.pagination,
        countTotal: res.data.pagination.countFiltered,
      };
      setPagination(pagination);
    });
    setPage(0);
  };

  const [sortFlag, setSortflag] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string | undefined>();

  const handleSort = (sortField?: string) => {
    let sorting;
    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setSort(sorting);
      setOrderBy(sortField);
    }

    searchDataUsers(search, 1, rowsPerPage, checked, sort).then((res) => {
      setUsers(res.data.data);
    });
    setPage(0);
  };

  const headCells = [
    { id: '1', label: t('administration.status'), sortField: 'status' },
    { id: '2', label: t('administration.thFirstName'), sortField: 'firstname' },
    { id: '3', label: t('administration.thLastName'), sortField: 'lastname' },
    { id: '4', label: t('administration.thUserName'), sortField: 'username' },
    { id: '5', label: t('administration.th2FA') },
    { id: '6', label: '' },
  ];

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', m: ' 24px' }}>
        <Typography children={t('administration.user')} variant="h5" />
        <Button
          onClick={() => navigate('/users/add')}
          variant="contained"
          children={t('administration.addNewUser')}
          startIcon={<AddIcon />}
        />
      </Box>
      <Tooltip title={hintSearchField(searchableFields)}>
        <TextField
          label={t('administration.search')}
          type="search"
          size="small"
          onChange={handleSearch}
          sx={{ ml: '24px' }}
        />
      </Tooltip>
      {!pagination ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table sx={{ m: '0px 24px', width: '-webkit-fill-available' }}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                    {!headCell.sortField ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        IconComponent={ArrowDropUpIcon}
                        active={headCell.sortField === orderBy}
                        direction={sortFlag ? 'asc' : 'desc'}
                        onClick={() => {
                          handleSort(headCell.sortField);
                          setSortflag(!sortFlag);
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((row: any) => (
                <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{getStatus(row.status)}</TableCell>
                  <TableCell align="left">{row.firstname}</TableCell>
                  <TableCell align="left">{row.lastname}</TableCell>
                  <TableCell align="left">{row.username}</TableCell>
                  <TableCell align="left">{status(row.two_step_activated)}</TableCell>
                  <TableCell align="left">
                    {row.status ? (
                      <Button
                        children={t('administration.viewProfile')}
                        variant="contained"
                        startIcon={<VisibilityIcon />}
                        onClick={() => navigate(`/users/card/${row.id}`)}
                      />
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', m: '0px 24px' }}>
            <FormControlLabel
              control={<Switch size="small" checked={checked} onChange={handleChange} />}
              label={t('administration.showDeleted')}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={pagination.countTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      )}
    </Layout>
  );
};

export default Users;
