import { useEffect, useState } from 'react';
import { apiClient } from '../../../../api/axios';
import parse from 'html-react-parser';
import { Box } from '@mui/material';
import DocumentationLayout from '../..';
import '../../styles.css';
import Spinner from '../../../../components/Spinner';

const RequestErrorMessages = () => {
  const [requestErrorContent, setRequestErrorContent] = useState<string>('');

  useEffect(() => {
    const getRequestError = async () => {
      try {
        const { data } = await apiClient.get('/documentation/api/errors');

        setRequestErrorContent(data);
      } catch (error) {
        throw error;
      }
    };

    getRequestError();
  }, []);

  return (
    <DocumentationLayout>
      <Box sx={{ m: '24px', width: 'inherit' }} className={'container'}>
        {requestErrorContent ? parse(requestErrorContent) : <Spinner />}
      </Box>
    </DocumentationLayout>
  );
};

export default RequestErrorMessages;
