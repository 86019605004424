import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { getCustomers, getLicensesCustomer } from '../../../../api/customerRegister';
import { getNewIntegrationDataSet, postApiIntegration } from '../../../../api/apiIntegration';
import { formatDate, showError } from '../../../../helpers';

import {
  Typography,
  Box,
  InputLabel,
  MenuItem,
  Select,
  Alert,
  Button,
  FormControl,
  Snackbar,
  TextField,
} from '@mui/material';
import GoBackButton from '../../../../components/GoBackButton';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import Layout from '../../../../layouts/Header';
import Spinner from '../../../../components/Spinner';

interface ICustomers {
  id: number;
  status: number;
  number: number;
  company: string;
  corporate: string;
  address: string;
  zip: string;
  city: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}

interface ILicenses {
  id: number;
  status: number;
  license_type_id: number;
  customer_id: number;
  callsforperiod: number;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  expires: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  license_number: number;
  license_type_name: string;
}

interface IProductRegister {
  id: number;
  last_sync: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  register_name: string;
}

interface ILanguages {
  id: number;
  status: number;
  iso6391: string;
  name_text: string;
  name_text_en: string;
}

interface ISyntaxAnalysis {
  id: number;
  number: number;
  status: number;
  register: number;
  language: number;
  title: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  updated: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  created_by: number;
  updated_by?: number;
}

interface IRegion {
  regionId: number;
  regionName: string;
}

interface IFormData {
  licenses_id: number;
  product_register_id: number;
  regionId: number;
  lang_codes_id: number;
  kidney_function: number;
  syntax_analyse_id: number;
  callback_url: string;
}

const NewIntegration = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const navigation = useNavigate();

  const [kidneyFunctions] = useState([
    { id: 1, name: 'Cockcroft-Gault' },
    { id: 2, name: 'Lund-Malmö, reviderad' },
  ]);
  const [customers, setCustomers] = useState<ICustomers[]>([]);
  const [licenses, setLicenses] = useState<ILicenses[]>([]);
  const [productRegister, setProductRegister] = useState<IProductRegister[]>([]);
  const [languages, setLanguages] = useState<ILanguages[]>([]);
  const [syntaxAnalysis, setSyntaxAnalysis] = useState<ISyntaxAnalysis[]>([]);
  const [regions, setRegions] = useState<IRegion[]>([]);
  const [customersId, setCustomersId] = useState<string | undefined>(customerId);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormData>({
    licenses_id: 3,
    product_register_id: 1,
    regionId: 1,
    lang_codes_id: 4,
    kidney_function: 1,
    syntax_analyse_id: 1,
    callback_url: '',
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([getCustomers(), getNewIntegrationDataSet()])
      .then((res) => {
        setCustomers(res[0].data.data);
        setSyntaxAnalysis(res[1].data.analysis);
        setLanguages(res[1].data.languages);
        setRegions(res[1].data.regions);
        setProductRegister(res[1].data.registers);
        setLoading(false);
      })
      .catch((error) => setErrorMessage(error.messages || error.response.data.messages));
  }, []);

  useEffect(() => {
    if (customersId) {
      setLoading(true);
      getLicensesCustomer(customersId)
        .then((res) => {
          setLoading(false);
          setLicenses(res.data.data);
          if (res.data.data !== null) {
            const defaultLicense = res.data.data.find((el: any) => el.license_type_id === 3).id;
            setFormData({ ...formData, licenses_id: defaultLicense });
          }
        })
        .catch((error) => setErrorMessage(error.messages || error.response.data.messages));
    }
  }, [customersId]);

  const handleChangeCustomer = (event: any) => {
    setCustomersId(event.target.value);
    navigation(`/integration/add/${event.target.value}`);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      await postApiIntegration(formData);
      setOpen(true);
    } catch (error: any) {
      setErrorMessage(error.messages || error.response.data.messages);
    }
  };

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <GoBackButton />
          <Typography children={t('apiIntegration.addNewIntegration')} variant="h5" sx={{ m: '0px 24px' }} />
        </Box>
      </Box>
      <Box component="form" autoComplete="off" onSubmit={handleSubmit} sx={{ width: '60%', m: '0 24px' }}>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <FormControl fullWidth variant="standard">
              <InputLabel children={t('apiIntegration.customerLabel')} />
              <Select sx={{ mb: '20px' }} variant="standard" value={customersId || ''} onChange={handleChangeCustomer}>
                <MenuItem value="">
                  <em>{t('apiIntegration.none')}</em>
                </MenuItem>
                {customers.map((customer: any) => (
                  <MenuItem
                    key={customer.id}
                    value={customer.id}
                  >{`${customer.number} - ${customer.company}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {loading || customerId == undefined ? (
              ''
            ) : loading || licenses === null ? (
              <Alert
                children={t('apiIntegration.customerHasNoActiveLicense')}
                severity="error"
                variant="filled"
                sx={{ width: 'fit-content', m: '24px 0' }}
              />
            ) : (
              <>
                <FormControl fullWidth variant="standard">
                  <InputLabel children={t('apiIntegration.licenseLabel')} />
                  <Select
                    value={formData.licenses_id}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        licenses_id: e.target.value,
                      })
                    }
                    sx={{ display: 'block', mb: '20px' }}
                    variant="standard"
                  >
                    <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                    {licenses.map((license: any) => (
                      <MenuItem
                        children={`${license.license_number} - ${license.license_type_name}`}
                        key={license.id}
                        value={license.id}
                      />
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel children={t('apiIntegration.productRegister')} />
                  <Select
                    value={formData.product_register_id || ''}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        product_register_id: e.target.value,
                      })
                    }
                    sx={{ display: 'block', mb: '20px' }}
                    variant="standard"
                  >
                    <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                    {productRegister.map((register: any) => (
                      <MenuItem
                        children={`${register.register_name} - ${t('apiIntegration.lastSync')} ${formatDate(
                          register.last_sync.date
                        )}`}
                        key={register.id}
                        value={register.id}
                      />
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel children={t('apiIntegration.regionsSilLabel')} />
                  <Select
                    value={formData.regionId || ''}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        regionId: e.target.value,
                      })
                    }
                    sx={{ display: 'block', mb: '20px' }}
                    variant="standard"
                  >
                    <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                    {regions.map((region: any) => (
                      <MenuItem children={region.regionName} key={region.regionId} value={region.regionId} />
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel children={t('apiIntegration.selectLangTitle')} />
                  <Select
                    value={formData.lang_codes_id || ''}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        lang_codes_id: e.target.value,
                      })
                    }
                    sx={{ display: 'block', mb: '20px' }}
                    variant="standard"
                  >
                    <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                    {languages
                      ?.filter((el: any) => el.status === 1)
                      ?.map((el: any) => (
                        <MenuItem key={el.id} value={el.id}>
                          {t(`language.${el.iso6391}`)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel children={t('apiIntegration.standartKidneyFuncCalcLabel')} />
                  <Select
                    value={formData.kidney_function || ''}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        kidney_function: e.target.value,
                      })
                    }
                    sx={{ display: 'block', mb: '20px' }}
                    variant="standard"
                  >
                    <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                    {kidneyFunctions.map((kidneyFunction: any) => (
                      <MenuItem children={kidneyFunction.name} key={kidneyFunction.id} value={kidneyFunction.id} />
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <InputLabel children={t('apiIntegration.syntaxAnalysisLabel')} />
                  <Select
                    value={formData.syntax_analyse_id || ''}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        syntax_analyse_id: e.target.value,
                      })
                    }
                    sx={{ display: 'block', mb: '20px' }}
                    variant="standard"
                  >
                    <MenuItem children={<em>{t('apiIntegration.none')}</em>} value="" />
                    {syntaxAnalysis.map((syntax: any) => (
                      <MenuItem children={`${syntax.number} ${syntax.title}`} key={syntax.id} value={syntax.id} />
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="callback url"
                    value={formData.callback_url}
                    onChange={(e: any) =>
                      setFormData({
                        ...formData,
                        callback_url: e.target.value,
                      })
                    }
                  ></TextField>
                </FormControl>
                <Button
                  type="submit"
                  children={t('apiIntegration.saveIntegration')}
                  variant="contained"
                  color="success"
                  startIcon={<LibraryAddIcon />}
                  sx={{ m: '24px 0' }}
                />
              </>
            )}
          </>
        )}

        {errorMessage ? showError(errorMessage) : ''}
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={() => {
            setOpen(false), navigation('/integration');
          }}
        >
          <Alert
            onClose={() => {
              setOpen(false), navigation('/integration');
            }}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {t('apiIntegration.integrationCreated')}
          </Alert>
        </Snackbar>
      </Box>
    </Layout>
  );
};

export default NewIntegration;
