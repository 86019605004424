import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatDateYMD } from '../../../../../../helpers';
import addYears from 'date-fns/addYears';
import format from 'date-fns/format';

import {
  Typography,
  Select,
  Box,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControl,
  Snackbar,
  Alert,
} from '@mui/material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import GoBackButton from '../../../../../../components/GoBackButton';
import Layout from '../../../../../../layouts/Header';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import store from '../../../../../../store';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { observer } from 'mobx-react-lite';

interface ILicenses {
  id: number;
  status: number;
  settings_type: number;
  name: string;
}

interface ICustomers {
  id: number;
  status: number;
  number: number;
  company: string;
  corporate: string;
  address: string;
  zip: string;
  city: string;
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
}

interface IData {
  license_type_id: number;
  customer_id: number;
  expires: string;
  callsforperiod: string;
}

const NewLicense = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    getLicensesList,
    getCustomersList,
    licensesList,
    customersList,
    createLicense,
    notification,
    closeNotification,
  } = store.customerRegister;
  const { register, handleSubmit, control, watch } = useForm({
    defaultValues: {
      license_type_id: 3,
      customer_id: Number(id),
      expires: '',
      callsforperiod: '',
    },
  });
  const nextYear = format(addYears(new Date(), 1), 'dd/MM/yyyy');

  useEffect(() => {
    getLicensesList();
    getCustomersList();
  }, []);

  const onSubmit: SubmitHandler<IData> = ({callsforperiod, customer_id, expires, license_type_id}) => {
    if (license_type_id === 2) {
      createLicense({
        customer_id,
        license_type_id,
        callsforperiod,
        expires: formatDateYMD(expires),
      });
    } else {
      createLicense({ customer_id, license_type_id });
    }
  };

  return (
    <Layout>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <GoBackButton />
          <Typography children={t('licenses.addNewLicense')} variant="h5" sx={{ m: '0px 24px', p: '8px' }} />
        </Box>
      </Box>

      <Box sx={{ width: '60%', m: 3 }} component="form" onSubmit={handleSubmit(onSubmit)}>
        {licensesList?.data === null ? (
          'loading'
        ) : (
          <>
            <FormControl fullWidth variant="standard">
              <InputLabel children={t('licenses.licenseType')} />
              <Select {...register('license_type_id')} variant="standard" defaultValue={3} sx={{ mb: 2 }}>
                {licensesList?.map((el: ILicenses) => (
                  <MenuItem key={el.id} children={el.name} value={el.id} />
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth variant="standard">
              <InputLabel children={t('licenses.customer')} />
              <Select {...register('customer_id')} variant="standard" defaultValue={Number(id)} sx={{ mb: 2 }}>
                {customersList?.data?.map((el: ICustomers) => (
                  <MenuItem key={el.id} children={el.company} value={el.id} />
                ))}
              </Select>
            </FormControl>

            {watch('license_type_id') === 1 ? (
              <Typography color="#28a745">
                {t('licenses.licenseValidUntil')} : {nextYear}
              </Typography>
            ) : null}

            {watch('license_type_id') === 2 ? (
              <>
                <FormControl fullWidth variant="standard">
                  <Typography sx={{ mb: '10px' }}>{t('licenses.licenseValidUntil')}</Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Controller
                      control={control}
                      name="expires"
                      render={({ field }) => (
                        <>
                          <DatePicker
                            {...field}
                            label={t('licenses.newDate')}
                            value={field.value}
                            disablePast={true}
                            renderInput={(params) => <TextField required {...params} />}
                          />
                        </>
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>

                <FormControl fullWidth variant="standard">
                  <TextField
                    {...register('callsforperiod')}
                    sx={{ mt: '10px' }}
                    type="number"
                    required
                    label={t('licenses.numberOfCallsForPeriod')}
                    variant="standard"
                  />
                </FormControl>
              </>
            ) : null}
          </>
        )}
        <Button
          type="submit"
          children={t('licenses.save')}
          variant="contained"
          color="success"
          startIcon={<LibraryAddIcon />}
          sx={{ m: '24px 0' }}
        />
      </Box>
      {!notification.isLoading ? (
        ''
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={notification.isLoading}
          autoHideDuration={5000}
          onClose={() => closeNotification()}
        >
          <Alert variant="filled" onClose={() => closeNotification()} severity={notification.severity}>
            {typeof notification.message === 'string'
              ? t(notification.message)
              : Object.values(notification.message).map((el: any) => ` ${el}`)}
          </Alert>
        </Snackbar>
      )}
    </Layout>
  );
});

export default NewLicense;
