import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RecentEvent from '../../../../../../components/RecentEvent';
import { getStatus } from '../../../../../../helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { apiClient } from '../../../../../../api/axios';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import GoBackButton from '../../../../../../components/GoBackButton';
import Layout from '../../../../../../layouts/Header';
import { deleteUser, disableTwoStepAuth, getRecordsUser, getUsersById } from '../../../../../../api/users';
import Spinner from '../../../../../../components/Spinner';
import Password from './components/Password';
import UserInfo from './components/UserInfo';
import TwoFactorAuth from './components/TwoFactorAuth';
import Notification from '../../../../../../components/Notification';

interface IUserCard {
  id: number;
  status: number;
  firstname: string;
  lastname: string;
  username: string;
  authentication_fail?: number;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  two_step_activated: number;
  language: string;
  activated: boolean;
  is_password_reset: boolean;
}

interface IRecords {
  id: number;
  user: number;
  api: number;
  rel_type: number;
  rel_id: number;
  type: string;
  data?: string;
  location: string;
  user_agent: {
    agent: string;
    is_browser: boolean;
    is_mobile: boolean;
    platform: string;
    browser: string;
    version: string;
    mobile: string;
  };
  timestamp: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  description: string;
  test: string;
  locale: string;
}

const UserCards = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [user, setUser] = useState<IUserCard>();
  const [records, setRecords] = useState<IRecords[]>([]);
  const [currentUserId, setCurrentUserId] = useState<number>();
  const [open, setOpen] = useState<boolean>(false);
  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  useEffect(() => {
    Promise.all([getRecordsUser(Number(id)), getUsersById(id), apiClient(`/access/check-auth`)])
      .then((res) => {
        setRecords(res[0].data.data);
        setUser(res[1].data.data[0]);
        setCurrentUserId(res[2].data.user.id);
      })
      .catch((error) => {
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        });
      });
  }, []);

  return (
    <Layout>
      {!user ? (
        <Spinner />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ ml: '24px' }}>
            <GoBackButton />
            <Typography variant="h5">{`${user.firstname} ${user.lastname}`}</Typography>
            <Box sx={{ width: 'fit-content' }}>{getStatus(user.status)}</Box>
            <Typography variant="h6" sx={{ mt: 3 }}>
              {t('administration.userInformation')}
            </Typography>
            <UserInfo user={user} />
            <Password userId={user.id} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', m: '24px' }}>
            {currentUserId === Number(id) ? (
              !user.two_step_activated ? (
                <TwoFactorAuth userId={user.id} setUser={setUser} />
              ) : (
                <Button
                  children={t('administration.removeTwoFactorAuth')}
                  variant="contained"
                  color="warning"
                  sx={{ mt: 1 }}
                  onClick={() => {
                    disableTwoStepAuth(Number(id))
                      .then(() => {
                        getUsersById(id).then((res) => {
                          setUser(res.data.data[0]);
                        });
                      })
                      .catch((error) => {
                        setNotification({
                          ...notification,
                          loading: true,
                          message: error.response?.data?.messages || error.message,
                          status: 'error',
                        });
                      });
                  }}
                />
              )
            ) : null}

            <Button
              children={t('administration.deleteUsers')}
              variant="contained"
              color="error"
              sx={{ mt: 1 }}
              onClick={() => {
                setOpen(true);
              }}
            />
            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
              <DialogTitle>{t('administration.deleteUsers')}</DialogTitle>
              <DialogContent dividers>
                <DialogContentText>{t('administration.areYouSure')}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="error"
                  children={t('administration.cancel')}
                  onClick={() => setOpen(false)}
                />
                <Button
                  variant="contained"
                  color="success"
                  children={t('administration.delete')}
                  onClick={() => {
                    setOpen(false);
                    deleteUser(id)
                      .then(() => {
                        setNotification({
                          ...notification,
                          loading: true,
                          message: t('administration.theUserHasBeenDeleted'),
                          status: 'success',
                        });
                        setTimeout(() => {
                          navigation('/users');
                        }, 2000);
                      })
                      .catch((error: any) => {
                        setNotification({
                          ...notification,
                          loading: true,
                          message: error.response?.data?.messages || error.message,
                          status: 'error',
                        });
                      });
                  }}
                />
              </DialogActions>
            </Dialog>
            <RecentEvent records={records} />
            {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
          </Box>
        </Box>
      )}
    </Layout>
  );
};

export default UserCards;
