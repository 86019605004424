import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLanguagesExtended, postStatusLanguage } from '../../../../../../api/languages';
import { showError } from '../../../../../../helpers';

const BtnChangeStatus = ({ row, setData }: any) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const statusCode = (row: any) => (row.status === 1 ? 0 : 1);

  return (
    <>
      <Button
        variant="contained"
        disabled={row.id === 1}
        onClick={() => setOpen(true)}
        color={row.status === 1 ? 'warning' : 'success'}
        sx={{ width: '210px' }}
      >
        {row.status === 1 ? t('settings.deactivatedLanguage') : t('settings.activateLanguage')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t(`language.${row.iso6391}`)}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>{t('settings.areYouWantChangeStatus')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="contained" onClick={() => setOpen(false)} children={t('settings.cancel')} />
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              setOpen(false);
              postStatusLanguage(row.id, statusCode(row))
                .then(() => {
                  setOpenAlert(true);
                  getLanguagesExtended().then((res) => {
                    setData(res.data.data);
                  });
                })
                .catch((error) => {
                  setErrorMessage(error.response.data.messages);
                  setOpenError(true);
                });
            }}
            children={t('settings.save')}
          />
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openAlert}
        autoHideDuration={5000}
        onClose={() => {
          setOpenAlert(false);
        }}
      >
        <Alert
          variant="filled"
          onClose={() => {
            setOpenAlert(false);
          }}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('settings.statusChanged')}
        </Alert>
      </Snackbar>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => setOpenError(false)}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </>
  );
};

export default BtnChangeStatus;
