import { Typography, Box, List, IconButton, Divider, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { getQuestionById, getQuestionRecordsId, getQuestionsAlgorithm } from '../../../../../../api/syntax';
import GoBackButton from '../../../../../../components/GoBackButton';
import Spinner from '../../../../../../components/Spinner';
import Layout from '../../../../../../layouts/Header';
import LangForm from './componets/LangForm';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RecentEvent from '../../../../../../components/RecentEvent';
import { showError } from '../../../../../../helpers';

export interface IData {
  question: {
    id: number;
    status: number;
    default_answer: number;
    draft_count: number;
    published_count: number;
    text_sv: string;
    text_sv_id: number;
    text_en: string;
    text_en_id: string;
    text_fr: number;
    text_fr_id: string;
  };
  translations: [
    {
      lang_code_id: number;
      iso6391: string;
      text: string;
    }
  ];
}

interface IUsedAlgo {
  algorithm_name: string;
  id: number;
}

interface IRecords {
  id: number;
  user: number;
  api: number;
  rel_type: number;
  rel_id: number;
  type: string;
  data?: number;
  location: string;
  user_agent: string;
  timestamp: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  description: string;
  test: string;
  locale: string;
}

const EditQuestion = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigation = useNavigate();

  const [data, setData] = useState<IData>();
  const [usedAlgo, setUsedAlgo] = useState<IUsedAlgo[]>([]);
  const [records, setRecors] = useState<IRecords[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([getQuestionById(Number(id)), getQuestionsAlgorithm(Number(id)), getQuestionRecordsId(Number(id))])
      .then((res) => {
        setData(res[0].data);
        setUsedAlgo(res[1].data.algorithms);
        setRecors(res[2].data.data);
      })
      .catch((error) => {
        setOpenError(true);
        setErrorMessage(error.response.data.messages);
      });
  }, []);

  return (
    <Layout>
      {data ? (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <GoBackButton />
            </Box>
            <Box sx={{ m: '14px 24px 0' }}>
              <RecentEvent records={records} />
            </Box>
          </Box>

          <Box sx={{ m: '0 24px', width: '60%' }}>
            <Typography variant="h6" children={`${t('syntaxcompiler.question')} #${data.question.id} `} />

            <LangForm data={data} />

            <Typography variant="h6" children={t('syntaxcompiler.usedInAlgorithms')} sx={{ mt: '24px' }} />
            {usedAlgo.map((el: any) => (
              <List key={el.id}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  {`#${el.id} ${el.algorithm_name}`}
                  <IconButton
                    color={'primary'}
                    onClick={() => {
                      navigation(`/syntaxcompiler/algorithms/${el.id}`);
                    }}
                    sx={{ ml: 1 }}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Box>
                <Divider />
              </List>
            ))}
          </Box>
        </>
      ) : (
        <Spinner />
      )}

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={2000}
          onClose={() => {
            setOpenError(false);
          }}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default EditQuestion;
