import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { addTextLineRow, getAnalysisModules, getSyntAnalysisById } from '../../../../../../../../api/syntax';
import { queryRule, validationField } from '../../../../../../../../helpers';
import { IStructure } from '../..';

const BtnRowAddNewLine = ({ el, setStructure }: { el: IStructure; setStructure: any }) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [moduleTypeList, setModuleTypeList] = useState<Array<string>>([]);

  const [value, setValue] = useState({
    text: '',
    moduleType: '',
    index: '',
    hidden: false,
    loading: true,
  });

  return (
    <>
      <Tooltip title={t('syntaxcompiler.addTextLine')}>
        <IconButton
          color={'primary'}
          onClick={() => {
            setOpen(true);
            getAnalysisModules()
              .then((res) => {
                setValue({ ...value, loading: false });
                setModuleTypeList(res.data.data);
              })
              .catch((error) => {
                setValue({ ...value, loading: false });
                setErrorMessage(error.response?.data?.messages || error.message);
              });
          }}
          sx={{ p: '5px' }}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Tooltip>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('syntaxcompiler.addTextLine')}</DialogTitle>
        <DialogContent dividers={true}>
          {value.loading ? (
            t('syntaxcompiler.loading')
          ) : errorMessage ? (
            <Alert variant="filled" severity="error" sx={{ width: 'fit-content' }}>
              {errorMessage}
            </Alert>
          ) : (
            <>
              <TextField
                autoFocus
                value={value.text}
                label={t('syntaxcompiler.addTextLine')}
                onChange={(e) => setValue({ ...value, text: e.target.value })}
                variant="standard"
                sx={{ width: '100%' }}
                error={validationField(text)}
              />
              <FormControl variant="standard" sx={{ width: '100%', marginTop: '10px' }}>
                <InputLabel children={t('syntaxcompiler.selectModuleType')} />

                <Select
                  value={value.moduleType}
                  onChange={(e) => setValue({ ...value, moduleType: e.target.value })}
                  label={t('syntaxcompiler.selectModuleType')}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {moduleTypeList
                    .filter((el) => el !== 'GENERIC')
                    .map((el: any, index: number) => (
                      <MenuItem key={index} value={el}>
                        {el}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                value={value.index}
                onChange={(e) => setValue({ ...value, index: e.target.value })}
                variant="standard"
                label="Index"
                sx={{ width: '100%' }}
                error={validationField(value.index)}
              />
              {value.moduleType !== 'GROUP' ? (
                <FormControlLabel
                  sx={{ mt: 1 }}
                  control={
                    <Checkbox checked={value.hidden} onChange={() => setValue({ ...value, hidden: !value.hidden })} />
                  }
                  label={t('syntaxcompiler.hideModule')}
                />
              ) : null}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
              setValue({ ...value, text: '', moduleType: '', hidden: false, index: '' });
            }}
          />
          <Button
            children={t('syntaxcompiler.addTextLine')}
            color="success"
            variant="contained"
            disabled={!value.moduleType || !value.text || validationField(value.text) || validationField(value.index)}
            onClick={() => {
              setValue({ ...value, text: '', moduleType: '', hidden: false, index: '' });
              setOpen(false);

              addTextLineRow(
                el.analysis,
                el.id,
                value.text,
                value.moduleType,
                value.index,
                queryRule(value.moduleType, value.hidden)
              )
                .then(() => {
                  getSyntAnalysisById(el.analysis).then((res: any) => setStructure(res.data.structure));
                })
                .catch((error) => {
                  setErrorMessage(error.response.data.messages);
                });
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BtnRowAddNewLine;
