import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const LangButton = () => {
  const { i18n } = useTranslation();

  const [language, setLanguage] = useState('en');

  const handleChange = (event: any) => setLanguage(event.target.value);

  const lngs: any = {
    en: { nativeName: 'English' },
    sv: { nativeName: 'Svenska' },
  };

  return (
    <>
      <Box sx={{ minWidth: 120 }}>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel children={'Language'} />
          <Select value={language} label="Language" onChange={handleChange}>
            {Object.keys(lngs).map((lng) => (
              <MenuItem key={lng} value={lng} onClick={() => i18n.changeLanguage(lng)}>
                {lngs[lng].nativeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default LangButton;
