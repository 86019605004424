import { TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getLogs } from '../../../../../../api/logs';

function Search({ rowsPerPage, page, setLogs, setPage, setPagination }: any) {
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    getLogs(page, rowsPerPage, [''], '', event.target.value).then((res: any) => {
      setLogs(res.data.data);
      const pagination = {
        ...res.data.pagination,
        countTotal: res.data.pagination.countFiltered,
      };
      setPagination(pagination);
      setPage(0);
    });
  };

  const tips = ['Endpoint * ', 'Request key * ', 'Request * ', 'Response'];

  return (
    <Tooltip title={tips}>
      <TextField
        label={t('productlist.search')}
        type="search"
        size="small"
        sx={{ mt: '24px' }}
        onChange={handleChange}
      />
    </Tooltip>
  );
}

export default Search;
