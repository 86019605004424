import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postSyntaxAnalysis } from '../../../../../../api/syntax';

import AddIcon from '@mui/icons-material/Add';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Notification from '../../../../../../components/Notification';

const CreateButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [create, setCreate] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const handleChange = (event: any) => setTitle(event.target.value);

  return (
    <>
      <Button
        children={t('syntaxcompiler.createNew')}
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setCreate(true)}
      />
      <Dialog fullWidth open={create} onClose={() => setCreate(false)}>
        <DialogTitle children={t('syntaxcompiler.titleInternal')} />
        <DialogContent>
          <TextField fullWidth autoFocus variant="standard" onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            variant="contained"
            color="error"
            onClick={() => setCreate(false)}
          />
          <Button
            children={t('syntaxcompiler.create')}
            variant="contained"
            onClick={() => {
              setCreate(false);
              postSyntaxAnalysis(title)
                .then((res) => {
                  const { analysis_id } = res.data;
                  navigate(`/syntaxcompiler/analysis/${analysis_id}`);
                  setNotification({
                    ...notification,
                    loading: true,
                    message: t('syntaxcompiler.analysisCreated'),
                    status: 'success',
                  });
                })

                .catch((error) => {
                  setNotification({
                    ...notification,
                    loading: true,
                    message: error.response?.data?.messages || error.message,
                    status: 'error',
                  });
                });
            }}
          />
        </DialogActions>
      </Dialog>
      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </>
  );
};

export default CreateButton;
