import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Box, Button, TextField, Typography } from '@mui/material';
import { addNewUser } from '../../../../../../api/users';
import Notification from '../../../../../../components/Notification';
import Layout from '../../../../../../layouts/Header';
import { useNavigate } from 'react-router-dom';

interface IUserData {
  firstname: string;
  lastname: string;
  username: string;
}

const AddUser = () => {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng');
  const navigation = useNavigate();

  const { register, handleSubmit } = useForm<IUserData>();

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const onSubmit: SubmitHandler<IUserData> = (data) => {
    addNewUser({ ...data, language })
      .then(() => {
        setNotification({
          ...notification,
          loading: true,
          message: t('customers.userCreated'),
          status: 'success',
        });
        setTimeout(() => {
          navigation('/users');
        }, 2000);
      })
      .catch((error) =>
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        })
      );
  };

  return (
    <Layout>
      <Box sx={{ m: 3 }}>
        <Typography variant="h5" children={t('administration.addNewUser')} />
        <Typography variant="h6" children={t('administration.userInformation')} sx={{ mt: 2 }} />
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column', maxWidth: '60%' }}
        >
          <TextField
            {...register('firstname', { required: true })}
            label={t('administration.firstName')}
            variant="standard"
          />
          <TextField
            {...register('lastname', { required: true })}
            label={t('administration.lastName')}
            variant="standard"
          />
          <TextField
            {...register('username', { required: true })}
            label={t('access.username')}
            variant="standard"
            helperText={`${t('administration.emailAddress')} ${t('administration.emailAddressUsedWhenLogIn')}`}
          />

          <Box sx={{ mt: 2, alignSelf: 'flex-end' }}>
            <Button
              children={t('administration.cancel')}
              variant="contained"
              color="error"
              onClick={() => navigation('/users')}
              sx={{ mr: 1 }}
            />
            <Button type="submit" children={t('administration.save')} color="success" variant="contained" />
          </Box>
        </Box>
        {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
      </Box>
    </Layout>
  );
};

export default AddUser;
