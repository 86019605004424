import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../helpers';

const RecentEvent = ({ records }: any) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant={'outlined'} sx={{ mt: '10px' }} onClick={() => setOpen(true)}>
        {t('customers.recentEvents')}
      </Button>
      <Dialog fullWidth={true} open={open} onClick={() => setOpen(false)}>
        <DialogTitle>{t('customers.recentEvents')}</DialogTitle>
        <DialogContent dividers={true}>
          <List sx={{ overflowY: 'auto' }}>
            {records?.map((el: any) => (
              <Box key={el.id}>
                <ListItem disablePadding>
                  <ListItemText primary={el.description} />
                </ListItem>
                <ListItem disablePadding>
                  <ListItemText primary={formatDate(el.timestamp.date)} />
                </ListItem>
                <Divider sx={{ m: '10px 0' }} />
              </Box>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color={'error'} variant={'contained'} onClick={() => setOpen(false)}>
            {t('customers.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecentEvent;
