import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import { useTranslation } from 'react-i18next';

const InteractionBtn = ({ el, className, setClassName }: any) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [blockPickerColor, setBlockPickerColor] = useState<string>(el.color);
  const [title, setTitle] = useState<string>(el.title);

  const updateClassName = (id: any, newTitle: any, newColor: any) => {
    const updateClassNames = className.map((className: any) => {
      if (id === className.id) {
        return { ...className, title: newTitle, color: newColor };
      }

      return className;
    });
    return setClassName(updateClassNames);
  };

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        sx={{
          'minWidth': 0,
          ':hover': {
            backgroundColor: blockPickerColor,
          },
          'border': 'none',
          'borderRadius': '6px',
          'backgroundColor': blockPickerColor,
          'width': '1.5em',
          'height': '1.5em',
          'color': '#FFF',
          'fontSize': '1.4em',
        }}
      >
        {title}
      </Button>
      <Dialog open={openModal}>
        <DialogTitle>{el.label}</DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <TextField
                  sx={{ width: '2.8em' }}
                  size="small"
                  inputProps={{ maxLength: 1 }}
                  value={title.toLocaleUpperCase()}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <button
                  style={{
                    border: 'none',
                    borderRadius: '6px',
                    backgroundColor: blockPickerColor,
                    width: '1.5em',
                    height: '1.5em',
                    color: '#FFF',
                    fontSize: '1.4em',
                  }}
                >
                  {title.toLocaleUpperCase()}
                </button>
              </Box>
              <ChromePicker
                color={blockPickerColor}
                onChange={(color) => {
                  setBlockPickerColor(color.hex);
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setOpenModal(false);
              setTitle(el.title);
              setBlockPickerColor(el.color);
            }}
          >
            {t('tests.cancel')}
          </Button>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setOpenModal(false);
              updateClassName(el.id, title, blockPickerColor);
            }}
          >
            {t('tests.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InteractionBtn;
