import { Box, TextField } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLogs } from '../../../../../../api/logs';
import { formatDateYMD } from '../../../../../../helpers';

function Filter({ setLogs, setPagination, filter, setFilter, page, rowsPerPage, setPage }: any) {
  const { t } = useTranslation();

  useEffect(() => {
    getLogs(page, rowsPerPage, [''], '', '', filter).then((res) => {
      setLogs(res.data.data);
      const pagination = {
        ...res.data.pagination,
        countTotal: res.data.pagination.countFiltered,
      };
      setPagination(pagination);
      setPage(0);
    });
  }, [filter]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ mr: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MobileDatePicker
            label={t('administration.from')}
            value={filter.date_from}
            inputFormat="dd/MM/y"
            onChange={(newValue: any) => {
              setFilter({ ...filter, date_from: formatDateYMD(newValue) });
            }}
            renderInput={(params) => (
              <TextField {...params} helperText="DD/MM/YYYY" variant="standard" />
            )}
          />
        </LocalizationProvider>
      </Box>
      <Box>
        {' '}
        <Box>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label={t('administration.to')}
              inputFormat="dd/MM/y"
              value={filter.date_to}
              onChange={(newValue: any) => {
                setFilter({ ...filter, date_to: formatDateYMD(newValue) });
              }}
              renderInput={(params) => (
                <TextField {...params} helperText="DD/MM/YYYY" variant="standard" />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
}

export default Filter;
