import { apiClient } from '../api/axios';

export const getDiagnosesSearch = (text: string, page: number, limit: number) =>
  apiClient(`/diagnoses`, { params: { search: text, page, limit } });

export const getDiagnosesRecords = () =>
  apiClient(`/records`, {
    params: { rel_type: 6 },
  });

export const postDiagnosesUpload = (textfile: any) =>
  apiClient.post(
    `/diagnoses/datafiles/upload`,
    { textfile },
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
