import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAnalysisModules, getSyntAnalysisById, putAnalysisModules } from '../../../../../../../../api/syntax';
import { queryRule, validationField } from '../../../../../../../../helpers';
import SettingsIcon from '@mui/icons-material/Settings';
import { IStructure } from '../..';
import Notification from '../../../../../../../../components/Notification';

const BtnEdit = ({
  el,
  setStructure,
}: {
  el: IStructure;
  setStructure: React.Dispatch<React.SetStateAction<IStructure>>;
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [text, setText] = useState<string>('');
  const [moduleType, setModuleType] = useState<Array<string>>([]);
  const [moduleItem, setModuleItem] = useState<string>('');
  const [checked, setChecked] = useState(el.hidden === 0 ? false : true);

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const handleChangeText = (event: any) => setText(event.target.value);
  const handleChangeModule = (event: any) => setModuleItem(event.target.value);

  return (
    <>
      <Tooltip title={t('syntaxcompiler.editIndex')}>
        <Button
          variant="text"
          startIcon={<SettingsIcon />}
          onClick={() => {
            setText(el.index);
            setModuleItem(el.module);
            setOpen(true);
            getAnalysisModules()
              .then((res) => setModuleType(res.data.data))
              .catch((error) => {
                setNotification({
                  ...notification,
                  loading: true,
                  message: error.response?.data?.messages || error.message,
                  status: 'error',
                });
              });
          }}
          sx={{
            p: '5px',
            lineHeight: '1',
            m: '0 10px',
            minWidth: '10px',
            fontWeight: 600,
          }}
        >
          {el.index}
        </Button>
      </Tooltip>

      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('syntaxcompiler.editIndex')}</DialogTitle>
        {moduleType.length === 0 ? (
          <DialogContent dividers children={t('syntaxcompiler.loading')} />
        ) : (
          <DialogContent dividers={true}>
            <TextField
              label={t('syntaxcompiler.editIndex')}
              value={text}
              onChange={handleChangeText}
              variant="standard"
              sx={{ width: '100%' }}
              autoFocus
              error={validationField(text)}
            />

            <FormControl variant="standard" sx={{ width: '100%', marginTop: '10px' }}>
              <InputLabel children={t('syntaxcompiler.selectModuleType')} />

              <Select value={moduleItem} onChange={handleChangeModule} label={t('syntaxcompiler.selectModuleType')}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {moduleType.map((el: string, index: number) => (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {moduleItem !== 'GROUP' ? (
              <FormControlLabel
                sx={{ mt: 1 }}
                control={<Checkbox checked={checked} onChange={() => setChecked(!checked)} />}
                label={t('syntaxcompiler.hideModule')}
              />
            ) : null}
          </DialogContent>
        )}

        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            color="error"
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Button
            children={t('syntaxcompiler.save')}
            color="success"
            variant="contained"
            disabled={validationField(text)}
            onClick={() => {
              setOpen(false);
              putAnalysisModules(el.analysis, el.id, text, moduleItem, queryRule(moduleItem, checked))
                .then(() => {
                  getSyntAnalysisById(el.analysis).then((res) => setStructure(res.data.structure));
                })
                .catch((error) => {
                  setNotification({
                    ...notification,
                    loading: true,
                    message: error.response?.data?.messages || error.message,
                    status: 'error',
                  });
                });
            }}
          />
        </DialogActions>
      </Dialog>

      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </>
  );
};

export default BtnEdit;
