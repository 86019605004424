import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TablePagination,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Spinner from '../../../../../../../../components/Spinner';
import { IQuestion } from '../..';
import Notification from '../../../../../../../../components/Notification';

const TableQuestion = ({ question, xml, setXml }: { question: IQuestion[]; xml: any; setXml: any }) => {
  const { t } = useTranslation();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleChangePage = (event: any, newPage: number) => setPage(newPage);
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const headCells = [
    { id: 1, label: '#' },
    { id: 2, label: `${t('syntaxcompiler.question')} ${t('syntaxcompiler.swedish')}` },
    { id: 3, label: '' },
  ];
  return (
    <>
      {!question ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table sx={{ margin: '0px 24px', width: '-webkit-fill-available' }}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} sx={{ fontWeight: '600' }} children={headCell.label} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {question.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
                <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{row.id}</TableCell>
                  <TableCell align="left">{row.text_sv}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setXml(xml + `<question>${row.id}</question>`);
                        setNotification({
                          ...notification,
                          loading: true,
                          message: t('syntaxcompiler.questionAdded'),
                          status: 'success',
                        });
                      }}
                      children={t('syntaxcompiler.load')}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Box sx={{ display: 'flex', margin: '0px 24px' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={question.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </TableContainer>
      )}
      {notification.message ? <Notification data={{ notification, setNotification }} /> : ''}
    </>
  );
};

export default TableQuestion;
