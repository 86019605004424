import { Box, Button, TextField, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import RecentEvent from '../../../../components/RecentEvent';
import SideEffectTable from './componets/SideEffectTable';
import Layout from '../../../../layouts/Header';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getRecordsSideEffects, searchSideEffects } from '../../../../api/sideEffects';
import Spinner from '../../../../components/Spinner';
import { hintSearchField } from '../../../../helpers';

interface IDataSideEffects {
  id: number;
  status: number;
  version: number;
  atc_code: string;
  nplid: string;
  side_effect_frequency: string;
  side_effect_fass_id: number;
  phase_20_id: number;
  row: number;
  phase_20_number: number;
  phase_20_text: string;
  fass_text: string;
}

interface IPagination {
  page: number;
  pages: number;
  limit: number;
  countTotal: number;
  countFiltered: number;
}

interface IRecord {
  id: number;
  user: number;
  api: number;
  rel_type: number;
  rel_id: number;
  type: string;
  data: string;
  location: string;
  user_agent: {
    agent: string;
    is_browser: boolean;
    is_mobile: boolean;
    platform: string;
    browser: string;
    version: string;
    mobile: string;
  };
  timestamp: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  description: string;
  test: string;
  locale: string;
}

const SideEffect = () => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const [dataSideEffects, setDataSideEffects] = useState<IDataSideEffects[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [sort, setSort] = useState<Array<string>>([]);
  const [records, setRecords] = useState<IRecord[]>([]);
  const [searchableFields, setSearchableFields] = useState<Array<string>>([]);

  useEffect(() => {
    getRecordsSideEffects().then((res) => setRecords(res.data.data));
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(
      () =>
        searchSideEffects(search, 0, rowsPerPage).then((res) => {
          setDataSideEffects(res.data.data);
          setSearchableFields(res.data.searchableFields);
          const pagination = {
            ...res.data.pagination,
            countTotal: res.data.pagination.countFiltered,
          };
          setPagination(pagination);
          setPage(0);
        }),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  return (
    <Layout>
      <Box sx={{ m: '24px', display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography children={t('administration.sideEffectDb')} variant="h5" />
          <Typography children={t('administration.currentSideEffectDb')} variant="h6" sx={{ m: '24px 0' }} />
          <Tooltip title={hintSearchField(searchableFields)}>
            <TextField
              label={t('administration.search')}
              type="search"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Link to="/side_effects_management/version_page" style={{ textDecoration: 'none', color: 'white' }}>
            <Button variant="contained" children={t('administration.uplModifDbFile')} startIcon={<AddIcon />} />
          </Link>
          <RecentEvent records={records} />
        </Box>
      </Box>

      {!pagination ? (
        <Spinner />
      ) : (
        <SideEffectTable
          data={{
            dataSideEffects,
            setDataSideEffects,
            pagination,
            setPagination,
            page,
            setPage,
            rowsPerPage,
            setRowsPerPage,
            search,
            sort,
            setSort,
          }}
        />
      )}
    </Layout>
  );
};

export default SideEffect;
