import { apiClient } from '../api/axios';

export const getProductRegister = () => apiClient('/registers');
export const syncProductRegister = (syncId: number) => apiClient(`/registers/${syncId}/sync`);
export const getSyncStatusProductRegister = (syncId: string) => apiClient(`/registers/${syncId}/sync-status`);

export const getDrugsById = (drug_id: number) => apiClient(`/drugs/${drug_id}`);
export const searchDrugs = (word: any, page: number, limit: number, sort?: string[]) => {
  const params = {
    search: word,
    page,
    limit,
    sort: [] as string[],
  };
  if (sort && sort.length) {
    params.sort = sort;
  }

  return apiClient.get(`/drugs`, {
    params,
  });
};

export const ascSortDrugs = (created: any) =>
  apiClient('/drugs', {
    params: {
      sort: [`${created}.asc`],
    },
  });
export const descSortDrugs = (created: any) =>
  apiClient('/drugs', {
    params: {
      sort: [`${created}.desc`],
    },
  });

export const getMissingDrugs = () => apiClient('/drugs/missing');

export const searchMissingDrugs = (word: any) =>
  apiClient.get(`/drugs/missing`, {
    params: {
      search: word,
    },
  });
