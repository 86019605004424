import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { updateDataUser } from '../../../../../../../../api/users';
import { InputLabel, Box, TextField, Button } from '@mui/material';
import Notification from '../../../../../../../../components/Notification';

const UserInfo = ({ user }: { user: any }) => {
  const { t } = useTranslation();
  const language = localStorage.getItem('i18nextLng');

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm();

  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });

  const onSubmit = (data: any) => {
    updateDataUser(user.id, { ...data, language })
      .then(() => {
        setNotification({
          ...notification,
          loading: true,
          message: t('administration.userInformationChanged'),
          status: 'success',
        });
      })
      .catch((error) => {
        setNotification({
          ...notification,
          loading: true,
          message: error.response?.data?.messages || error.message,
          status: 'error',
        });
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ '& .MuiTextField-root': { width: '100%' } }}>
      <InputLabel children={t('administration.firstName')} />
      <TextField {...register('firstname', { required: true, value: user.firstname })} variant="standard" />
      <InputLabel children={t('administration.lastName')} />
      <TextField {...register('lastname', { required: true, value: user.lastname })} variant="standard" />
      <InputLabel children={t('access.username')} />
      <TextField
        {...register('username', { required: true, value: user.username })}
        variant="standard"
        helperText={`${t('administration.emailAddress')} ${t('administration.emailAddressUsedWhenLogIn')}`}
      />
      <Button
        type="submit"
        children={t('administration.save')}
        variant="contained"
        color="success"
        sx={{ mt: 3, display: 'flex' }}
        disabled={!isDirty || !isValid}
      />
      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </Box>
  );
};

export default UserInfo;
