import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { searchDataApiIntegration } from '../../../../api/apiIntegration';

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Switch,
  FormControlLabel,
  Box,
  TableSortLabel,
} from '@mui/material';
import Spinner from '../../../../components/Spinner';
import BtnActivateIntegration from './components/BtnActivateIntegration';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';

interface IData {
  id: number;
  status: number;
  product_register_id: number;
  public_key: string;
  request_key: string;
  request_key_iv: string;
  lang_codes_id: number;
  licenses_id: number;
  regionId: number;
  syntax_analyse_id: number;
  kidney_function: number;
  kidney_function_name: string;
  syntax_analysis_name: string;
  license_number: number;
  license_type_name: string;
  customer_id: number;
  customer_number: number;
  customer_company: string;
  request_key_decoded: string;
  callback_url: string;
}
[];

const ApiIntegrationTable = ({ data }: any) => {
  const {
    apiData,
    setApiData,
    pagination,
    setPagination,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    search,
    checked,
    setChecked,
    sort,
    setSort,
  } = data;
  const { t } = useTranslation();

  const [sortFlag, setSortflag] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string>('');

  const headCells = [
    { id: '1', label: t('apiIntegration.thPublicKey'), sortField: 'public_key' },
    { id: '13', label: 'Callback url', sortField: '' },
    { id: '2', label: t('apiIntegration.thCustomerNumber'), sortField: 'product_register_id' },
    { id: '3', label: t('apiIntegration.thBusiness'), sortField: '' },
    { id: '4', label: t('apiIntegration.thKidneyFuncCalc'), sortField: 'kidney_function' },
    { id: '5', label: t('apiIntegration.thSyntaxAnalysis'), sortField: 'syntax_analyse_id' },
    { id: '6', label: t('apiIntegration.thRegionId'), sortField: 'regionId' },
    { id: '7', label: t('apiIntegration.thLicenseNum'), sortField: 'licenses_id' },
    { id: '8', label: t('apiIntegration.thLicenseType'), sortField: '' },
    { id: '9', label: t('apiIntegration.thRequestKey'), sortField: '' },
    { id: '10', label: `${t('apiIntegration.thActivated')} (handshake)`, sortField: '' },
    { id: '11', label: '' },
    { id: '12', label: '' },
  ];

  const handleChangePage = (event: any, newPage: number) => {
    searchDataApiIntegration(search, newPage + 1, rowsPerPage, [1], checked, sort).then((res) =>
      setApiData(res.data.data)
    );

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    searchDataApiIntegration(search, 1, event.target.value, [1], checked, sort).then((res) =>
      setApiData(res.data.data)
    );
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    searchDataApiIntegration(search, 1, rowsPerPage, [1], event.target.checked, sort).then((res) => {
      setApiData(res.data.data);
      const pagination = {
        ...res.data.pagination,
        countTotal: res.data.pagination.countFiltered,
      };
      setPagination(pagination);
      setPage(0);
    });
  };

  const handleSort = (sortField?: string) => {
    let sorting;
    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setSort(sorting);
      setOrderBy(sortField);
    }

    searchDataApiIntegration(search, 1, rowsPerPage, [1], checked, sorting).then((res) => {
      setApiData(res.data.data);
    });
    setPage(0);
  };

  return (
    <>
      {!data ? (
        <Spinner />
      ) : (
        <Box sx={{ m: '0 24px' }}>
          <TableContainer sx={{ width: '100%' }}>
            <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                      {!headCell.sortField ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          IconComponent={ArrowDropUpIcon}
                          active={headCell.sortField === orderBy}
                          direction={sortFlag ? 'asc' : 'desc'}
                          onClick={() => {
                            handleSort(headCell.sortField);
                            setSortflag(!sortFlag);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {apiData.map((row: IData) => (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      'backgroundColor': !row.status ? 'rgb(253, 237, 237)' : '',
                    }}
                  >
                    <TableCell sx={{ wordWrap: 'break-word' }} align="left">
                      {row.public_key}
                    </TableCell>
                    <TableCell sx={{ wordWrap: 'break-word' }} align="left">
                      {row.callback_url ? row.callback_url : 'No'}
                    </TableCell>
                    <TableCell align="left">{row.customer_number}</TableCell>
                    <TableCell align="left">{row.customer_company}</TableCell>
                    <TableCell align="left">{row.kidney_function_name}</TableCell>
                    <TableCell sx={{ wordWrap: 'break-word' }} align="left">
                      {row.syntax_analysis_name}
                    </TableCell>
                    <TableCell align="left">{row.regionId}</TableCell>
                    <TableCell align="left">{row.license_number}</TableCell>
                    <TableCell align="left">{row.license_type_name}</TableCell>
                    <TableCell sx={{ wordWrap: 'break-word' }} align="left">
                      {row.request_key_decoded}
                    </TableCell>
                    <TableCell align="left">
                      {row.status ? (
                        <BtnActivateIntegration integration={row} setApiData={setApiData} data={data} />
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <NavLink to={`/integration/edit/${row.id}`} style={{ textDecoration: 'none', color: 'white' }}>
                        <Button variant="contained">
                          <EditIcon />{' '}
                        </Button>
                      </NavLink>
                    </TableCell>
                    <TableCell align="center">
                      <NavLink
                        to={`/customers/card/${row.customer_id}`}
                        style={{ textDecoration: 'none', color: 'white' }}
                      >
                        <Button variant="contained">
                          <PersonIcon />
                        </Button>
                      </NavLink>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex', m: '0px 24px' }}>
              <FormControlLabel
                control={<Switch size="small" checked={checked} onChange={handleChange} />}
                label={t('apiIntegration.showDeleted')}
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={pagination.countTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default ApiIntegrationTable;
