import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { activateApiIntegrationById, searchDataApiIntegration } from '../../../../../../api/apiIntegration';

import { Button, Snackbar } from '@mui/material';
import { showError } from '../../../../../../helpers';

interface IIntegration {
  id: number;
  status: number;
  product_register_id: number;
  public_key: string;
  request_key: string;
  request_key_iv: string;
  lang_codes_id: number;
  licenses_id: number;
  regionId: number;
  syntax_analyse_id: number;
  kidney_function: number;
  kidney_function_name: string;
  syntax_analysis_name: string;
  license_number: number;
  license_type_name: string;
  customer_id: number;
  customer_number: number;
  customer_company: string;
  request_key_decoded: string;
}

const BtnActivateIntegration = ({
  integration,
  setApiData,
  data,
}: {
  integration: IIntegration;
  setApiData: any;
  data: any;
}) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openError, setOpenError] = useState<boolean>(false);

  useEffect(() => {}, [integration.request_key_decoded]);

  const handleActivateIntegration = (integration: any) => {
    activateApiIntegrationById(integration.id)
      .then(() => {
        searchDataApiIntegration(data.search, 0, data.rowsPerPage, [1], data.checked, data.sort).then((res) => {
          setApiData(res.data.data);
          const pagination = {
            ...res.data.pagination,
            countTotal: res.data.pagination.countFiltered,
          };
          data.setPagination(pagination);
          data.setPage(0);
        });
      })
      .catch((error) => {
        setErrorMessage(error.response.data.messages);
        setOpenError(true);
      });
  };

  return (
    <>
      <Button
        disabled={integration.request_key_decoded || integration.license_type_name !== 'Unlimited' ? true : false}
        children={t('apiIntegration.activate')}
        onClick={() => handleActivateIntegration(integration)}
        variant="contained"
      />
      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={3000}
          onClose={() => setOpenError(false)}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </>
  );
};

export default BtnActivateIntegration;
