import { useTranslation } from 'react-i18next';
import GoBackButton from '../../../../components/GoBackButton';
import FormData from '../../../../components/FormData';
import { Typography } from '@mui/material';
import Layout from '../../../../layouts/Header';

const AddButton = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <GoBackButton />
      <Typography children={t('customers.addNewCustomer')} variant="h5" sx={{ m: '0px 24px', p: '8px' }} />
      <FormData dataUser={undefined} />
    </Layout>
  );
};

export default AddButton;
