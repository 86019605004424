import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { apiClient } from './api/axios';

import Access from './pages/Access';
import Dashboard from './pages/Dashboard';
import Diagregister from './pages/Administration/components/Diagregister';
import SideEffect from './pages/Administration/components/SideEffect';
import Version from './pages/Administration/components/SideEffect/componets/Version';
import Users from './pages/Administration/components/Users';
import UserCard from './pages/Administration/components/Users/components/UserCard';
import ApiIntegration from './pages/ApiIntegration';
import NewIntegration from './pages/ApiIntegration/components/NewIntegration';
import CustomerRegister from './pages/CustomerRegister';
import AddButton from './pages/CustomerRegister/componets/AddButton';
import LicenseCard from './pages/CustomerRegister/componets/Card/components/LicenseCard';
import NewLicense from './pages/CustomerRegister/componets/Card/components/NewLicense';
import APICalls from './pages/Documentation/components/APICalls';
import Overview from './pages/Documentation/components/Overview';
import RequestErrorMessages from './pages/Documentation/components/RequestErrorMessages';
import ProductRegister from './pages/ProductRegister';
import CardDrugs from './pages/ProductRegister/components/CardDrugs';
import MissingDrugs from './pages/ProductRegister/components/MissingDrugs';
import Algorithm from './pages/Syntax/components/Algorithms';
import Analysis from './pages/Syntax/components/Analysis';
import Questions from './pages/Syntax/components/Questions';
import Card from './pages/CustomerRegister/componets/Card';
import Settings from './pages/Administration/components/Settings';
import NotFound from './pages/NotFound';
import ResetForm from './pages/Access/componets/ResetForm';
import EditIntegration from './pages/ApiIntegration/components/TableIntegration/components/EditIntegration';
import EditQuestion from './pages/Syntax/components/Questions/components/EditQuestion';
import TestAnalysis from './pages/Tests/TestAnalysis';
import TestAlgorithms from './pages/Tests/TestAlgorithms';
import VisualizationComponent from './pages/Tests/VisualizationComponent';
import HistoryOfRequest from './pages/Administration/components/HistoryOfRequest';
import Module from './pages/Syntax/components/Modules';
import NewModule from './pages/Syntax/components/Modules/components/NewModule';
import AddUser from './pages/Administration/components/Users/components/AddUser';

interface IUser {
  id: number;
  status: number;
  firstname: string;
  lastname: string;
  username: string;
  authentication_fail: number;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  two_step_activated: number;
  language: string;
  activated: boolean;
  is_password_reset: boolean;
}

const ProtectedRoute = ({ children }: any) => {
  const navigation = useNavigate();
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const {
          data: { user },
        } = await apiClient(`/access/check-auth`);
        setUser(user);
      } catch (error: any) {
        if (error.response.status === 401) {
          navigation('/access/login');
        }
      }
    };

    checkAuth();
  }, []);

  if (user === null) {
    return null;
  }

  return children;
};

const App = () => (
  <Routes>
    <Route path="access/login" element={<Access />} />
    <Route path="access/reset/verify" element={<ResetForm />} />
    <Route
      path="/"
      element={
        <ProtectedRoute>
          <Dashboard />
        </ProtectedRoute>
      }
    />
    <Route
      path="customers"
      element={
        <ProtectedRoute>
          <CustomerRegister />
        </ProtectedRoute>
      }
    />
    <Route
      path="customers/add"
      element={
        <ProtectedRoute>
          <AddButton />
        </ProtectedRoute>
      }
    />
    <Route
      path="customers/card/:id"
      element={
        <ProtectedRoute>
          <Card />
        </ProtectedRoute>
      }
    />
    <Route
      path="licenses/add_new/:id"
      element={
        <ProtectedRoute>
          <NewLicense />
        </ProtectedRoute>
      }
    />
    <Route
      path="licenses/card/:licenseId"
      element={
        <ProtectedRoute>
          <LicenseCard />{' '}
        </ProtectedRoute>
      }
    />
    <Route
      path="integration/add/"
      element={
        <ProtectedRoute>
          <NewIntegration />
        </ProtectedRoute>
      }
    />
    <Route
      path="integration/add/:customerId"
      element={
        <ProtectedRoute>
          <NewIntegration />
        </ProtectedRoute>
      }
    />
    <Route
      path="integration/edit/:integrationId"
      element={
        <ProtectedRoute>
          <EditIntegration />
        </ProtectedRoute>
      }
    />
    <Route
      path="integration/add/:customerId/:licenseId"
      element={
        <ProtectedRoute>
          <NewIntegration />
        </ProtectedRoute>
      }
    />
    <Route
      path="integration"
      element={
        <ProtectedRoute>
          <ApiIntegration />
        </ProtectedRoute>
      }
    />
    <Route
      path="product_list"
      element={
        <ProtectedRoute>
          <ProductRegister />
        </ProtectedRoute>
      }
    />
    <Route
      path="product_list/missing_drugs"
      element={
        <ProtectedRoute>
          <MissingDrugs />
        </ProtectedRoute>
      }
    />
    <Route
      path="product_list/card/:id"
      element={
        <ProtectedRoute>
          <CardDrugs />
        </ProtectedRoute>
      }
    />
    <Route
      path="tests/analysis"
      element={
        <ProtectedRoute>
          <TestAnalysis />
        </ProtectedRoute>
      }
    />
    <Route
      path="tests/algorithms"
      element={
        <ProtectedRoute>
          <TestAlgorithms />
        </ProtectedRoute>
      }
    />
    <Route
      path="tests/visualization-component"
      element={
        <ProtectedRoute>
          <VisualizationComponent />
        </ProtectedRoute>
      }
    />
    <Route
      path="syntaxcompiler/analysis"
      element={
        <ProtectedRoute>
          <Analysis />
        </ProtectedRoute>
      }
    />
    <Route
      path="syntaxcompiler/analysis/:id"
      element={
        <ProtectedRoute>
          <Analysis />
        </ProtectedRoute>
      }
    />
    <Route
      path="syntaxcompiler/algorithms"
      element={
        <ProtectedRoute>
          <Algorithm />
        </ProtectedRoute>
      }
    />
    <Route
      path="syntaxcompiler/algorithms/:id"
      element={
        <ProtectedRoute>
          <Algorithm />
        </ProtectedRoute>
      }
    />
    <Route
      path="syntaxcompiler/questions"
      element={
        <ProtectedRoute>
          <Questions />
        </ProtectedRoute>
      }
    />
    <Route
      path="syntaxcompiler/questions/:id"
      element={
        <ProtectedRoute>
          <EditQuestion />
        </ProtectedRoute>
      }
    />{' '}
    <Route
      path="syntaxcompiler/modules"
      element={
        <ProtectedRoute>
          <Module />
        </ProtectedRoute>
      }
    />{' '}
    <Route path="syntaxcompiler/modules/add" element={<ProtectedRoute>{<NewModule />}</ProtectedRoute>} />
    <Route path="syntaxcompiler/modules/:id" element={<ProtectedRoute>{<NewModule />}</ProtectedRoute>} />
    <Route
      path="settings"
      element={
        <ProtectedRoute>
          <Settings />
        </ProtectedRoute>
      }
    />
    <Route
      path="users"
      element={
        <ProtectedRoute>
          <Users />
        </ProtectedRoute>
      }
    />
    <Route
      path="users/add"
      element={
        <ProtectedRoute>
          <AddUser />
        </ProtectedRoute>
      }
    />
    <Route
      path="users/card/:id"
      element={
        <ProtectedRoute>
          <UserCard />
        </ProtectedRoute>
      }
    />
    <Route
      path="side_effects_management"
      element={
        <ProtectedRoute>
          <SideEffect />
        </ProtectedRoute>
      }
    />
    <Route
      path="history"
      element={
        <ProtectedRoute>
          <HistoryOfRequest />
        </ProtectedRoute>
      }
    />
    <Route
      path="side_effects_management/version_page"
      element={
        <ProtectedRoute>
          <Version />
        </ProtectedRoute>
      }
    />
    <Route
      path="diagregister"
      element={
        <ProtectedRoute>
          <Diagregister />
        </ProtectedRoute>
      }
    />
    <Route
      path="*"
      element={
        <ProtectedRoute>
          <NotFound />
        </ProtectedRoute>
      }
    />
    <Route path="documentation/overview" element={<Overview />} />
    <Route path="documentation/api_calls_doc" element={<APICalls />} />
    <Route path="documentation/request_error_messages" element={<RequestErrorMessages />} />
  </Routes>
);

export default App;
