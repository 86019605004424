import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { searchSideEffects } from '../../../../../../api/sideEffects';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const SideEffectTable = ({ data }: any) => {
  const {
    dataSideEffects,
    setDataSideEffects,
    pagination,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    search,
    sort,
    setSort,
  } = data;
  const { t } = useTranslation();

  const [sortFlag, setSortflag] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string>('');

  const handleChangePage = (event: any, newPage: number) => {
    searchSideEffects(search, newPage + 1, rowsPerPage).then((res) => setDataSideEffects(res.data.data));
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    searchSideEffects(search, 1, event.target.value).then((res) => setDataSideEffects(res.data.data));
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const headCells = [
    { id: '1', label: t('administration.thAtcCode'), sortField: 'atc_code' },
    { id: '2', label: t('administration.thNplid'), sortField: 'nplid' },
    { id: '3', label: t('administration.thFrequency'), sortField: 'side_effect_frequency' },
    { id: '4', label: t('administration.thFass') },
    { id: '5', label: t('administration.thPh20Num'), sortField: 'phase_20_number' },
    { id: '6', label: t('administration.thPh20Text') },
  ];

  const handleSort = (sortField?: string) => {
    let sorting;
    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setSort(sorting);
      setOrderBy(sortField);
    }

    searchSideEffects(search, 1, rowsPerPage, sort).then((res) => {
      setDataSideEffects(res.data.data);
      setSortflag(!sortFlag);
    });
    setPage(0);
  };

  return (
    <Box sx={{ m: '0 24px' }}>
      <TableContainer>
        <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                  {!headCell.sortField ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      IconComponent={ArrowDropUpIcon}
                      active={headCell.sortField === orderBy}
                      direction={sortFlag ? 'asc' : 'desc'}
                      onClick={() => {
                        handleSort(headCell.sortField);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSideEffects.map((row: any) => (
              <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align="left">{row.atc_code}</TableCell>
                <TableCell align="left">{row.nplid}</TableCell>
                <TableCell align="left">{row.side_effect_frequency}</TableCell>
                <TableCell align="left">{row.fass_text}</TableCell>
                <TableCell align="left">{row.phase_20_number}</TableCell>
                <TableCell align="left">{row.phase_20_text}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={{ display: 'flex', margin: '0px 24px' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={pagination.countTotal}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </TableContainer>
    </Box>
  );
};

export default SideEffectTable;
