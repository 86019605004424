import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination, Box } from '@mui/material';
import { formatDate } from '../../../../../../helpers';
import CallBtn from '../CallBtn';

const DragTable = ({ missingDrugs }: any) => {
  const { t } = useTranslation();
  const headCells = [
    { id: '1', label: '' },
    { id: '2', label: t('productlist.apiIntegration') },
    { id: '3', label: t('productlist.specNplId') },
    { id: '4', label: t('productlist.specNplPackId') },
    { id: '5', label: t('productlist.dateOfCall') },
    { id: '6', label: '' },
  ];

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  return (
    <TableContainer sx={{ mt: '24px' }}>
      <Table sx={{ minWidth: 650, width: '-webkit-fill-available' }}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell children={headCell.label} key={headCell.id} align={'left'} sx={{ fontWeight: '600' }} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {missingDrugs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
            <TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell align="left">{''}</TableCell>
              <TableCell align="left">{row.integration}</TableCell>
              <TableCell align="left">{row.nplid}</TableCell>
              <TableCell align="left">{row.nplpackid}</TableCell>
              <TableCell align="left">{formatDate(row.created.date)}</TableCell>
              <TableCell>
                <CallBtn row={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box sx={{ display: 'flex' }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={missingDrugs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </TableContainer>
  );
};

export default DragTable;
