import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getStatus } from '../../../../helpers';
import Layout from '../../../../layouts/Header';
import Spinner from '../../../../components/Spinner';
import FormData from '../../../../components/FormData';
import GoBackButton from '../../../../components/GoBackButton';
import DialogButton from './components/DialogButton';
import LicenseTable from './components/LicenseTable';
import IntegrationTable from './components/IntegrationTable';
import { Typography, Box, Divider, Alert, Snackbar } from '@mui/material';
import store from '../../../../store/';
import { observer } from 'mobx-react-lite';

const Card = observer(() => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { getCustomerData, getCustomerRecord, isLoading, customerData, notification, closeNotification, refresh } =
    store.customerRegister;

  useEffect(() => {
    getCustomerData(id);
    getCustomerRecord(id);
    refresh();
  }, []);

  return (
    <Layout>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {' '}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Box>
              <GoBackButton />
              <Typography children={customerData?.company} variant="h5" sx={{ m: '0px 24px', p: '8px 0' }} />
              <Box children={getStatus(customerData?.status)} sx={{ m: '0px 24px', p: '8px 0' }} />
              <Typography children={t('customers.businessInfromation')} variant="h5" sx={{ m: '24px' }} />
            </Box>
            <Box>
              <DialogButton />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <FormData dataUser={customerData} />
          </Box>
          <Divider />
          <LicenseTable />
          <Divider />
          <IntegrationTable />
        </>
      )}
      {!notification.isLoading ? (
        ''
      ) : (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={notification.isLoading}
          autoHideDuration={5000}
          onClose={() => closeNotification()}
        >
          <Alert variant="filled" onClose={() => closeNotification()} severity={notification.severity}>
            {typeof notification.message === 'string'
              ? t(notification.message)
              : Object.values(notification.message).map((el: any) => ` ${el}`)}
          </Alert>
        </Snackbar>
      )}
    </Layout>
  );
});

export default Card;
