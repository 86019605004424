import { Box, CircularProgress } from '@mui/material';

const Spinner = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <CircularProgress />
  </Box>
);

export default Spinner;
