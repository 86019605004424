import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IData } from '../..';
import { logParse } from '../../../../../../api/logs';
import { postTestVisual } from '../../../../../../api/testAnalysis';
import { showError } from '../../../../../../helpers';

function BtnVisualize({ data }: { data: IData }) {
  const { t } = useTranslation();

  const [open, setOpen] = useState<boolean>(false);
  const [html, setHtml] = useState<any>();
  const [backDrop, setBackDrop] = useState(false);

  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <>
      <Button
        variant="contained"
        size="small"
        disabled={data.status === 'failed'}
        onClick={() => {
          setBackDrop(true);
          logParse(data.id)
            .then((res) => postTestVisual(res.data))
            .then((res) => {
              setOpen(true);
              setHtml(res.data);
              setBackDrop(false);
            })
            .catch((error) => {
              setErrorMessage(error.response.data.messages);
              setOpenError(true);
              setBackDrop(false);
            });
        }}
      >
        {t('administration.show')}
      </Button>
      <Dialog fullScreen={true} maxWidth={'xl'} open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Box sx={{ height: '100%' }}>
            <iframe srcDoc={html} width="100%" height="90%"></iframe>
            <DialogActions>
              <Button children={t('tests.cancel')} variant="contained" color="error" onClick={() => setOpen(false)} />
            </DialogActions>
          </Box>
        </DialogContent>
      </Dialog>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {errorMessage ? (
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openError}
          autoHideDuration={5000}
          onClose={() => setOpenError(false)}
        >
          {showError(errorMessage)}
        </Snackbar>
      ) : (
        ''
      )}
    </>
  );
}

export default BtnVisualize;
