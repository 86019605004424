import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiClient } from '../../../../api/axios';
import parse from 'html-react-parser';

import '../../styles.css';
import DocumentationLayout from '../..';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Spinner from '../../../../components/Spinner';

const APICalls = () => {
  const { t } = useTranslation();

  const [apiItems, setApiItems] = useState<Array<string>>([]);
  const [apiItemContent, setApiItemContent] = useState<string>('');
  const [apiSelect, setApiSelect] = useState<string>('');

  const handleChangeApi = (event: any) => setApiSelect(event.target.value);

  useEffect(() => {
    const getApiItem = async () => {
      try {
        const {
          data: { endpoints },
        } = await apiClient.get('/documentation/api');
        setApiItems(endpoints);
      } catch (error) {
        throw error;
      }
    };

    getApiItem();
  }, []);

  useEffect(() => {
    const getContent = async () => {
      if (apiSelect) {
        try {
          const { data } = await apiClient.get(`documentation/api/endpoint/${apiSelect}`);
          setApiItemContent(data);
        } catch (error) {
          throw error;
        }
      }
    };

    getContent();
  }, [apiSelect]);

  return (
    <DocumentationLayout>
      <Box sx={{ m: '24px' }}>
        <Typography children={t('documentation.apiCalls')} variant="h5" sx={{ mb: '16px' }} />
        {apiItems.length > 0 ? (
          <FormControl variant="standard" fullWidth>
            <InputLabel children={t('documentation.chooseFeature')} />
            <Select value={apiSelect} onChange={handleChangeApi}>
              <MenuItem value="" children={t('documentation.chooseFeature')} />
              {apiItems.map((el) => (
                <MenuItem key={el} value={el} children={el} />
              ))}
            </Select>
          </FormControl>
        ) : (
          <Spinner />
        )}
        {apiSelect ? (
          <Box sx={{ width: 'inherit' }} className={'container'}>
            {apiItemContent ? parse(apiItemContent) : <Spinner />}
          </Box>
        ) : (
          ''
        )}
      </Box>
    </DocumentationLayout>
  );
};

export default APICalls;
