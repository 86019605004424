import axios from 'axios';

export const apiClient = axios.create({
  baseURL: '/admin',
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      window.location.href = '/access/login';
    }

    return Promise.reject(error);
  }
);
