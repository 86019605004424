import {
  Alert,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Layout from '../../../../layouts/Header';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BtnVisualize from './components/BtnVisualize';
import { useState } from 'react';
import { getLogs } from '../../../../api/logs';
import BtnDrugIdList from './components/BtnDrugIdList';
import BtnDownload from './components/BtnDownload';
import Spinner from '../../../../components/Spinner';
import { formatDate, formatDateYMD } from '../../../../helpers';
import Search from './components/Search';
import Filter from './components/Filter';

interface IPagination {
  page: number;
  pages: number;
  limit: number;
  countTotal: number;
  countFiltered: number;
}

export interface IData {
  id: number;
  endpoint: string;
  request_key: string;
  status: string;
  created_at: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  request: string;
  parsedRequestData: {
    drugids: string[];
    questions: string[];
    symtom: string[];
  };
}

const HistoryOfRequest = () => {
  const { t } = useTranslation();

  const [logs, setLogs] = useState<IData[]>([]);

  const [sortFlag, setSortflag] = useState<boolean>(false);
  const [orderBy, setOrderBy] = useState<string>('');

  const [pagination, setPagination] = useState<IPagination>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [filter, setFilter] = useState({
    date_from: '2023-01-01',
    date_to: formatDateYMD(new Date()),
  });

  const handleChangePage = (event: any, newPage: number) => {
    getLogs(newPage + 1, rowsPerPage, [''], '', '', filter).then((res) => {
      const pagination = {
        ...res.data.pagination,
        countTotal: res.data.pagination.countFiltered,
      };
      setPagination(pagination);
      setLogs(res.data.data);
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    getLogs(1, event.target.value, [''], '', '', filter).then((res) => setLogs(res.data.data));
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleSort = (sortField?: string) => {
    let sorting;

    if (sortField) {
      sorting = sortFlag ? [`${sortField}.asc`] : [`${sortField}.desc`];
      setOrderBy(sortField);
    }

    getLogs(0, rowsPerPage, sorting, '', '', filter).then((res) => {
      setLogs(res.data.data);
      setSortflag(!sortFlag);
    });
    setPage(0);
  };

  const headCells = [
    { id: '0', label: 'ID', sortField: '' },
    { id: '1', label: t('administration.requestStatus'), sortField: 'status' },
    { id: '2', label: t('administration.publicKey'), sortField: 'request_key' },
    { id: '3', label: t('administration.date'), sortField: 'created_at' },
    { id: '4', label: t('administration.visualize'), sortField: '' },
    { id: '5', label: t('administration.endpoint'), sortField: '' },
    { id: '6', label: '', sortField: '' },
    { id: '7', label: '', sortField: '' },
  ];

  const status = (status: string): JSX.Element =>
    status === 'success' ? (
      <Alert children={t('administration.success')} severity="success" />
    ) : (
      <Alert children={t('administration.failed')} severity="error" />
    );

  return (
    <Layout>
      <Box sx={{ m: '24px' }}>
        <Typography children={t('menu.history')} variant="h5" />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Search
            page={page}
            rowsPerPage={rowsPerPage}
            setLogs={setLogs}
            setPage={setPage}
            setPagination={setPagination}
          />
          <Filter
            setLogs={setLogs}
            setPagination={setPagination}
            filter={filter}
            setFilter={setFilter}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
          />
        </Box>
      </Box>
      {pagination ? (
        <Box sx={{ m: '24px' }}>
          <TableContainer>
            <Table sx={{ tableLayout: 'auto', width: '100%' }}>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={'left'} sx={{ fontWeight: '600' }}>
                      {!headCell.sortField ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          IconComponent={ArrowDropUpIcon}
                          active={headCell.sortField === orderBy}
                          direction={sortFlag ? 'asc' : 'desc'}
                          onClick={() => {
                            handleSort(headCell.sortField);
                            setSortflag(!sortFlag);
                          }}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {logs?.map((row: IData) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left">{ status(row.status) }</TableCell>
                    <TableCell align="left" sx={{ wordWrap: 'break-word' }}>
                      {row.request_key}
                    </TableCell>
                    <TableCell align="left">{formatDate(row.created_at.date)}</TableCell>
                    <TableCell>
                      <BtnVisualize data={row} />
                    </TableCell>
                    <TableCell align="left">{row.endpoint}</TableCell>
                    <TableCell align="left">
                      <BtnDrugIdList data={row} />
                    </TableCell>
                    <TableCell>
                      <BtnDownload data={row} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box sx={{ display: 'flex' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={pagination.countTotal}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </TableContainer>
        </Box>
      ) : (
        <Spinner />
      )}
    </Layout>
  );
};

export default HistoryOfRequest;
