import { apiClient } from '../api/axios';

export const getApiIntegration = (status: number) =>
  apiClient('/integrations', {
    params: {
      status: [status],
    },
  });
export const getAllApiIntegration = () => apiClient('/integrations');

export const getApiIntegrationById = (customerId: any, word: any, page: number, limit: number, sort?: string[]) => {
  const params = {
    customer_id: customerId,
    search: word,
    page,
    limit,
    status: [1] as number[],
    sort: [] as string[],
  };
  if (sort && sort.length) {
    params.sort = sort;
  }

  return apiClient('/integrations', {
    params,
  });
};

export const getIntegrationById = (integrationId: number | string) => apiClient(`/integrations/${integrationId}`);
export const putIntegrationById = (integrationId: number, data: any) =>
  apiClient.put(`/integrations/${integrationId}`, data);

export const postApiIntegration = (data: any) => apiClient.post('/integrations', data);

export const searchDataApiIntegration = (
  word: any,
  page: number,
  limit: number,
  status: number[],
  showAll?: boolean,
  sort?: string[]
) => {
  const params = {
    search: word,
    page,
    limit,
    status: [] as number[],
    sort: [] as string[],
  };
  if (status?.length) {
    params.status = status;
  }

  if (showAll) {
    params.status = [];
  }

  if (sort && sort.length) {
    params.sort = sort;
  }

  return apiClient.get(`/integrations`, {
    params,
  });
};

export const deleteApiIntegrationById = (integrationId: any) => apiClient.delete(`/integrations/${integrationId}`);

export const activateApiIntegrationById = (integrationId: any) =>
  apiClient(`/integrations/${integrationId}/activate`, {
    params: {
      id: integrationId,
    },
  });

export const getRegion = () => apiClient('/datasource/regions');

export const getNewIntegrationDataSet = () => apiClient(`/integrations/new-integration-dataset`);
