import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generateTwoStepCode, getUsersById, postGenerateTwoStepCode } from '../../../../../../../../api/users';

import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Box, TextField, Typography } from '@mui/material';
import Notification from '../../../../../../../../components/Notification';

interface ITwoStep {
  secret: string;
  secret_formatted: string;
  qrImgDataUri: string;
  user: {
    id: number;
    status: number;
    firstname: string;
    lastname: string;
    username: string;
    authentication_fail: number;
    created: {
      date: string;
      timezone_type: number;
      timezone: string;
    };
    two_step_activated: number;
    language: string;
    activated: boolean;
    is_password_reset: boolean;
  };
  metrics?: {
    total_execution: {
      start: number;
      end: number;
      duration: number;
    };
    bootstrap: {
      start: number;
      end: number;
      duration: number;
    };
    routing: {
      start: number;
      end: number;
      duration: number;
    };
    before_filters: {
      start: number;
      end: number;
      duration: number;
    };
    controller: {
      start: number;
      end: number;
      duration: number;
    };
    controller_constructor: {
      start: number;
      end: number;
      duration: number;
    };
    after_filters: {
      start: number;
      end: number;
      duration: number;
    };
  };
}

const TwoFactorAuth = ({ userId, setUser }: { userId: number; setUser: any }) => {
  const { t } = useTranslation();

  const [twoStep, setTwoStep] = useState<ITwoStep>();
  const [open, setOpen] = useState<boolean>(false);
  const [googleCode, setGoogleCode] = useState<string>('');
  const [notification, setNotification] = useState({
    loading: false,
    message: '',
    status: 'error',
  });
  const handleChangeCode = (event: any) => setGoogleCode(event.target.value);

  return (
    <>
      <Button
        children={t('administration.enableTwoFactorAuth')}
        variant="contained"
        color="success"
        sx={{ mt: 1 }}
        onClick={() => {
          generateTwoStepCode(userId)
            .then((res) => {
              setTwoStep(res.data);
            })
            .catch((error) => {
              setNotification({
                ...notification,
                loading: true,
                message: error.response?.data?.messages || error.message,
                status: 'error',
              });
            });
          setOpen(true);
        }}
      />
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <DialogTitle children={t('administration.enableTwoStepAuth')} />
        <DialogContent dividers>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography children={t('administration.scanQrCode')} />
            <img src={twoStep?.qrImgDataUri} />
            <Typography children={t('administration.responseCode')} />
            <TextField variant="standard" value={googleCode} autoFocus onChange={handleChangeCode} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            children={t('administration.cancel')}
            variant="contained"
            color="error"
            onClick={() => {
              setOpen(false);
              setGoogleCode('');
            }}
          />
          <Button
            children={t('administration.confirmTwoStepAuth')}
            variant="contained"
            color="success"
            onClick={() => {
              postGenerateTwoStepCode(userId, twoStep ? twoStep.secret : '', googleCode)
                .then(() => {
                  getUsersById(userId).then((res) => {
                    setUser(res.data.data[0]);
                  });
                  setOpen(false);
                })
                .catch((error) => {
                  setNotification({
                    ...notification,
                    loading: true,
                    message: error.response?.data?.messages || error.message,
                    status: 'error',
                  });
                });
            }}
          />
        </DialogActions>
      </Dialog>
      {notification.message ? <Notification data={{ notification, setNotification }} /> : null}
    </>
  );
};

export default TwoFactorAuth;
