import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { getSyntaxTest, getSyntaxTestData, postSyntaxTestData } from '../../../api/testAnalysis';

import Layout from '../../../layouts/Header';
import Spinner from '../../../components/Spinner';
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Typography,
} from '@mui/material';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import SendIcon from '@mui/icons-material/Send';
import { showError } from '../../../helpers';
import './index.css';

interface ISyntaxAnalysis {
  id: number;
  number: number;
  status: number;
  register: number;
  language: number;
  title: string;
  created: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  updated: {
    date: string;
    timezone_type: number;
    timezone: string;
  };
  created_by: number;
  updated_by?: number;
}

interface IOptions {
  kidney: number;
  side: number;
  double: number;
  interaction: number;
  recommendation: number;
  analyse: number;
  regionId: number;
  kidney_function: number;
}

interface IRegions {
  regionId: number;
  regionName: string;
}

interface IResponse {
  data: object;
  xml: string;
}

enum ResponseFormat {
  xml = 'XML',
  json = 'JSON',
}

const TestAnalysis = () => {
  const { t } = useTranslation();
  const { control, handleSubmit } = useForm();

  const [kidneyFunctions] = useState([
    { id: 1, name: 'Cockcroft-Gault' },
    { id: 2, name: 'Lund-Malmö, reviderad' },
  ]);
  const [syntaxAnalysis, setSyntaxAnalysis] = useState<ISyntaxAnalysis[]>([]);
  const [regions, setRegions] = useState<IRegions[]>([]);
  const [xmlData, setxmlData] = useState<string>('');
  const [response, setResponse] = useState<IResponse|null>(null);
  const [showResponseData, setShowResponseData] = useState<boolean>(true);
  const [responseShowFormat, setResponseShowFormat] = useState<ResponseFormat>(ResponseFormat.xml);
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<IOptions>();
  const [openError, setOpenError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [warnings, setWarnings] = useState();

  useEffect(() => {
    getSyntaxTestData().then((res) => {
      setOptions(res.data.options);
      setSyntaxAnalysis(res.data.analyses);
      setRegions(res.data.regions);
      setxmlData(res.data.data);
    });
  }, []);

  const onSubmit = (data: any) => {
    postSyntaxTestData(data)
      .then((res) => {
        setWarnings(res.data.test_input_data);

        setShowResponseData(false);
        getSyntaxTest()
          .then((res) => {
            setResponse(res.data);
          })
          .catch((error) => {
            setErrorMessage(error.message || error.response.data.messages);
            setOpenError(true);
            setShowResponseData(true);
          });
      })
      .catch((error: any) => {
        setShowResponseData(true);
        setErrorMessage(error.response ? error.response.data?.messages : error.message);
        setOpenError(true);
      });
  };

  return (
    <Layout>
      <Typography children={t('tests.testAnalysis')} variant="h5" sx={{ m: '24px' }} />
      {!options ? (
        <Spinner />
      ) : (
        <>
          <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} sx={{ m: '24px' }}>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
              <Controller
                render={({ field }) => (
                  <>
                    <InputLabel children={t('tests.kidneyFuncCalc')} />
                    <Select {...field} sx={{ display: 'block', mb: '20px' }} variant="standard">
                      <MenuItem children={<em>{t('tests.none')}</em>} value="" />
                      {kidneyFunctions.map((kidneyFunction: any) => (
                        <MenuItem children={kidneyFunction.name} key={kidneyFunction.id} value={kidneyFunction.id} />
                      ))}
                    </Select>
                  </>
                )}
                control={control}
                name="kidney_function"
                defaultValue={options.kidney_function}
              />
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.kidney}
                name="kidney"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      return field.onChange(e.target.checked ? 1 : 0);
                    }}
                    defaultChecked={!!options.kidney}
                  />
                )}
              />
              <InputLabel children={t('tests.kidneyFuncCalc')} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.double}
                name="double"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => {
                      return field.onChange(e.target.checked ? 1 : 0);
                    }}
                    defaultChecked={!!options.double}
                  />
                )}
              />
              <InputLabel children={t('tests.dualUseOfDrugs')} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.interaction}
                name="interaction"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                    defaultChecked={!!options.interaction}
                  />
                )}
              />
              <InputLabel children={t('tests.interactAnalysis')} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Controller
                defaultValue={options.recommendation}
                name="recommendation"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked ? 1 : 0)}
                    defaultChecked={!!options.recommendation}
                  />
                )}
              />
              <InputLabel children={t('tests.recomendAnalysis')} />
            </Box>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
              <Controller
                render={({ field }) => (
                  <>
                    <InputLabel children={t('tests.silRegion')} />
                    <Select {...field} sx={{ display: 'block', mb: '20px' }} variant="standard">
                      <MenuItem children={<em>{t('tests.none')}</em>} value="" />
                      {regions.map((region: IRegions) => (
                        <MenuItem children={region.regionName} key={region.regionId} value={region.regionId} />
                      ))}
                    </Select>
                  </>
                )}
                control={control}
                name="regionId"
                defaultValue={options.regionId}
              />
            </FormControl>
            <FormControl variant="standard" sx={{ m: 1, minWidth: '60%' }}>
              <Controller
                render={({ field }) => (
                  <>
                    <InputLabel children={t('tests.syntaxAnalysis')} />
                    <Select {...field} sx={{ display: 'block', mb: '20px' }} variant="standard">
                      <MenuItem children={<em>{t('tests.none')}</em>} value="" />
                      {syntaxAnalysis.map((syntax: any) => (
                        <MenuItem children={`${syntax.number} ${syntax.title}`} key={syntax.id} value={syntax.id} />
                      ))}
                    </Select>
                  </>
                )}
                control={control}
                name="analyse"
                defaultValue={options.analyse}
              />
            </FormControl>

            <Box sx={{ margin: '8px' }}>
              <InputLabel children={t('tests.inputDataXml')} />
              <Controller
                name="test_data"
                control={control}
                defaultValue={xmlData}
                render={({ field }) => (
                  <TextareaAutosize
                    minRows={5}
                    maxRows={30}
                    cols={137}
                    spellCheck={false}
                    className="resize-vertical w-60-percents"
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Box>

            <Button
              type="submit"
              children={t('tests.send')}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ m: '24px 0 0' }}
              onClick={() => setOpen(!open)}
            />
            {errorMessage ? (
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openError}
                autoHideDuration={2000}
                onClose={() => setOpenError(false)}
              >
                {showError(errorMessage)}
              </Snackbar>
            ) : (
              ''
            )}
          </Box>
        </>
      )}

      {!showResponseData ? (
        <Box sx={{ margin: '32px', minWidth: '60%' }}>
          {!response ? (
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
              <Spinner />
            </Backdrop>
          ) : (
            <>
              <Box
                sx={{
                  display: 'flex',
                  gap: '12px',
                  justifyContent: 'space-between',
                  maxWidth: '60.5%',
                  alignItems: 'flex-end',
                }}
              >
                <InputLabel children={t('tests.responseData')} />
                <ButtonGroup variant="outlined" size="small" color="inherit">
                  <Button
                    children={ResponseFormat.xml}
                    variant={responseShowFormat == ResponseFormat.xml ? 'contained' : 'text'}
                    onClick={() => setResponseShowFormat(ResponseFormat.xml)}
                  />
                  <Button
                    children={ResponseFormat.json}
                    variant={responseShowFormat == ResponseFormat.json ? 'contained' : 'text'}
                    onClick={() => setResponseShowFormat(ResponseFormat.json)}
                  />
                </ButtonGroup>
              </Box>
              <TextareaAutosize
                minRows={5}
                maxRows={30}
                cols={137}
                readOnly={true}
                spellCheck={false}
                className="resize-vertical w-60-percents"
                value={responseShowFormat == ResponseFormat.xml ? response.xml : JSON.stringify(response.data, null, 2)}
              />
              <InputLabel children={t('tests.additionalInformation')} sx={{margin: '20px 0 0'}} />
              <TextareaAutosize
                minRows={5}
                maxRows={30}
                cols={137}
                readOnly={true}
                spellCheck={false}
                className="resize-vertical red-text w-60-percents"
                value={JSON.stringify(warnings, null, 1)}
              />
            </>
          )}
        </Box>
      ) : null}
    </Layout>
  );
};

export default TestAnalysis;
