import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { postQuestion } from '../../../../../../api/syntax';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const BtnNewQuestion = ({ xml, setXml }: { xml: string; setXml: any }) => {
  const { t } = useTranslation();

  const [openQuestion, setOpenQuestion] = useState<boolean>(false);
  const [questionSelect, setQuestionSelect] = useState<string>('');

  const handleChangeQuestionSelect = (event: any) => setQuestionSelect(event.target.value);

  return (
    <>
      <Button
        onClick={() => setOpenQuestion(true)}
        variant="contained"
        color="warning"
        size="small"
        startIcon={<AddIcon />}
        children={t('syntaxcompiler.newQuestion')}
        sx={{ mr: '10px', width: 'max-content' }}
      />

      <Dialog fullWidth open={openQuestion} onClose={() => setOpenQuestion(false)}>
        <DialogTitle children={t('syntaxcompiler.addNewQuestion')} />
        <DialogContent>
          <TextField
            sx={{ marginTop: 1 }}
            label={t('syntaxcompiler.swedishLabel')}
            helperText={t('syntaxcompiler.possibilityToEnterQuestInOtherLang')}
            value={questionSelect}
            onChange={handleChangeQuestionSelect}
          />
        </DialogContent>
        <DialogActions>
          <Button
            children={t('syntaxcompiler.cancel')}
            variant="contained"
            color="error"
            onClick={() => setOpenQuestion(false)}
          />
          <Button
            children={t('syntaxcompiler.save')}
            variant="contained"
            color="success"
            onClick={() => {
              postQuestion(questionSelect).then((res) => setXml(xml + `<question>${res.data.question_id}</question>`)),
                setOpenQuestion(false);
            }}
            autoFocus
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BtnNewQuestion;
