import { apiClient } from '../api/axios';

export const getSyntaxAnalysis = () => apiClient('/syntax/analysis');
export const postSyntaxAnalysis = (title: string) => apiClient.post('/syntax/analysis', { title });
export const getAnalysisRecords = () =>
  apiClient('/records', {
    params: { rel_type: 11 },
  });

export const getSyntAnalysisById = (analysisId: any) => apiClient(`/syntax/analysis/${analysisId}`);

export const postStatusById = (analysisId: number, statusValue: number) =>
  apiClient.post(`/syntax/analysis/${analysisId}/status`, { status: statusValue });

export const deleteAnalysisById = (analysisId: number) =>
  apiClient.delete(`/syntax/analysis/${analysisId}`, {
    params: {
      id: analysisId,
    },
  });

export const getSyntAnalysisCode = (analysisId: any) => apiClient(`/syntax/analysis/${analysisId}`);

export const addTextLine = (analysisId: number, text: string, module: string, index?: string, hidden?: number) =>
  apiClient.post(`/syntax/analysis/${analysisId}/rows/add-text`, { text, module, index, hidden });

export const getSyntaxAlgorithms = () => apiClient('/syntax/algorithms');
export const getSyntaxAlgorithmsById = (id: number) => apiClient(`/syntax/algorithms/${id}`);

export const getAnalysisRecordsId = (analysisId: any) =>
  apiClient(`/records`, {
    params: { rel_type: 14, rel_id: analysisId },
  });

export const addTextLineRow = (
  elmentId: number,
  parent_row_id: number,
  text: string,
  module: string,
  index: string,
  hidden?: number
) =>
  apiClient.post(`/syntax/analysis/${elmentId}/rows/add-text`, {
    text,
    parent_row_id,
    module,
    index,
    hidden,
  });

export const addAlgorithmLineRow = (
  elmentId: number,
  parentId: number,
  algoId: number,
  module: string,
  index: string,
  hidden?: number
) =>
  apiClient.post(`/syntax/analysis/${elmentId}/rows/add-algorithm`, {
    algorithm_id: algoId,
    parent_row_id: parentId,
    module,
    index,
    hidden,
  });

export const deleteAnalysisRow = (elmentId: number, rowId: number) =>
  apiClient.delete(`/syntax/analysis/${elmentId}/rows/${rowId}`);

export const getTranslation = (elmentId: number, rowId: number) =>
  apiClient(`/syntax/analysis/${elmentId}/rows/${rowId}/translations`);

export const postTranslation = (elmentId: number, rowsId: number, langCode: any, langText: any) =>
  apiClient.put(
    `/syntax/analysis/${elmentId}/rows/${rowsId}/translations`,
    {
      lang_id: langCode,
      lang_text: langText,
    },
    { params: { analysis_id: elmentId, row_id: rowsId } }
  );

//questions
export const getQuestions = () => apiClient('/syntax/questions');
export const getQuestionById = (id: number) => apiClient(`/syntax/questions/${id}`);
export const getQuestionsPagination = (page: any, limit: number) =>
  apiClient('/syntax/questions', { params: { page, limit } });
export const getQuestionsPaginationLimit = (limit: number) => apiClient('/syntax/questions', { params: { limit } });

export const searchQuestions = (word: any, page: number, limit: number) =>
  apiClient(`/syntax/questions`, {
    params: {
      search: word,
      page,
      limit,
    },
  });
export const postQuestion = (text: string, answer?: number) =>
  apiClient.post('/syntax/questions', { text_sv: text, default_answer: Number(answer) });

export const getTranslationsQuestion = (questionId: number) => apiClient(`/syntax/questions/${questionId}`);
export const putTranslationsQuestion = (elmentId: number, lang_id: any, lang_text: any, answer?: number) =>
  apiClient.put(
    `/syntax/questions/${elmentId}`,
    {
      lang_id,
      lang_text,
      default_answer: Number(answer),
    },
    { params: { id: elmentId } }
  );
export const getQuestionsAlgorithm = (questionId: number) => apiClient(`/syntax/questions/${questionId}/algorithms`);

export const getQuestionRecordsId = (analysisId: any) =>
  apiClient(`/records`, {
    params: { rel_type: 16, rel_id: analysisId },
  });

export const getRecordsAlgorithm = () =>
  apiClient(`/records`, {
    params: { rel_type: 12 },
  });

export const getRecordsAlgorithmById = (algorithmId: any) =>
  apiClient(`/records`, {
    params: { rel_type: 12, rel_id: algorithmId },
  });

export const getVersionsAlgorithm = (algoId: number) => apiClient(`syntax/algorithms/${algoId}/versions`);
export const deleteAlgorithm = (algoId: number) => apiClient.delete(`syntax/algorithms/${algoId}`);

export const getAlgoTranslation = (algoId: number) => apiClient(`syntax/algorithms/${algoId}/response-text`);
export const postAlgoTranslation = (algoId: number, langCode: any, langText: any) =>
  apiClient.put(
    `syntax/algorithms/${algoId}/response-text`,
    {
      lang_id: langCode,
      lang_text: langText,
    },
    { params: { id: algoId } }
  );

export const postAlgorithm = (algorithmName: string, algorithmDescription: string, xml: string, textSv: string) =>
  apiClient.post(`syntax/algorithms`, {
    name: algorithmName,
    description: algorithmDescription,
    syntax: xml,
    response_text_sv: textSv,
  });

export const putAlgorithm = (
  id: number,
  algorithmName: string,
  algorithmDescription: string,
  xml: string,
  textSv: string
) =>
  apiClient.put(`syntax/algorithms/${id}`, {
    name: algorithmName,
    description: algorithmDescription,
    syntax: xml,
    response_text_sv: textSv,
  });

//module
export const getAnalysisModules = () => apiClient(`syntax/analysis/row-module-types`);
export const putAnalysisModules = (id: number, rowId: number, index: string, module: string, hidden: number) =>
  apiClient.put(`syntax/analysis/${id}/rows/${rowId}`, { index, module, hidden });
