import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{ display: 'flex', height: '100vh', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h5">{t('errors.pageNotFound')}</Typography>
      <Link to="/">{t('errors.backToStartPage')}</Link>
    </Box>
  );
};

export default NotFound;
