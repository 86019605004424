import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          general: {
            docOverview: 'Overview',
            docApiCalls: 'API Calls',
            docReqErrMsg: 'Request error messages',
            showMenu: 'Show menu',
            viewProfile: 'View profile',
            logOut: 'Log out',
          },
          access: {
            signin: 'Sign in',
            password: 'Password',
            username: 'Username (email address)',
            secretcode: 'Security code',
            forgot: 'Forgot password',
            errorAccess: 'Incorrect username or password',
            resetLinkUsedUp: 'The reset link has been used up',
            resetLinkDeleted: 'The reset link has been deleted',
            createNewForgotLink:
              'Create a new link by going to the <a href="/access"> login page </a> and selecting "forgot password".',
            goToLoginPage: 'Go to the login page',
            activateAccount: 'Activate account',
            enterPasswordToActivate: 'Enter password to activate account',
            enterNewPassword: 'Enter new password',
            enterPassword: 'Enter password',
            repeatPassword: 'Repeat password',
            activate: 'Activate',
            savePassword: 'Save password',
            passwordMustBe:
              'The password must contain at least 8 characters, a number, a capital letter and a lowercase letter.',
            resetPassword: 'Reset password',
            enterEmailToGetRestoreLink: 'Enter the email address for your account to get a restore link.',
            emailAddress: 'Email address',
            cancel: 'Cancel',
            sendFeedbackLink: 'Send feedback link',
            fillEmail: 'Please fill in an email address!',
            linkSent: 'Reset link sent to email',
            twoFA: '2-Factor Authentication Code',
          },
          dashboard: {
            thCustomer: 'Customer',
            thProblem: 'Problem',
            thEndDate: 'End date',
            noActionNeededNow: 'No action is needed right now.',
            hStatistics: 'Statistics',
            apiCallsTotal: 'API Calls (total):',
            apiCallsAfterLastSync: 'API Calls after last sync:',
            hProductRegister: 'Product register',
            numItemsOutOfSync: 'Number of items currently out of sync:',
            lastSync: 'Last sync:',
            nextSync: 'Next automatic sync:',
            numItemsInSystem: 'Number of items in the system:',
            hReports: 'Reports',
            showList: 'Show list',
          },
          customers: {
            registerCustomer: 'Customer register',
            addNewCustomer: 'Add new customer',
            companyName: 'Company name',
            enterValidCompName: 'Please enter a valid company name',
            orgNumber: 'Organization number',
            enterValidOrgNum: 'Please enter a valid organization number',
            address: 'Address',
            zipCode: 'ZIP code',
            city: 'City',
            contactPerson: 'Contact person',
            contactPersonFirsName: 'First name',
            enterContactPersonFirstName: 'Enter first name for contact person',
            contactPersonLastName: 'Last name',
            enterContactPersonLastName: 'Enter last name for contact person',
            contactPersonPhoneNumber: 'Phone number',
            contactPersonEmail: 'E-mail',
            enterContactPersonEmail: 'Please enter a valid email',
            saveCustomer: 'Save',
            anErrorHasOccured: 'An error has occurred!',
            failedCreateCustomer: 'Failed to create customer.',
            customerExists: 'Customer already exists!',
            companyNameInUse: 'The company name is already in use!',
            customerRemoved: 'Removed',
            customerActive: 'Active',
            customerInactive: 'Inactive',
            deactivateCustomer: 'Deactivate customer',
            activateCustomer: 'Activate customer',
            active: 'Activate',
            confirmActive: 'Confirm active',
            removeCustomer: 'Remove customer',
            businessInfromation: 'Business information',
            saveBusinessInfo: 'Save',
            saveContactPerson: 'Save',
            userCreated: 'User created!',
            newCustomerCreated: 'New customer created ',
            removed: 'Removed!',
            customerHasBeenRemoved: 'The customer has been removed.',
            customerActivated: 'Customer activated!',
            customerHasBeenActivated: 'Customer activated: ',
            customerInactivated: 'Customer inactivated!',
            customerHasBeenInactivated: 'Customer inactivated: ',
            licenseAdded: 'License added!',
            newLicenseAddedFor: 'New license added for: ',
            licenseRemoved: 'License removed',
            licenseRemovedFor: 'License removed for: ',
            integrationRemoved: 'Integration removed',
            integrationRemovedFor: 'Integration removed for: ',
            integrationAdded: 'Integration added',
            integrationAddedFor: 'Integration added for',
            customerInfoSaved: 'Saved!',
            customerInfoChanged: 'Company information changed.',
            failedSaveCustomerInfo: 'Failed to save customer information.',
            inCaseOfErrorContactAdmin: 'In case of repeated errors, contact the administrator.',
            failedSaveContactInfo: 'Failed to save contact information.',
            addNewCustomerLinkName: 'Add new customer',
            thCustomerNumber: 'Customer number',
            thBusiness: 'Business',
            thCity: 'City',
            thCreated: 'Created',
            showDeleted: 'Show deleted',
            showCustomer: 'Show customer',
            remActivate: 'Activate',
            remInactivate: 'Deactivation',
            remRemove: 'Remove',
            cancel: 'Cancel',
            confirmActivate: 'Confirm activation',
            confirmInactivate: 'Confirm deactivation',
            confirmRemove: 'Confirm remove',
            confirmDelete: 'Confirm delete',
            failDeleteCustomer: 'Failed to delete customer.',
            search: 'Search',
            recentEvents: 'Recent events',
            statusChanged: 'Customer status changed',
            dataChanged: 'Customer data changed',
            customerActivated: 'Customer activated',
            customerDectivated: 'Customer deactivated',
            customerRemoved: 'Customer removed',
            loading: 'Please wait, the data is loading...',
          },
          apiIntegration: {
            apiIntegration: 'API integration',
            addNewIntegration: 'Add new integration',
            customerLabel: 'Customer *',
            anErrorHasOccured: 'An error has occurred!',
            inCaseOfErrorContactAdmin: 'In case of repeated errors, contact the administrator.',
            failedCreateCustomer: 'Failed to create customer.',
            selectCustomerTitle: 'Select customer',
            selectCustomer: 'Select customer',
            apiIntegrForCustLicenses: 'API integrations for customer licenses',
            newIntegration: 'New integration',
            thPublicKey: 'Public key',
            thKidneyFuncCalc: 'Kidney function Calculation',
            thSyntaxAnalysis: 'Syntax analysis',
            thRegionId: 'RegionId',
            thLicenseNum: 'License number',
            thLicenseType: 'License type',
            thCallsLeft: 'Call left',
            thRequestKey: 'Request key',
            thActivated: 'Activated',
            activate: 'Activate',
            thCustomerNumber: 'Customer number',
            thBusiness: 'Business',
            yesCockcroftGault: 'Yes - Cockcroft-Gault',
            yesLundMalmoRevised: 'Yes - Lund-Malmö revised',
            no: 'No',
            yes: 'Yes',
            removeIntegration: 'Remove integration',
            showDeleted: 'Show deleted',
            saved: 'Saved',
            integrationCreated: 'Integration created',
            noLimit: 'no limit',
            showCustomer: 'Show customer',
            licenseLabel: 'License *',
            selectLicense: 'Select license',
            selectLicenseInfo: 'Select license',
            customerHasNoActiveLicense: 'The customer has no active licenses',
            removeTitle: 'Remove',
            doYouWantRemoveIntegration: 'Do you want to remove integration?',
            cancel: 'Cancel',
            yesRemoveIntegration: 'Remove integration',
            failedRemoveIntegration: 'Failed to remove integration.',
            productRegister: 'Product register',
            productRegisterTitle: 'Select product register',
            productRegisterInfo: 'Select product register',
            selectRegionTitle: 'Select region',
            regionsSilLabel: 'Region',
            langForIntegrationLabel: 'Language for integration',
            selectLangTitle: 'Select language',
            standartKidneyFuncCalcLabel: 'Standard kidney function calculation',
            cockcroftGault: 'Cockcroft-Gault',
            lundMalmoRevised: 'Lund-Malmö, revised',
            syntaxAnalysisLabel: 'Syntax analysis',
            selectAnalysisOption: 'Select analysis',
            selectAnalysisTitle: 'Select analysis',
            saveIntegration: 'Save',
            search: 'Search',
            lastSync: 'Last synchronized:',
            editIntegration: 'Edit integration',
            integrationChanged: 'Integration changed',
            notActivated: 'Not activated',
            none: 'None',
          },
          administration: {
            anErrorHasOccured: 'An error has occured',
            inCaseOfErrorContactAdmin: 'In case of repeated errors, contact the administrator.',
            previewOfDiagnosRegister: 'Preview of diagnostic registers',
            totalRows: 'Total rows: ',
            runTakes: 'run takes (sec): ',
            warning: 'Warning: ',
            noErrors: 'No errors were found',
            toContinueClickDiagnosticRegister: 'To continue, click activate diagnostic register',
            activateDiagnosticRegister: 'Activate diagnostic register',
            waitDbManaging: 'Please wait, managing database.',
            diagnosticRegisterUpdated: 'Diagnostic register updated',
            dbUpdatedTime: 'The database has been updated, in total time (sec): ',
            administration: 'Administration',
            diagRegister: 'Diagnostic register',
            uploadNewDiagRegFile: 'Upload new diagnostic registry file',
            uplDbFile: 'Upload database file',
            cancel: 'Cancel',
            upload: 'Upload',
            errorDbFileSize: 'database file is over 20mb',
            couldNotSave: 'Could not save',
            pleaseTryAgain: 'Please try again.',
            dbFilePreview: 'Database file preview',
            thFileName: 'File name',
            thSysDataVersionInternal: 'System data version (internal)',
            thLastActivated: 'Last activated',
            thCreated: 'Created',
            thCreatedBy: 'Created by',
            neverActivated: 'Never activated',
            noUsersFound: 'No users found',
            toContinueClickSideEffectDb: 'To continue, click activate side effect database',
            activateSideEffectDb: 'Activate side effect database',
            pleaseWaitDbLoading: 'Please wait, the database is loading.',
            dbUpdated: 'Database updated',
            dbHasBeenUpdated: 'The database has been updated',
            thAtcCode: 'atc_code',
            thNplid: 'nplid',
            thFrequency: 'Frequency',
            thFass: 'FASS',
            thPh20Num: 'PHASE 20 num',
            thPh20Text: 'PHASE 20 text',
            sideEffectDb: 'Side effect database',
            version: 'Version',
            uplModifDbFile: 'Upload / modify database file',
            currentSideEffectDb: 'Current side effect database',
            dbHasBeenUpdatedTotalTime: 'The database has been updated, total time:',
            sideEffectVersionsLabel: 'Versions - select to preview or upload new database file',
            selectVersion: 'Select version',
            selectVersionTitle: 'Select version',
            uplNewDbFile: 'Upload new database file',
            enableTwoStepAuth: 'Enable 2-step authentication',
            responseCode: 'Response code',
            scanQrCode: 'Scan the QR code',
            confirmTwoStepAuth: 'Confirm two-step authentication',
            incorrectEnteredCode: 'The entered code is incorrect!',
            failedToEnableTwoStepAuth: 'Failed to enable 2-step authentication.',
            user: 'User',
            addNewUser: 'Add new user',
            userInformation: 'User information',
            firstName: 'First name *',
            enterValidFirstName: 'Please enter a valid first name',
            lastName: 'Last name *',
            enterValidLastName: 'Please enter a valid last name',
            userName: 'User name *',
            emailAddress: '(Email address)',
            emailAddressUsedWhenLogIn: ' e-mail address is used when logging in',
            enterValidEmailAddress: 'Please enter a valid email address',
            save: 'Save',
            userAlreadyExist: 'User already exist!',
            emailAddressAlreadyInUse: 'The specified email address is already in use.',
            failedCreateCustomer: 'Failed to create user',
            notActivated: 'Not activated',
            active: 'Active',
            removed: 'Removed',
            removeTwoFactorAuth: 'Remove two-factor authentication',
            enableTwoFactorAuth: 'Enable two-factor authentication',
            deleteUsers: 'Delete user',
            changePassword: 'Change password',
            enterNewPassword: 'Enter new password',
            passwordMustBe:
              'The password must contain at least 8 characters, a number, a capital letter and a lowercase letter.',
            repeatNewPassword: 'Repeat new password *',
            firstNameLabel: 'First name',
            lastNameLabel: 'Last name',
            userNameLabel: 'User name',
            userCreated: 'User created!',
            newUserCreated: 'New user created:',
            msgRemoved: 'Removed!',
            theUserHasBeenDeleted: 'The user has been deleted.',
            twoStepAuthActivated: 'Two-step authentication activated!',
            twoStepAuthRemoved: 'Two-step authentication removed!',
            savedInfo: 'Saved!',
            userInformationChanged: 'User information changed.',
            failedToSaveUserData: 'Failed to save user data.',
            failedToSavePassword: 'Failed to save password.',
            passwordChanged: 'Password changed.',
            removeTwoFactorAuthTitle: 'Remove Two-Factor authentication',
            confirmRemoveTwoFactorAuth: 'Confirm removal of two-step authentication',
            confirmAndDelete: 'Confirm & delete',
            failedRemoveTwoStepAuth: 'Failed to remove two-step authentication.',
            remove: 'Remove',
            tryingToRemoveYourself: 'NOTE! You are trying to delete your own user!',
            failedToDeleteUser: 'Failed to delete users.',
            thFirstName: 'First name',
            thLastName: 'Last name',
            thUserName: 'User name',
            th2FA: '2FA',
            userStatusActive: 'Active',
            userStatusNotActivated: 'Not activated',
            userStatusRemoved: 'Removed',
            showDeleted: 'Show deleted',
            yes: 'Yes',
            no: 'No',
            viewProfile: 'View profile',
            uiLanguage: 'UI language',
            selectLanguage: 'Select language',
            search: 'Search',
            recentEvents: 'Recent events',
            lines: 'lines',
            status: 'Status',
            requestStatus: 'Request status',
            drugIdList: 'Drug Id list',
            showDrugIdList: 'Show drug Id list',
            endpoint: 'Endpoint',
            publicKey: 'Public key',
            requestBody: 'Request body',
            responseBody: 'Response body',
            date: 'Date',
            visualize: 'Visualize',
            failed: 'failed',
            success: 'success',
            show: 'show',
            downloadFile: 'Download file',
            from: 'From',
            to: 'To',
            delete: 'Delete',
            areYouSure: 'Are you sure you want to delete user?',
          },
          tests: {
            kidneyFuncCalc: 'Kidney function Calculation',
            kidFuncNo: 'No',
            kidFuncCockcroftGault: 'Yes - Cockcroft-Gault',
            kidFuncLundMalmo: 'Yes - Lund-Malmö, revised',
            adverseReactAnalysis: 'Adverse reaction analysis',
            dualUseOfDrugs: 'Dual use of drugs',
            interactAnalysis: 'Interaction analysis',
            recomendAnalysis: 'Recommendation analysis',
            silRegion: 'Region',
            syntaxAnalysis: 'Syntax analysis',
            inputDataXml: 'Input data (xml)',
            send: 'Send',
            responseData: 'Response data',
            testAnalysis: 'Test analysis',
            error: 'Error',
            anErrorHasOccured: 'An error has occurred',
            visualizationComponent: 'Visualization component',
            drugIds: 'Drug IDs',
            cancel: 'Cancel',
            classNames: 'Class names',
            choosingColors: 'Choosing colors',
            copyright: 'Copyright',
            save: 'Save',
            confClass: 'Configuration class',
            twoDrugs: 'Required minimum of two drugs',
            copyrightDefaultValue: 'Quality Pharma',
            none: 'None',
            additionalInformation: 'Additional information',
          },
          syntaxcompiler: {
            addTextLine: 'Add line of text',
            algorithm: 'Algorithm',
            addAlgorithm: 'Add algorithm',
            undo: 'Undo',
            textField: 'Text field',
            selectAlgorithm: 'Select algorithm',
            algorithmQuestions: 'Algorithm questions',
            showQuestion: 'Show question',
            doYouWantToDeleteAlg: 'Do you want to delete algorithm?',
            cancel: 'Cancel',
            delete: 'Delete',
            returnAnswerForAlgorithm: 'Retursvar för algoritm',
            save: 'Save',
            close: 'Close',
            langDbUpdated: 'Language database updated',
            algLangUpdated: 'Algorithm languages updated',
            anErrorHasOccured: 'An error has occurred',
            inCaseOfErrorContactAdmin: 'In case of repeated errors, contact the administrator.',
            couldNotUpdateLangDb: 'Could not update language database',
            earlierVersionsOfAlgorithm: 'Earlier versions of the algorithm',
            calculationAlgorithm: 'Calculation algorithms',
            copy: 'Copy',
            createNew: 'Create new',
            selectAlgOrCreateNew: 'Select algorithm to change in the dropdown menu, or create a new algorithm',
            removed: 'Removed',
            algorithmRemoved: 'Algorithm removed',
            couldNotDelete: 'Could not delete',
            saved: 'Saved',
            algorithmSaved: 'Algorithm saved',
            draft: 'DRAFT',
            published: 'PUBLISHED',
            unpublish: 'Unpublish',
            publish: 'Publish',
            showCode: 'Show code',
            newLine: 'New line',
            rowRemoved: 'Row removed',
            fail: 'Fail',
            added: 'Added',
            textLineAdded: 'Text line added',
            algorithmAdded: 'Algorithm added',
            updated: 'Updated',
            textLineTranslations: 'Text line translations',
            languageDbUpdated: 'Language database updated',
            textLineLanguagesUpdated: 'Text line languages updated',
            selectAnalysis: 'Select analysis',
            code: 'Code',
            name: 'Name',
            enterName: 'Enter a name',
            description: 'Description',
            returnResponse: 'Return response (in case of positive outcome of the algorithm)',
            otherLang: 'Other languages',
            otherLangSaveFirst: 'Other languages (save first)',
            loadQuestion: 'Load question',
            newQuestion: 'New question',
            algXmlLogic: 'Algorithm (xml/+logic)',
            problemsWithSyntax: 'Problems with syntax',
            deleteAlg: 'Delete algorithm',
            versions: 'Versions',
            analysis: 'Analysis',
            selAnalysisOrCreateNew: 'Select analysis to change in the drop-down menu, or create a new analysis',
            titleInternal: 'Title (internal)',
            missingTitle: 'Missing title',
            create: 'Create',
            created: 'Created',
            analysisCreated: 'Analysis created',
            doYouWant: 'Do you want?',
            yes: 'Yes',
            no: 'No',
            addNewQuestion: 'Add new question',
            swedishLabel: 'Swedish *',
            enterQuestionInSwedish: 'Enter question in Swedish',
            possibilityToEnterQuestInOtherLang:
              'Possibility to enter question in other languages when the question was created.',
            questionCreated: 'Question created!',
            newQuestionSaved: 'New question saved',
            couldNotCreateQuestion: 'Could not create question',
            question: 'Question',
            swedish: '( Swedish )',
            load: 'Load',
            usedInPublishedAnalysis: 'Used in published analysis',
            usedInAlgorithm: 'Used in algorithm',
            notUsedInAnyAnalysis: 'Not used in any analysis',
            usedInAlgorithms: 'Used in algorithms',
            show: 'Show',
            questionSaved: 'Question saved!',
            failedToSaveQuestion: 'Failed to save question.',
            questions: 'Questions',
            notUsed: 'Not used',
            remove: 'Remove',
            youWillAlsoDeleteLines: 'You will also delete these lines linked to the line:',
            deleteSyntaxRow: 'Delete syntax row',
            responseData: 'Response data',
            deleteRow: 'Do you want to delete row?',
            recentEvents: 'Recent events',
            selectVersion: 'Select version',
            defaultAnswer: 'Default answer',
            editIndex: 'Edit index',
            selectModuleType: 'Select a module type',
            hideModule: 'Hide module in the analysis report',
            module: 'Modules',
            color: 'Choose color',
            label: 'Label',
            legend: 'Legend',
            addNewModule: 'Add new module',
            created: 'New module created',
            updated: 'Module updated',
            removed: 'Module removed',
            loading: 'Please wait, the data is loading...',
            index: 'Index',
            questionAdded: 'The question has been loaded',
          },
          documentation: {
            handshakeValidateDesc:
              "This API-call is used to validate a public key, this call won't generate a privatekey and it wont consume the one time use of a public key.",
            apiCallHeader: 'API call header information',
            apiCalls: 'API Calls',
            chooseFeature: 'Choose feature',
            overview: 'Overview',
            docDesc: 'API for MiniQ decision support system for analysis of drug prescriptions and drug use.',
            handshakeDesc: 'This call will generate a private key to use for the rest of the API',
            requestDesc: 'The main API-call, where analyses will be performed on the input data based on licensetypes.',
            validationEndpointsDesc: 'Used to validate API call input without affecting license restrictions.',
            responseTypesDesc:
              'All API-calls can be chosen to either be json arrays or XML format. When XML is chosen the response is bracketed around &lt; data&gt Here is the response &lt;/data&gt;. JSON arrays will be nested in most cases, for each API call endpoint read the API calls section. Standard response is json array.',
            badRequestDesc:
              'The server cannot or will not process the request due to an apparent client error (e.g., malformed request syntax, size too large, invalid request message framing, or deceptive request routing)',
            unauthorizedDesc:
              'The response must include a WWW-Authenticate header field containing a challenge applicable to the requested resource.',
            internalServerErrorDesc:
              'A generic error message, given when an unexpected condition was encountered and no more specific message is suitable.',
            serviceUnavailiableDesc:
              'The server cannot handle the request (because it is overloaded or down for maintenance). Generally, this is a temporary state.',
            gatewayTimeoutDesc:
              'The server was acting as a gateway or proxy and did not receive a timely response from the upstream server.',
            reqErrMsg: 'Request error messages',
            errCode: 'Error code',
            errMeaning: 'Meaning',
            err1001: 'Missing regionid',
            err1002: 'Missing patientdata',
            err1003: 'Patientdata wrong format',
            err1003a: 'Missing patientid',
            err1003b: 'Birthyear missing',
            err1003c: 'Sex missing',
            err1003c2: 'Sex wrong format, must be M or F',
            err1003d: 'Weight missing',
            err1006: 'Prescriptions dataformat is wrong',
            err1006b: 'PrescStartdate missing',
            err1006b2: 'PrescStartdate wrong format, use YYYY/MM/DD',
            err1006c: 'Drugform missing',
            err1006d: 'Drugname missing',
            err1006e: 'Strength missing',
            err1006f: 'StrengthUnit missing',
            err1006g: 'Nplid missing',
            err1006h: 'Nplpackid missing',
            err1006i: 'Dosage missing',
            err1006j: 'Indication missing',
            err1007: 'LabValues missing',
            err1007a: 'LabValues wrong format',
            err1007b: 'LabValue missing',
            err1007b2: 'LabValue wrongformat',
            err1007c: 'LabDate missing',
            err1007c2: 'LabDate wrong format (YYYY/MM/DD)',
            err1007d: 'LabTestName missing',
            err1007e: 'LabValueNum missing',
            err1007f: 'LabValueText missing',
            err1007g: 'SymtomList missing',
            err1007g1: 'SymtomList wrong format',
            err1007g2: 'SymtomListDate missing',
            err1007g2a: 'SymtomListDate wrong format (YYYY/MM/DD)',
            err1007g3: 'Symtom missing',
            err1007g3a: 'Symtom wrong format (array/object)',
            err1007g4: 'Symtomnumber missing',
            err1007g5: 'SymtomSeverity missing',
            err1007g6: 'SymtomText missing',
            err1008: 'IcdCode needs to be a string',
            handshakeEndpointDesc:
              'This call will generate a private key to use for the rest of the API. Be sure to save your private key / response, because a private key can only be generated once per public key.',
            requestValidateDesc:
              'Validates the post_fields and the call, this API call is not considered a live call and wont count towards using the license connected to API-keys',
            requestEndpointDesc:
              'The main API-call, where analyses will be performed on the input data based on licensetypes.',
          },
          productlist: {
            prodRegister: 'Product register',
            info: 'Info',
            strengthNumerical: 'Numerical strength',
            strengthUnit: 'Strength unit',
            atcCode: 'ATC-code',
            packNumberNumeric: 'Pack. number numeric',
            packMultiple1: 'Pack. multiple 1',
            packMultiple2: 'Pack. multiple 2',
            pharmSalePrice: "The pharmacy's sale price",
            numOfDailyDoses: 'Number of daily doses / packaging',
            itemNum: 'ItemNo',
            drugId: 'InternalDrugID',
            genericid: 'GenericID',
            nplId: 'DrugID',
            nplPackId: 'DrugpackID',
            pharmForm: 'Pharmaceutical form',
            retrieved: 'Retrieved',
            name: 'Name',
            substanceId: 'SubstanceId',
            unsyncProducts: 'Unsynchronized products',
            lastSync: 'Last sync:',
            nextAutoSync: 'Next automatic sync: ',
            apiIntegration: 'API-integration',
            specNplId: 'Specified DrugID',
            specNplPackId: 'Specified DrugpackID',
            dateOfCall: 'Date of call',
            existAfterSync: 'Exists after synchronization of product registers',
            notExistAfterSync: 'Does not exist after synchronization of product registers',
            prodRegSyncNotCompleted: 'Product register synchronization not completed after date of call.',
            call: 'Call',
            markAsProcessed: 'Mark as processed',
            callData: 'Call data',
            close: 'Close',
            markCallsAsProcessed: 'Mark calls as processed from',
            noteLinesWillDisappear: 'NOTE! Treated lines disappear from this list!',
            cancel: 'Cancel',
            mark: 'Mark',
            treated: 'Treated!',
            markedAsTreated: 'Marked as treated',
            anErrorHasOccured: 'An error has occurred',
            inCaseOfErrorContactAdmin: 'In case of repeated errors, contact the administrator.',
            couldNotCreateQuestion: 'Could not create question.',
            synchronized: 'Synchronized',
            date: 'Date',
            exists: 'Exists',
            doesNotExist: 'Does not exist',
            no: 'No',
            yes: 'Yes',
            preparationName: 'Preparation name',
            added: 'Added',
            historyPrep: 'Historical preparation',
            showProduct: 'Show product',
            syncProductRegister: 'Synchronize product registers',
            pleaseWaitSync: 'Please wait, synchronization in progress, it may take up to 30 minutes',
            syncReadyOn: 'Sync ready on',
            syncCancel: 'Sync cancel',
            procContinueInBackground: 'The process continues in the background!',
            syncronization: 'Synchronization',
            syncronizationOf: 'Synchronization of',
            syncDesc:
              'Depending on how many new products will be downloaded from the register, it can take up to 30 minutes to synchronize, other services can sometimes work slowly, incorrectly or not at all.',
            doYouWantToCont: 'Do you want to continue?',
            startSync: 'Start synchronization',
            search: 'Search',
            containsSubstances: 'Contains substances:',
            syncCompleted: 'Sync completed successfully',
          },
          errors: {
            uncaughtExep: 'An uncaught Exception was encountered',
            anErrorHasOccured: 'An error has occurred',
            inCaseOfErrorContactAdmin: 'In case of repeated errors, contact the administrator.',
            pageNotFound: 'The page you were looking for could not be found.',
            backToStartPage: 'Back to start page',
            close: 'Close',
          },
          licenses: {
            customer: 'Customer *',
            customerRegister: 'Customer register',
            addNewLicense: 'Add new license',
            editLicense: 'Edit license',
            licenseType: 'License type *',
            selectLicenseType: 'Select a license type',
            selectCustomer: 'Please select a customer.',
            save: 'Save',
            failedCreateCustomer: 'Failed to create customer.',
            renew: 'Renew',
            newDate: 'New date',
            extraNumberOfCalls: 'Extra number of calls (in addition to the current ones)',
            saved: 'Saved!',
            licenseUpdated: 'The license has been updated!',
            couldNotSave: 'Could not save!',
            licenseValidUntil: 'License valid until *',
            selectValidDate: 'Please select a valid date',
            numberOfCallsForPeriod: 'Number of calls for the period',
            specifyNumberOfCalls: 'You must specify the number of calls that can be made.',
            licenses: 'Licenses',
            licenseNumber: 'License number',
            type: 'Type',
            maxCall: 'Max call',
            thSaved: 'Saved',
            validUntil: 'Valid until',
            manageLicense: 'Manage license',
            createApiIntegration: 'Create api integration',
            removeLicense: 'Remove license',
            noLimit: 'No limit',
            remove: 'Remove',
            cancel: 'Cancel',
            failedToRemoveLicense: 'Failed to remove license.',
            licenseHaveIntegration:
              'The license has integrations connected to it, if you remove the license these also disappear, do you want to continue?',
            doYouWantRemoveLicense: 'Do you want to remove the license? The license has no active integrations.',
            search: 'Search',
          },
          settings: {
            downloadLangFileFor: 'Download language file for',
            syntaxQuestions: 'Syntax questions',
            syntaxTextLines: 'Syntax text lines',
            syntaxReturnResponse: 'Syntax return response',
            phase20text: 'Phase 20 text',
            fassText: 'Fass text',
            cancel: 'Cancel',
            download: 'Download',
            downloadStarted: 'Download started!',
            langFilePreviewFor: 'Language file preview for',
            warning: 'Warning:',
            noErrorsFound: 'No errors were found',
            toContinueApproveLangFile: 'To continue, approve language file:',
            approve: 'Approve',
            langDbUpdated: 'Language database updated',
            totalRows: 'Total rows: ',
            gonnaAffected: 'will be affected upon approval, driving went on',
            language: 'Language',
            selectLanguage: 'Select language',
            noteBreaksInCsv:
              'Note that a number of breaks in the text csv files will be removed, use spaces when translating.',
            downloadLangFile: 'Download language file',
            uploadLangFile: 'Upload language file',
            noLangFileForSwedish: 'No language file for Swedish',
            uploadLangFileFor: 'Upload language file for',
            downloadTranslationSwedishFile: 'Download Swedish translation file',
            addNewLangTitle: 'Add a new language: ',
            addLanguage: 'Add a language',
            languageAdded: 'Language added',
            langCouldNotBeAdded: 'The language could not be added.',
            settings: 'Settings',
            languageSettings: 'Language settings',
            addNewLang: 'Add new language',
            upload: 'Upload',
            fileOversized: 'language file is over 20mb',
            failedSaveTryAgain: 'Failed to save. Please try again.',
            recentEvents: 'Recent events',
            downloadSwedish: 'Download Swedish',
            translationFile: 'translation file',
            uploadLanguageFileFor: 'Upload language file for',
            swedishLanguage: "Swedish language settings doesn't require a translation",
            fileUploadSuccess: 'File uploaded successfully',
            status: 'Status',
            removeLanguage: 'Remove',
            changeStatus: 'Change status',
            save: 'Save',
            areYouWantChangeStatus: 'Are you want to change the status for this language?',
            statusChanged: 'Status changed',
            areYouWantRemove: 'Are you want remove language translation?',
            languageRemoved: 'Language removed',
            translationsCount: 'Translations count',
            integrationsCount: 'Integrations count',
            no: 'No',
            deactivatedLanguage: 'Deactivate language',
            activateLanguage: 'Activate language',
          },
          menu: {
            dashboard: 'Dashboard',
            customerRegister: 'Customer register',
            apiIntegration: 'API-integration',
            productRegister: 'Product register',
            testAnalysis: 'Test analysis',
            syntax: 'Syntax',
            analysis: 'Analysis',
            calculationAlgorithm: 'Calculation algorithms',
            questions: 'Questions',
            administration: 'Administration',
            settings: 'Settings',
            users: 'Users',
            sideEffectDatabase: 'Side effect database',
            diagnosticRegister: 'Diagnostic register',
            apiDocumentation: 'API documentation',
            disableMaintenanceMode: 'Disable maintenance mode for API',
            enableMaintenanceMode: 'Enable maintenance mode for API',
            tests: 'Tests',
            algorithms: 'Algorithms',
            visualizationComponent: 'Visualization component',
            history: 'History of requests',
            module: 'Modules',
          },
          language: {
            en: 'English',
            sv: 'Swedish',
            es: 'Spanish',
            de: 'German',
            fr: 'French',
          },
        },
      },
      sv: {
        translation: {
          general: {
            docOverview: 'Översikt',
            docApiCalls: 'API-anrop',
            docReqErrMsg: 'Begär felmeddelanden',
            showMenu: 'Visa meny',
            viewProfile: 'Visa profil',
            logOut: 'Logga ut',
          },
          access: {
            signin: 'Logga in',
            password: 'Lösenord',
            username: 'Användarnamn (e-postadress)',
            secretcode: 'Säkerhets kod',
            forgot: 'Glömt lösenord',
            errorAccess: 'Felaktig användarnamn eller lösenord',
            resetUsedUp: 'Återställningslänken har förbrukats',
            resetLinkDeleted: 'Återställningslänken har utgått',
            createNewForgotLink:
              'Skapa en ny länk genom att gå till <a href="/access">inloggningssidan</a> och välj "glömt lösenord".',
            goToLoginPage: 'Gå till inloggningssida',
            activateAccount: 'Aktivera konto',
            enterPasswordToActivate: 'Ange lösenord för att aktivera konto',
            enterNewPassword: 'Ange nytt lösenord',
            enterPassword: 'Ange lösenord',
            repeatPassword: 'Upprepa lösenord',
            activate: 'Aktivera',
            savePassword: 'Spara lösenord',
            passwordMustBe: 'Lösenordet måste innehålla minst 8 tecken, en siffra, en versal och en gemen.',
            resetPassword: 'Återställ lösenord',
            enterEmailToGetRestoreLink: 'Ange e-postdressen för ditt konto för att få återställningslänk.',
            emailAddress: 'E-postadress',
            cancel: 'Avbryt',
            sendFeedbackLink: 'Skicka återsläningslänk',
            fillEmail: 'Vänligen fyll i en e-postadress!',
            linkSent: 'Återställ länk skickad till e-post',
            twoFA: '2-faktors autentiseringskod',
          },
          dashboard: {
            tDashboard: 'Dashboard',
            thCustomer: 'Kund',
            thProblem: 'Problem',
            thEndDate: 'Slut datum',
            noActionNeededNow: 'Inga åtgärder behövs just nu.',
            hStatistics: 'Statistik',
            apiCallsTotal: 'API Anrop (totalt):',
            apiCallsAfterLastSync: 'API Anrop efter senaste synkronisering:',
            hProductRegister: 'Varuregister',
            numItemsOutOfSync: 'Antal artiklar som är osynkroniserade just nu:',
            lastSync: 'Senaste synkronisering:',
            nextSync: 'Nästa automatiska synkronisering:',
            numItemsInSystem: 'Antal inlagda artiklar i systemet:',
            hReports: 'Rapporter',
            showList: 'Visa lista',
          },
          customers: {
            registerCustomer: 'Kundregister',
            addNewCustomer: 'Lägg till ny kund',
            companyName: 'Företagsnamn *',
            enterValidCompName: 'Ange giltigt företagsnamn',
            orgNumber: 'Organisationsnummer',
            enterValidOrgNum: 'Ange giltigt organisationsnummer',
            address: 'Adress',
            zipCode: 'Postnummer',
            city: 'Stad',
            contactPerson: 'Kontaktperson',
            contactPersonFirsName: 'Förnamn *',
            enterContactPersonFirstName: 'Ange förnamn för kontaktperson',
            contactPersonLastName: 'Efternamn *',
            enterContactPersonLastName: 'Ange efternamn för kontaktperson',
            contactPersonPhoneNumber: 'Telefonnummer',
            contactPersonEmail: 'E-post *',
            enterContactPersonEmail: 'Ange en giltig e-post',
            saveCustomer: 'Spara',
            anErrorHasOccured: 'Ett fel har inträffat!',
            failedCreateCustomer: 'Gick inte att skapa kund.',
            customerExists: 'Kund existerar redan!',
            companyNameInUse: 'Företagsnamnet används redan!',
            customerRemoved: 'Borttagen',
            customerActive: 'Aktiv',
            customerInactive: 'Inaktiv',
            deactivateCustomer: 'Avaktivera kund',
            activateCustomer: 'Aktivera kund',
            removeCustomer: 'Ta bort kund',
            businessInfromation: 'Företagsinformation',
            saveBusinessInfo: 'Spara',
            saveContactPerson: 'Spara',
            userCreated: 'Användare skapad!',
            newCustomerCreated: 'Ny kund skapad: ',
            removed: 'Borttagen!',
            customerHasBeenRemoved: 'Kunden har tagits bort.',
            customerActivated: 'Kund aktiverad!',
            customerHasBeenActivated: 'Kunden aktiverad: ',
            customerInactivated: 'Kund avaktiverad!',
            customerHasBeenInactivated: 'Kund avaktiverad: ',
            licenseAdded: 'Licens tillagd',
            newLicenseAddedFor: 'Ny licens tillagd för: ',
            licenseRemoved: 'Licens borttagen',
            licenseRemovedFor: 'Licens borttagen för: ',
            integrationRemoved: 'Integration borttagen',
            integrationRemovedFor: 'Integration borttagen för: ',
            integrationAdded: 'Integration tillagd',
            integrationAddedFor: 'Integration tillagd för',
            customerInfoSaved: 'Sparad!',
            customerInfoChanged: 'Företagsuppgifter ändrade.',
            failedSaveCustomerInfo: 'Gick inte att spara kundinformation.',
            inCaseOfErrorContactAdmin: 'Vid upprepade fel kontakta administratör.',
            failedSaveContactInfo: 'Gick inte att spara kontaktuppgifter.',
            addNewCustomerLinkName: 'Lägg till ny kund',
            thCustomerNumber: 'Kundnummer',
            thBusiness: 'Företag',
            thCity: 'Stad',
            thCreated: 'Skapad',
            showDeleted: 'Visa borttagna',
            showCustomer: 'Visa kund',
            remActivate: 'Aktivera',
            remInactivate: 'Avaktivering',
            remRemove: 'Ta bort',
            cancel: 'Avbryt',
            confirmActivate: 'Bekräfta & aktivera',
            confirmInactivate: 'Bekräfta avaktiveringen',
            confirmDelete: 'Bekräfta & ta bort',
            confirmRemove: 'Bekräfta borttagning',
            failDeleteCustomer: 'Gick inte att ta bort kund.',
            search: 'Sök',
            recentEvents: 'Senaste händelser',
            statusChanged: 'Kundstatus ändrad',
            dataChanged: 'Kunddata ändras',
            customerActivated: 'Kund aktiverad',
            customerDectivated: 'Kunden avaktiverad',
            customerRemoved: 'Kunden har tagits bort',
            loading: 'Vänta, data laddas...',
          },
          apiintegration: {
            apiIntegration: 'API-integration',
            addNewIntegration: 'Lägg till ny integration',
            customerLabel: 'Kund *',
            anErrorHasOccured: 'Ett fel har inträffat!',
            inCaseOfErrorContactAdmin: 'Vid upprepade fel kontakta administratör.',
            failedCreateCustomer: 'Gick inte att skapa kund.',
            selectCustomerTitle: 'Välj kund',
            selectCustomer: 'Välj kund',
            apiIntegrForCustLicenses: 'API-integrationer för kundlicenser',
            newIntegration: 'Ny integration',
            thPublicKey: 'Publik nyckel',
            thKidneyFuncCalc: 'Njurfunktionsberäkning',
            thSyntaxAnalysis: 'Syntax analys',
            thRegionId: 'RegionId',
            thLicenseNum: 'Licensnummer',
            thLicenseType: 'Licenstyp',
            thCallsLeft: 'Anrop kvar',
            thRequestKey: 'begär nyckel',
            thActivated: 'Aktiverad',
            activate: 'Aktivera',
            thCustomerNumber: 'Kundnummer',
            thBusiness: 'Företag',
            yesCockcroftGault: 'Ja - Cockcroft-Gault',
            yesLundMalmoRevised: 'Ja - Lund-Malmö reviderad',
            no: 'Nej',
            yes: 'Ja',
            removeIntegration: 'Ta bort integration',
            showDeleted: 'Visa borttagna',
            saved: 'Sparat',
            integrationCreated: 'Integration skapad',
            noLimit: 'ingen gräns',
            showCustomer: 'Visa kund',
            licenseLabel: 'Licens *',
            selectLicense: 'Välj licens',
            selectLicenseInfo: 'Välj licens',
            customerHasNoActiveLicense: 'Kunden har inga aktiva licenser',
            removeTitle: 'Ta bort',
            doYouWantRemoveIntegration: 'Vill du ta bort integration?',
            cancel: 'Avbryt',
            yesRemoveIntegration: 'Ta bort integration',
            failedRemoveIntegration: 'Gick inte att ta bort integration.',
            productRegister: 'Varuregister',
            productRegisterTitle: 'Välj varuregister',
            productRegisterInfo: 'Välj varuregister',
            selectRegionTitle: 'Välj region',
            regionsIdSilLabel: 'Regions id',
            langForIntegrationLabel: 'Språk för integration',
            selectLangTitle: 'Välj språk',
            standartKidneyFuncCalcLabel: 'Standard njurfunktionsberäkning',
            cockcroftGault: 'Cockcroft-Gault',
            lundMalmoRevised: 'Lund-Malmö, reviderad',
            syntaxAnalysisLabel: 'Syntax Analys',
            selectAnalysisOption: 'Välj analys',
            selectAnalysisTitle: 'Välj analys',
            saveIntegration: 'Spara',
            search: 'Sök',
            lastSync: 'Senast synkroniserad: ',
            editIntegration: 'Redigera integration',
            integrationChanged: 'Integrationen förändrades',
          },
          administration: {
            anErrorHasOccured: 'Ett fel har inträffat!',
            inCaseOfErrorContactAdmin: 'Vid upprepade fel kontakta administratör.',
            previewOfDiagnosRegister: 'Förhandsgranskning av diagnosregister',
            totalRows: 'Totalt rader: ',
            runTakes: 'körning gick på (sekunder): ',
            warning: 'Varning: ',
            noErrors: 'Inga fel hittades',
            toContinueClickDiagnosticRegister: 'För att fortsätta, klicka aktivera diagnosregister',
            activateDiagnosticRegister: 'Aktivera diagnosregister',
            waitDbManaging: 'Var god vänta, hanterar databas.',
            diagnosticRegisterUpdated: 'Diagnosregister uppdaterat',
            dbUpdatedTime: 'Databasen har blivit uppdaterad, på total tid (sek): ',
            administration: 'Administration',
            diagRegister: 'Diagnosregister',
            uploadNewDiagRegFile: 'Ladda upp ny diagnosregisterfil',
            uplDbFile: 'Ladda upp databasfil',
            cancel: 'Avbryt',
            upload: 'Ladda upp',
            errorDbFileSize: 'databasfil är över 20mb',
            couldNotSave: 'Gick inte att spara',
            pleaseTryAgain: 'Vänligen försök igen.',
            dbFilePreview: 'Förhandsgranskning av databasfil',
            thFileName: 'Namn på fil',
            thSysDataVersionInternal: 'Systemdataversion (intern)',
            thLastActivated: 'Senast aktiverad',
            thCreated: 'Skapad',
            thCreatedBy: 'Skapad av',
            neverActivated: 'Aldrig aktiverad',
            noUsersFound: 'Ingen användare hittad',
            toContinueClickSideEffectDb: 'För att fortsätta, klicka aktivera biverkningsdatabas',
            activateSideEffectDb: 'Aktivera biverkningsdatabas',
            pleaseWaitDbLoading: 'Var god vänta, laddar databas.',
            dbUpdated: 'Databas uppdaterad',
            dbHasBeenUpdated: 'Databasen har blivit uppdaterad',
            thAtcCode: 'atc_kod',
            thNplid: 'nplid',
            thFrequency: 'Frekvens',
            thFass: 'FASS',
            thPh20Num: 'PHASE 20 nr',
            thPh20Text: 'PHASE 20 text',
            sideEffectDb: 'Biverkningsdatabas',
            version: 'Version',
            uplModifDbFile: 'Ladda upp/ändra databasfil',
            currentSideEffectDb: 'Nuvarande biverkningsdatabas',
            dbHasBeenUpdatedTotalTime: 'Databasen har uppdaterats, total tid nerlagd:',
            sideEffectVersionsLabel: 'Versioner - välj för att förhandsgranska eller ladda upp ny databasfil',
            selectVersion: 'Välj version',
            selectVersionTitle: 'Välj version',
            uplNewDbFile: 'Ladda upp ny databasfil',
            enableTwoStepAuth: 'Aktivera tvåstegs authentisering',
            responseCode: 'Svarskod',
            scanQrCode: 'Scanna QR koden',
            confirmTwoStepAuth: 'Bekräfta tvåstegs authentisering',
            incorrectEnteredCode: 'Koden som angivits är felaktig!',
            failedToEnableTwoStepAuth: 'Gick inte att aktivera tvåstegs authentisering.',
            user: 'Användare',
            addNewUser: 'Lägg till ny användare',
            userInformation: 'Användaruppgifter',
            firstName: 'Förnamn *',
            enterValidFirstName: 'Ange ett giltigt förnamn',
            lastName: 'Efternamn *',
            enterValidLastName: 'Ange ett giltigt efternamn',
            userName: 'Användarnamn *',
            emailAddress: '(E-postadress)',
            emailAddressUsedWhenLogIn: ' e-postadress används vid inloggning',
            enterValidEmailAddress: 'Ange en giltig e-postadress',
            save: 'Spara',
            userAlreadyExist: 'Användare existerar redan!',
            emailAddressAlreadyInUse: 'Det angivna e-postadressen användas redan.',
            failedCreateCustomer: 'Gick inte att skapa användare.',
            notActivated: 'Inte aktiverad',
            active: 'Aktiv',
            removed: 'Borttagen',
            removeTwoFactorAuth: 'Ta bort tvåfaktors authentisering',
            enableTwoFactorAuth: 'Aktivera tvåfaktors authentisering',
            deleteUsers: 'Ta bort användare',
            changePassword: 'Ändra lösenord',
            enterNewPassword: 'Ange nytt lösenord',
            passwordMustBe: 'Lösenordet måste innehålla minst 8 tecken, en siffra, en versal och en gemen.',
            repeatNewPassword: 'Upprepa nytt lösenord *',
            firstNameLabel: 'First name',
            lastNameLabel: 'Last name',
            userNameLabel: 'User name',
            userCreated: 'Användare skapad!',
            newUserCreated: 'Ny användare skapad:',
            msgRemoved: 'Borttagen!',
            theUserHasBeenDeleted: 'Användaren har tagits bort.',
            twoStepAuthActivated: 'Tvåstegs authentisering aktiverad!',
            twoStepAuthRemoved: 'Tvåstegs authentisering borttagen!',
            savedInfo: 'Sparad!',
            userInformationChanged: 'Användaruppgifter ändrade.',
            failedToSaveUserData: 'Gick inte att spara användaruppgifter.',
            failedToSavePassword: 'Gick inte att spara lösenord.',
            passwordChanged: 'Lösenord ändrad.',
            removeTwoFactorAuthTitle: 'Ta bort 2FA',
            confirmRemoveTwoFactorAuth: 'Bekräfta borttagning av tvåstegs authentisering',
            confirmAndDelete: 'Bekräfta & ta bort',
            failedRemoveTwoStepAuth: 'Gick inte att tabort tvåstegs authentisering.',
            remove: 'Ta bort',
            tryingToRemoveYourself: 'OBS! Du försöker ta bort din egen användare!',
            failedToDeleteUser: 'Gick inte att ta bort användare.',
            thFirstName: 'Förnamn',
            thLastName: 'Efternamn',
            thUserName: 'Användarnamn',
            th2FA: '2FA',
            userStatusActive: 'Aktiv',
            userStatusNotActivated: 'Inte aktiverad',
            userStatusRemoved: 'Borttagen',
            showDeleted: 'Visa borttagna',
            yes: 'Ja',
            no: 'Nej',
            viewProfile: 'Visa profil',
            uiLanguage: 'Språk för användargränssnitt',
            selectLanguage: 'Välj språk',
            search: 'Sök',
            recentEvents: 'Senaste händelser',
            lines: 'rader',
            status: 'Status',
            requestStatus: 'Status för begäran',
            publicKey: 'Offentlig nyckel',
            requestBody: 'Begäran kropp',
            responseBody: 'Svarskropp',
            date: 'Datum',
            visualize: 'Visualisera',
            failed: 'misslyckades',
            success: 'framgång',
            show: 'visa',
            downloadFile: 'Nedladdning fil',
            from: 'Från',
            to: 'Till',
            delete: 'Radera',
            areYouSure: 'Är du säker på att du vill ta bort användare?',
          },
          tests: {
            kidneyFuncCalc: 'Njurfunktionsberäkning',
            kidFuncNo: 'Nej',
            kidFuncCockcroftGault: 'Ja - Cockcroft-Gault',
            kidFuncLundMalmo: 'Ja - Lund-Malmö, reviderad',
            adverseReactAnalysis: 'Biverkningsanalys',
            dualUseOfDrugs: 'Dubbelanvändning av läkemedel',
            interactAnalysis: 'Interaktionsanalys',
            recomendAnalysis: 'Rekommendationsanalys',
            silRegionId: 'RegionId',
            syntaxAnalysis: 'Syntax Analys',
            inputDataXml: 'Inputdata (xml)',
            send: 'Skicka',
            responseData: 'Svarsdata',
            testAnalysis: 'Test av analyser',
            error: 'Fel',
            anErrorHasOccured: 'Ett fel har inträffat',
            visualizationComponent: 'Visualiseringskomponent',
            drugIds: 'Läkemedels-ID',
            cancel: 'Avbryt',
            classNames: 'Klassnamn',
            choosingColors: 'Att välja färger',
            copyright: 'Upphovsrätt',
            save: 'Spara',
            confClass: 'Konfigurationsklass',
            twoDrugs: 'Krävs minst två läkemedel',
          },
          syntaxcompiler: {
            addTextLine: 'Lägg till textrad',
            algorithm: 'Algoritm',
            addAlgorithm: 'Lägg till algoritm',
            undo: 'Ångra',
            textField: 'Textfält',
            selectAlgorithm: 'Välj algoritm',
            algorithmQuestions: 'Algoritm frågor',
            showQuestion: 'Visa fråga',
            doYouWantToDeleteAlg: 'Vill du radera algoritm?',
            cancel: 'Avbryt',
            delete: 'Radera',
            returnAnswerForAlgorithm: 'Return answer for algorithm',
            save: 'Spara',
            close: 'Stäng',
            langDbUpdated: 'Språkdatabas uppdaterat',
            algLangUpdated: 'Språken för algoritm uppdaterat',
            anErrorHasOccured: 'Ett fel har inträffat',
            inCaseOfErrorContactAdmin: 'Vid upprepade fel kontakta administratör.',
            couldNotUpdateLangDb: 'Kunde ej uppdatera språkdatabas',
            earlierVersionsOfAlgorithm: 'Tidigare versioner av algoritmen',
            calculationAlgorithm: 'Beräkningsalgoritmer',
            copy: 'Kopiera',
            createNew: 'Skapa ny',
            selectAlgOrCreateNew: 'Välj algoritm att ändra i dropdownmenyn, eller skapa en ny algoritm',
            removed: 'Borttagen',
            algorithmRemoved: 'Algoritm borttagen',
            couldNotDelete: 'Gick inte att radera',
            saved: 'Sparad',
            algorithmSaved: 'Algoritm sparad',
            draft: 'UTKAST',
            published: 'PUBLICERAD',
            unpublish: 'Avpublicera',
            publish: 'Publicera',
            showCode: 'Visa kod',
            newLine: 'Ny rad',
            rowRemoved: 'Raden borttagen',
            fail: 'Fel',
            added: 'Tillagd',
            textLineAdded: 'Textrad tillagd',
            algorithmAdded: 'Algoritm tillagd',
            updated: 'Uppdaterad',
            textLineTranslations: 'Textrad översättningar',
            languageDbUpdated: 'Språkdatabas uppdaterat',
            textLineLanguagesUpdated: 'Språken för textrad uppdaterat',
            selectAnalysis: 'Välj analys',
            code: 'Kod',
            name: 'Namn',
            enterName: 'Ange ett namn',
            description: 'Beskrivning',
            returnResponse: 'Retursvar (vid positivt utfall av algoritmen)',
            otherLang: 'Andra språk',
            otherLangSaveFirst: 'Andra språk (spara först)',
            loadQuestion: 'Ladda in fråga',
            newQuestion: 'Ny fråga',
            algXmlLogic: 'Algoritm (xml/+logik)',
            problemsWithSyntax: 'Problem med syntax',
            deleteAlg: 'Radera algoritm',
            versions: 'Versioner',
            analysis: 'Analyser',
            selAnalysisOrCreateNew: 'Välj analys att ändra i dropdownmenyn, eller skapa en ny analys',
            titleInternal: 'Titel (intern)',
            missingTitle: 'Titel saknas',
            create: 'Skapa',
            created: 'Skapad',
            analysisCreated: 'Analys skapad',
            doYouWant: 'Vill du',
            yes: 'Ja',
            no: 'Nej',
            addNewQuestion: 'Lägg till ny fråga',
            swedishLabel: 'Svenska *',
            enterQuestionInSwedish: 'Ange fråga på svenska',
            possibilityToEnterQuestInOtherLang: 'Möjlighet att ange fråga i andra språk när frågan skapats.',
            questionCreated: 'Fråga skapad!',
            newQuestionSaved: 'Ny fråga skapad',
            couldNotCreateQuestion: 'Gick inte att skapa fråga.',
            question: 'Fråga',
            swedish: '( Svenska )',
            load: 'Ladda in',
            usedInPublishedAnalysis: 'Används i publicerad analys',
            usedInAlgorithm: 'Används i algorithm',
            notUsedInAnyAnalysis: 'Används inte i någon analys',
            usedInAlgorithms: 'Används i algoritmer',
            show: 'Visa',
            questionSaved: 'Fråga sparad!',
            failedToSaveQuestion: 'Gick inte att spara fråga.',
            questions: 'Frågor',
            notUsed: 'Används inte',
            remove: 'Ta bort',
            youWillAlsoDeleteLines: 'Du kommer även ta bort dessa rader kopplade till raden:',
            deleteSyntaxRow: 'Ta bort syntax rad',
            responseData: 'Svarsdata',
            deleteRow: 'Vill du ta bort rad?',
            recentEvents: 'Senaste händelser',
            selectVersion: 'Välj version',
            defaultAnswer: 'Standardsvar',
            editIndex: 'Redigera index',
            selectModuleType: 'Välj en modultyp',
            hideModule: 'Dölj modul i analysrapporten',
            module: 'Moduler',
            color: 'Välj färg',
            label: 'Märka',
            legend: 'Legend',
            addNewModule: 'Lägga till ny modul',
            created: 'Ny modul skapad',
            updated: 'Modul uppdaterad',
            removed: 'Modul borttagen',
            loading: 'Vänligen vänta, data laddas...',
            index: 'Index',
            questionAdded: 'Frågan har laddats',
          },
          documentation: {
            handshakeValidateDesc:
              'Detta API-anrop används för att validera en offentlig nyckel, detta anrop kommer inte att generera en privat nyckel och det kommer inte att förbruka engångsanvändningen av en offentlig nyckel.',
            apiCallHeader: 'API-anropshuvudinformation',
            apiCalls: 'API-anrop',
            chooseFeature: 'Välj funktion',
            overview: 'Översikt',
            docDesc:
              'API för MiniQ beslutsstödssystem för analys av läkemedelsförskrivningar och läkemedelsanvändning.',
            handshakeDesc: 'Detta anrop kommer att generera en privat nyckel att använda för resten av API',
            requestDesc:
              'Det huvudsakliga API-anropet, där analyser kommer att utföras på indata baserat på licenstyper.',
            validationEndpointsDesc:
              'Används för att validera API-anropsinmatning utan att påverka licensbegränsningar.',
            responseTypesDesc:
              'Alla API-anrop kan väljas att antingen vara json-arrayer eller XML-format. När XML väljs är svaret inom parentes runt &lt;data&gt Här är svaret &lt;/data&gt;. JSON-matriser kommer att kapslas i de flesta fall, för varje API-anropsändpunkt läs avsnittet API-anrop. Standardsvaret är json array.',
            badRequestDesc:
              'Servern kan eller kommer inte att bearbeta begäran på grund av ett uppenbart klientfel (t.ex. felaktig syntax för begäran, för stor storlek, ogiltig inramning av förfrågningsmeddelande eller vilseledande förfrågningsdirigering)',
            unauthorizedDesc:
              'Svaret måste innehålla ett WWW-Authenticate-huvudfält som innehåller en utmaning som är tillämplig på den begärda resursen.',
            internalServerErrorDesc:
              'Ett allmänt felmeddelande som ges när ett oväntat tillstånd påträffades och inget mer specifikt meddelande är lämpligt.',
            serviceUnavailiableDesc:
              'Servern kan inte hantera begäran (eftersom den är överbelastad eller nere för underhåll). I allmänhet är detta ett tillfälligt tillstånd.',
            gatewayTimeoutDesc:
              'Servern fungerade som en gateway eller proxy och fick inget svar i rätt tid från uppströmsservern.',
            reqErrMsg: 'Begär felmeddelanden',
            errCode: 'Felkod',
            errMeaning: 'Menande',
            err1001: 'Saknar regionid',
            err1002: 'Saknar patientdata',
            err1003: 'Patientdata fel format',
            err1003a: 'Saknar patientid',
            err1003b: 'Födelseår saknas',
            err1003c: 'Saknar kön',
            err1003c2: 'Sex fel format, måste vara "M" eller "F"',
            err1003d: 'Vikt saknas',
            err1006: 'Dataformatet för recept är fel',
            err1006b: 'PrescStartdate saknas',
            err1006b2: 'PrescStartdate fel format, använd YYYY/MM/DD',
            err1006c: 'Drugform saknas',
            err1006d: 'Drugname saknas',
            err1006e: 'Strength saknas',
            err1006f: 'StrengthUnit saknas',
            err1006g: 'Nplid saknas',
            err1006h: 'Nplpackid saknas',
            err1006i: 'Dosage saknas',
            err1006j: 'Indication saknas',
            err1007: 'LabValues saknas',
            err1007a: 'LabValues fel format',
            err1007b: 'LabValue saknas',
            err1007b2: 'LabValue fel format',
            err1007c: 'LabDate saknas',
            err1007c2: 'LabDate fel format (YYYY/MM/DD)',
            err1007d: 'LabTestName saknas',
            err1007e: 'LabValueNum saknas',
            err1007f: 'LabValueText saknas',
            err1007g: 'SymtomList saknas',
            err1007g1: 'SymtomList fel format',
            err1007g2: 'SymtomListDate saknas',
            err1007g2a: 'SymtomListDate fel format (YYYY/MM/DD)',
            err1007g3: 'Symtom saknas',
            err1007g3a: 'Symtom fel format (array/object)',
            err1007g4: 'Symtomnumber saknas',
            err1007g5: 'SymtomSeverity saknas',
            err1007g6: 'SymtomText saknas',
            err1008: 'IcdCode måste vara en sträng',
            handshakeEndpointDesc:
              'Detta anrop kommer att generera en privat nyckel att använda för resten av API:et. Se till att spara din privata nyckel/svar, eftersom en privat nyckel bara kan genereras en gång per offentlig nyckel.',
            requestValidateDesc:
              'Validerar post_fields och anropet, detta API-anrop anses inte vara ett livesamtal och räknas inte för att använda licensen kopplad till API-nycklar',
            requestEndpointDesc:
              'Det huvudsakliga API-anropet, där analyser kommer att utföras på indata baserat på licenstyper.',
          },
          productlist: {
            prodRegister: 'Varuregister',
            info: 'Info',
            strengthNumerical: 'Styrka numerisk',
            strengthUnit: 'Styrka enhet',
            atcCode: 'ATC-kod',
            packNumberNumeric: 'Förp. antal numerisk',
            packMultiple1: 'Förp. multipell 1',
            packMultiple2: 'Förp. multipell 2',
            pharmSalePrice: 'Apotekets utförsäljningspris',
            numOfDailyDoses: 'Antal dygnsdoser / förpackning',
            itemNum: 'VaruNr',
            drugId: 'InternalDrugID',
            genericid: 'GenericID',
            nplId: 'DrugID',
            nplPackId: 'DrugpackID',
            pharmForm: 'Läkemedelsform',
            retrieved: 'Hämtat',
            name: 'Namn',
            substanceId: 'Substansid',
            unsyncProducts: 'Osynkroniserade produkter',
            lastSync: 'Senaste synkronisering: ',
            nextAutoSync: 'Nästa automatiska synkronisering: ',
            apiIntegration: 'API-integration',
            specNplId: 'Angiven DrugID',
            specNplPackId: 'Angiven DrugpackID',
            dateOfCall: 'Datum för anrop',
            existAfterSync: 'Existerar efter synkronisering av varuregister',
            notExistAfterSync: 'Existerar inte efter synkronisering av varuregister',
            prodRegSyncNotCompleted: 'Synkronisering av varuregister inte genomförd efter datum för anrop.',
            call: 'Anrop',
            markAsProcessed: 'Markera som behandlad',
            callData: 'Anropsdata',
            close: 'Stäng',
            markCallsAsProcessed: 'Markera anrop som behandlad från',
            noteLinesWillDisappear: 'OBS! Behandlade rader försvinner från denna lista!',
            cancel: 'Avbryt',
            mark: 'Markera',
            treated: 'Behandlad!',
            markedAsTreated: 'Markerad som behandlad',
            anErrorHasOccured: 'Ett fel har inträffat!',
            inCaseOfErrorContactAdmin: 'Vid upprepade fel kontakta administratör.',
            couldNotCreateQuestion: 'Gick inte att skapa fråga.',
            synchronized: 'Synkroniserad',
            date: 'Datum',
            exists: 'Existerar',
            doesNotExist: 'Existerar inte',
            no: 'Nej',
            yes: 'Ja',
            preparationName: 'Preparatnamn',
            added: 'Tillagd',
            historyPrep: 'Historiskt preparat',
            showProduct: 'Visa produkt',
            syncProductRegister: 'Synkronisera varuregister',
            pleaseWaitSync: 'Vänligen vänta, synkronisering pågår, det kan ta upptill 30 minuter',
            syncReadyOn: 'Synkronisering klar på',
            syncCancel: 'Synkronisering avbryten',
            procContinueInBackground: 'Processen fortsätter i bakgrunden!',
            syncronization: 'Synkronisering',
            syncronizationOf: 'Synkronisering av',
            syncDesc:
              'Beroende på hur mycket nya produkter som kommer hämtas från registret så kan det ta upp till 30 minuter att synkronisera, övriga tjänster kan undertiden fungera långsamt,felaktigt eller inte alls.',
            doYouWantToCont: 'Vill du fortsätta?',
            startSync: 'Starta synkronisering',
            search: 'Sök',
            containsSubstances: 'Innehåller substanser:',
            syncCompleted: 'Synkroniseringen har slutförts',
          },
          errors: {
            uncaughtExep: 'Ett ouppfattat undantag påträffades',
            anErrorHasOccured: 'Ett fel har inträffat',
            inCaseOfErrorContactAdmin: 'Vid upprepade fel kontakta administratör.',
            pageNotFound: 'Sidan du letade efter hittades inte.',
            backToStartPage: 'Tillbaka till startsida',
            close: 'Stäng',
          },
          licenses: {
            customer: 'Kund *',
            customerRegister: 'Kundregister',
            addNewLicense: 'Lägg till ny licens',
            editLicense: 'Redigera licens',
            licenseType: 'Licenstyp *',
            selectLicenseType: 'Välj en licenstyp',
            selectCustomer: 'Vänligen välj en kund.',
            save: 'Spara',
            failedCreateCustomer: 'Gick inte att skapa kund.',
            renew: 'Förnya',
            newDate: 'Nytt datum',
            extraNumberOfCalls: 'Extra antal anrop (utöver de nuvarande)',
            saved: 'Sparat!',
            licenseUpdated: 'Licensen har uppdaterats!',
            couldNotSave: 'Kunde ej spara!',
            licenseValidUntil: 'Licens giltig till *',
            selectValidDate: 'Vänligen välj ett giltigt datum',
            numberOfCallsForPeriod: 'Antal anrop för perioden',
            specifyNumberOfCalls: 'Du måste ange hur många anrop som ska få göras.',
            licenses: 'Licenser',
            licenseNumber: 'Licensnummer',
            type: 'Typ',
            maxCall: 'Max anrop',
            thSaved: 'Skapad',
            validUntil: 'Giltig till',
            manageLicense: 'Hantera licens',
            createApiIntegration: 'Skapa api-integration',
            removeLicense: 'Ta bort licens',
            noLimit: 'ingen gräns',
            remove: 'Ta bort',
            cancel: 'Avbryt',
            failedToRemoveLicense: 'Gick inte att ta bort licens.',
            licenseHaveIntegration:
              'Licensen  har integrationer kopplade till sig, om du tar bort licensen försvinner även dessa, vill du fortsätta?',
            doYouWantRemoveLicense: 'Vill du ta bort licensen? Licensen har inga aktiva integrationer.',
            search: 'Sök',
          },
          settings: {
            downloadLangFileFor: 'Ladda ner språkfil för',
            syntaxQuestions: 'Syntax frågor',
            syntaxTextLines: 'Syntax textrader',
            syntaxReturnResponse: 'Syntax retursvar',
            phase20text: 'Phase 20 text',
            fassText: 'Fass text',
            cancel: 'Avbryt',
            download: 'Ladda ner',
            downloadStarted: 'Nedladdningen påbörjas!',
            langFilePreviewFor: 'Förhandsgranskning av språkfil för',
            warning: 'Varning:',
            noErrorsFound: 'Inga fel hittades',
            toContinueApproveLangFile: 'För att fortsätta, godkänn språkfil:',
            approve: 'Godkänn',
            langDbUpdated: 'Språkdatabas uppdaterad',
            totalRows: 'Totalt rader: ',
            gonnaAffected: 'kommer påverkas vid godkännande, körning gick på',
            language: 'Språk',
            selectLanguage: 'Välj språk',
            noteBreaksInCsv:
              'Notera att rad brytningar i text csv filerna blir borttagna, använd mellanslag vid översättning.',
            downloadLangFile: 'Ladda ner språkfil',
            uploadLangFile: 'Ladda upp språkfil',
            noLangFileForSwedish: 'Ingen språkfil för svenska',
            uploadLangFileFor: 'Ladda upp språkfil för',
            downloadTranslationSwedishFile: 'Ladda ner Svenska översättningsfil',
            addNewLangTitle: 'Lägg till ett nytt språk: ',
            addLanguage: 'Lägg till språk',
            languageAdded: 'Språk tillagdt',
            langCouldNotBeAdded: 'Språket kunde inte läggas till.',
            settings: 'Inställningar',
            languageSettings: 'Språkinställningar',
            addNewLang: 'Lägg till nytt språk',
            upload: 'Ladda upp',
            fileOversized: 'språkfil är över 20mb',
            failedSaveTryAgain: 'Gick inte att spara Vänligen försök igen.',
            recentEvents: 'Senaste händelser',
            downloadSwedish: 'Ladda ner Svenska',
            translationFile: 'översättningsfil',
            uploadLanguageFileFor: 'Ladda upp språkfil för',
            swedishLanguage: 'Svenska språkinställningar kräver ingen översättning',
            fileUploadSuccess: 'Filen har laddats upp',
            status: 'Status',
            removeLanguage: 'Ta bort',
            changeStatus: 'Byta status',
            areYouWantChangeStatus: 'Vill du ändra status för det här språket?',
            statusChanged: 'Status ändrad',
            areYouWantRemove: 'Vill du ta bort språköversättning?',
            languageRemoved: 'Språk borttaget',
            translationsCount: 'Översättningar räknas',
            integrationsCount: 'Integrationer räknas',
            no: 'Nej',
            deactivatedLanguage: 'Avaktivera språk',
            activateLanguage: 'Aktivera språk',
          },
          menu: {
            dashboard: 'Dashboard',
            customerRegister: 'Kundregister',
            apiIntegration: 'API-integration',
            productRegister: 'Varuregister',
            testAnalysis: 'Test av analyser',
            syntax: 'Syntax',
            analysis: 'Analyser',
            calculationAlgorithm: 'Beräkningsalgoritmer',
            questions: 'Frågor',
            administration: 'Administration',
            settings: 'Inställningar',
            users: 'Användare',
            sideEffectDatabase: 'Biverkningsdatabas',
            diagnosticRegister: 'Diagnosregister',
            apiDocumentation: 'API dokumentation',
            disableMaintenanceMode: 'Inaktivera underhållsläge för API',
            enableMaintenanceMode: 'Aktivera underhållsläge för API',
            tests: 'Tester',
            algorithms: 'Algoritmer',
            visualizationComponent: 'Visualiseringskomponent',
            history: 'Historik över förfrågningar',
            module: 'Moduler',
          },
          language: {
            en: 'Engelsk',
            sv: 'Svenska',
            es: 'Spanska',
            de: 'Tyska',
            fr: 'Franska',
          },
        },
      },
    },
    react: {
      wait: true,
    },
  });

export default i18n;
